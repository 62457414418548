<template>
  <div class="query-view" ref="container">
    <vxe-table :data="list" :loading="loading"
      highlight-hover-row
      @sort-change="headerCellClickEvent"
      @checkbox-all="handleCheckboxChange"
      @checkbox-change="handleCheckboxChange"
      :height="height" size="mini"
      :filter-config="{showIcon: false}"
      ref="vtable" auto-resize
      :sort-config="{remote:true,trigger:'default'}">
      <slot name="actions"></slot>

      <template v-for="col in columns">
        <template v-if="!col.filterColumnName">
          <vxe-column :key="col.field"
            :field="col.field" :title="col.title"
            :min-width="col.width"
            :sort-by="col.field"
            :align="col.align"
            :formatter="col.formatter"
            :sortable="col.sortable"
            :cell-render="col.renderFunc">
          </vxe-column>
        </template>
        <template v-else>
          <vxe-table-colgroup :title="col.title"
            :key="col.field" :sort-by="col.field">
            <vxe-column :key="col.field"
              :field="col.field"
              :min-width="col.width"
              :align="col.align"
              :sortable="col.sortable"
              :formatter="col.formatter"
              :cell-render="col.renderFunc"
              :filters="[{data: ''}]"
              :filter-method="customStringFilterMethod">
              <template #header="{ column }">
                <el-input
                  v-for="(option, index) in column.filters"
                  v-model.trim="option.data"
                  size="mini" :key="index"
                  @change="filterEvent(option)"
                  @clear="filterEvent(option)"
                  clearable
                  style="width:calc(100% - 30px) !important">
                </el-input>
              </template>
            </vxe-column>
          </vxe-table-colgroup>
        </template>
      </template>

      <slot name="template-columns"> </slot>

      <slot name="template-action-columns">
        <vxe-column :width="70" fixed="left" v-if="showActions">
          <template slot-scope="scope">
            <div>
              <el-button type="text"
                icon="el-icon-edit"
                @click="$emit('editRow', scope.row)"
                size="large"></el-button>
              <el-button type="text"
                icon="el-icon-delete"
                @click="$emit('deleteRow', scope.row)"
                size="large">
              </el-button>
            </div>
          </template>
        </vxe-column>
      </slot>
      <vxe-column type="checkbox" width="60"
        v-if="multiple" fixed="left">
      </vxe-column>
    </vxe-table>
    <div
      style="display: flex;justify-content: flex-end;align-items: center;"
      v-if="page">
      <pagination v-show="total > 0"
        :total="total" :page.sync="page.pageNum"
        :limit.sync="page.pageSize"
        @pagination="refresh">
      </pagination>
      <el-button size="mini"
        icon="el-icon-refresh" circle
        @click="refresh"></el-button>
    </div>

  </div>
</template>

<script>
import BaseQuery from "./BaseQuery.js";
import "./QueryView.renders";
import {dynamicQueryList} from '@/api/uxretail/sys/sysQueryBuilder.js'
export default {
  components: {},
  mixins: [BaseQuery],
  name: "ux-query-view",
  props: {
    height: {
      default: "auto",
    },
    lazyFetch: {
      default: false,
    },
    multiple: {
      default: false,
    },
    showActions: {
      default: true,
    },
    value: {}, //当前选中的值,用来回显
    valueField: {
      default: "id",
    },
    echoField: {
      default:""
    }, //切换分页保留数据
    //注意BaseQuery中还有属性
  },
  data() {
    return {
      echoData:[],
    };
  },
  methods: {
    async search() {
      let params = [];
      if (this.echoData && this.echoData.length > 0) return;
      let flag = Array.isArray(this.value)? this.value : this.value ? this.value.split(",") : [];
      if (flag && flag.length > 0) {
        let value = flag.join(",");
        params.push(`${this.valueField} IN (${value})`);
        let res = await dynamicQueryList({
          queryCode: this.code,
          dataScopeActive: this.queryModel.dataScope
            ? this.queryModel.dataScope.active
            : false,
          orgAlias: this.queryModel.dataScope
            ? this.queryModel.dataScope.orgAlias
            : "",
          userAlias: this.queryModel.dataScope
            ? this.queryModel.dataScope.userAlias
            : "",
          sql: this.sql,
          params: params,
        });
        if (res.items[0]) {
          this.echoData = res.items;
        }
      }
    },
    handleCheckboxChange({ checked, row, rowIndex }) {
      if (this.valueField && this.echoField) {
        // 只有是input框的形式打开才需要这些判断,通过按钮打开的走原来的逻辑
        if (!row && !checked) {
          // 全不选
          this.echoData = this.echoData.filter((t) => {
            return !this.list.find(
              (x) => x[this.valueField] == t[this.valueField]
            );
          });
          this.$refs.vtable.clearCheckboxReserve(); //清空保留的行数据,echoData实际就是保留的行数据
        } else if (checked && !row) {
          // 全选
          this.echoData = this.mergeAndDistinct([
            ...this.echoData,
            ...this.list,
          ]);
        }
        if (checked && row) {
          // 勾选
          if (
            this.echoData.find(
              (x) => x[this.valueField] == row[this.valueField]
            )
          ) {
            return;
          }
          this.echoData.push(row);
        } else if (!checked && row) {
          // 取消勾选
          // 删除对象数组指定元素
          this.echoData = this.removeItemFromArray(
            this.echoData,
            row[this.valueField]
            );
            console.log(this.echoData,"this.echoData");
          this.$refs.vtable.clearCheckboxReserve(); //清空保留的行数据,echoData实际就是保留的行数据
        }
      }
    },
    // 删除对象数组指定元素
    removeItemFromArray(arr, itemId) {
      const index = arr.findIndex((obj) => obj[this.valueField] === itemId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    mergeAndDistinct(arr) {
      const mergedArray = arr.reduce((acc, obj) => {
        const key = obj[this.valueField];
        if (!acc[key]) {
          acc[key] = obj;
        }
        return acc;
      }, {});

      return Object.values(mergedArray);
    },
    //------实现abstract-----------
    afterColumnsRender(defaultSort) {
      if (defaultSort) {
        this.$nextTick(() => {
          if (!this.lazyFetch) {
            this.$refs.vtable
              .sort(defaultSort.columnName, defaultSort.sortDir)
              .then(() => {
                this.refresh();
              });
          }
        });
      } else {
        this.refresh();
      }
    },
    beforeRefresh(sql, params) {
      if (this.$refs.vtable) {
        let vm = this;
        this.$refs.vtable.getCheckedFilters().forEach((t) => {
          var sCol = vm.columns.find((x) => x.field == t.property);
          var data = t.datas[0];
          if (data) {
            //todo:lisen
            if (data.indexOf(">") == 0 || data.indexOf("<") == 0 
             || data.indexOf("=") == 0 || data.indexOf(">=") == 0 
             || data.indexOf("<=") == 0 || data.indexOf("!=") == 0
             || data.indexOf("!") == 0 ) {
              //todo:判断
              // <1 >1 =1  !=1   !1 >= <=
              // 字段是字符型  <1
              var operate = "";
              var newDate = "";
              if(data.indexOf("=") == 1){
                 operate=data.substring(0,data.indexOf("=")+1);
                 newDate=data.substring(data.indexOf("=")+1);
              }else if(data.indexOf("!") == 0){
                 operate = data.substring(0, 1)+'=';
                 newDate = data.substring(1);
              }else{
                  operate = data.substring(0, 1);
                  newDate = data.substring(1);
              }
              params.push(`${sCol.filterColumnName} ${operate} '${this.$ux.util.stripscript(newDate)}'`);
            } else {
              params.push(`${sCol.filterColumnName} like '%${this.$ux.util.stripscript(data)}%'`);
            }
          }
        });
        this.$refs.vtable.getSortColumns().forEach((t) => {
          sql += ` order by ${t.property} ${t.order}`;
        });
      }
      return sql;
    },

    //------实现abstract end---------

    headerCellClickEvent({ column, property, order }) {
      this.refresh();
    },
    filterEvent(option) {
      if (option.data) {
        option.checked = true;
      } else {
        option.checked = false;
      }
      this.refresh();
    },
    customStringFilterMethod() {
      return true;
    },
    recalculate() {
      console.log("re-calc");
      this.$refs.vtable.recalculate();
    },
    getSelected() {
      const currentSelected =  this.$refs.vtable.getCheckboxRecords();
      if (this.valueField && this.echoField) {
        return this.mergeAndDistinct([
          ...currentSelected,
          ...this.echoData,
        ]);
      }
      return currentSelected
    },
    getAll() {
      return this.list;
    },
  },
};
</script> 

<style>
.query-view {
  height: calc(100% - 55px);
}
</style>
