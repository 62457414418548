<template>
  <el-select v-model="currentValue"
    :placeholder="l('PleaseSelect')"
    :clearable="clearable" :disabled="disabled"
    @change="handleChange" filterable
    :size="size"
    style="flex:1" :multiple="multiple"
    :collapse-tags="true">
    <slot></slot>
    <el-option v-for="item in options"
      :key="item[valuePath]"
      :label="item[labelPath]||item['code']"
      :value="item[valuePath]">
    </el-option>
    <div slot="prefix" v-if="api||apiRequest">
      <el-button type="text"
        icon="el-icon-refresh" @click="refresh"
        :loading="loading">
      </el-button>
    </div>
  </el-select>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "ux-select",
  props: {
    size: {},
    disabled: false,
    api: {},
    text: {},
    number: {
      default: false,
    },
    apiRequest: {}, //fetch api
    filterDto: {
      default: () => ({
        keyword: "",
      }),
    }, //api input filter dto
    labelPath: {
      default: "name",
    },
    valuePath: {
      default: "id",
    },
    value: {},
    multiple: {
      default: false,
    },
    multipleOption: {
      default: "array",
    }, //array|| splitChar like ,
    clearable: {
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      currentValue: this.getValue(),
    };
  },
  watch: {
    value() {
      this.currentValue = this.getValue();
    },
  },
  methods: {
    getValue() {
      if (this.value || this.value == 0) {
        if (this.multiple) {
          if (this.multipleOption == "array") {
            return this.value;
          } else {
            return this.value
              .split(this.multipleOption)
              .map((t) => this.tryNumber(t));
          }
        } else {
          if (this.number) {
            return this.tryNumber(this.value);
          }
          return this.value;
        }
      }
      return this.multiple ? [] : null;
    },
    tryNumber(value) {
      if (
        value &&
        typeof value == "string" &&
        value.indexOf("0") == 0 &&
        value.length > 1
      ) {
        return value; //特殊情况 例如value=001
      }
      var v = parseInt(value);
      if (v == value) {
        return v;
      } else {
        return value;
      }
    },
    getCurrentValue() {
      if (this.multiple) {
        if (this.multipleOption == "array") {
          return this.currentValue;
        } else {
          return this.currentValue.join(this.multipleOption);
        }
      } else {
        return this.currentValue;
      }
    },
    emitChange(text, input, rows) {
      this.$emit("update:text", text);
      this.$emit("input", input);
      this.$emit("change", {
        row: rows,
        value: input,
        label: text,
      });
    },
    handleChange() {
      if (this.currentValue || this.currentValue == 0) {
        if (this.multiple) {
          let rows = this.options.filter(
            (t) => this.currentValue.indexOf(t[this.valuePath]) > -1
          );
          let labels = rows
            .map((row) => row[this.labelPath])
            .join(this.multipleOption.replace("array", ","));
          this.emitChange(labels, this.getCurrentValue(), rows);
        } else {
          let row = this.options.find(
            (t) => t[this.valuePath] == this.currentValue
          );
          this.emitChange(row[this.labelPath], this.getCurrentValue(), row);
        }
      } else {
        this.emitChange("", this.getCurrentValue(), null);
      }
    },
    refresh() {
      if (this.api) {
        request({
          url: this.api,
          method: "post",
          data: this.filterDto,
        }).then((res) => {
          this.options = res.items;
        });
      } else if (this.apiRequest) {
        this.apiRequest(this.filterDto).then((res) => {
          this.options = res.items;
        });
      }
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style>
</style>
