<template>
  <el-form :model="filterValues" status-icon
    ref="itemForm" label-width="100px"
    label-position="top" class="has-n-row"
    @submit.native.prevent :rules="rules"
    size="small">
    <template v-for="(f,index) in filters">
      <!--一行3列-->
      <n-row v-if="index%3==0" :key="index">
        <el-col :span="8"
          v-for="index1 in [0,1,2]" :key="index1">
          <ux-form-item
            v-if="index+index1<filters.length"
            :label="filters[index+index1].caption"
            :prop="filters[index+index1].name"
            :key="filters[index+index1].name">
            <!--特殊处理-->
            <template
              v-if="filters[index+index1].component=='el-select'">
              <el-select
                v-bind="filters[index+index1].options"
                v-model="filterValues[filters[index+index1].name]"
                clearable>
                <el-option
                  v-for="item in filters[index+index1].options.selectOptions"
                  :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </template>
            <template v-else>
              <component
                :is="filters[index+index1].component"
                v-bind="filters[index+index1].options"
                :filterValues="filterValues"
                v-model="filterValues[filters[index+index1].name]">
              </component>
            </template>
          </ux-form-item>
        </el-col>
      </n-row>
    </template>
  </el-form>
</template>

<script>
import Cookies from "js-cookie";
import moment, { defaultFormat } from "moment";
import format from "@/utils/format.js";
import store from "@/store";
import SqlSelect from "./components/sqlSelect.vue";
import SqlRefSelect from "./components/sqlRefSelect.vue";
import ReportUtil from "@/components/Report/reportUtil.js";

import { getData } from "@/api/uxreport/query.js";
export default {
  components: {
    SqlSelect,
    SqlRefSelect,
  },
  props: {
    reportName: {
      default: "",
    },
    filters: {
      default: () => [],
    },
  },
  data() {
    return {
      filterValues: this.initFilterValues(),
    };
  },
  watch: {
    filters() {
      this.filterValues = this.initFilterValues();
    },
    filterValues: {
      deep: true,
      handler: function () {
        this.$root.eventBus.$emit("ref-change", this.filterValues);
      },
    },
  },
  methods: {
    getFilterValues() {
      return this.filterValues;
    },
    getData() {
      return new Promise((resolve, reject) => {
        this.$refs.itemForm.validate((valid) => {
          if (valid) {
            var sqlParameters = [];
            //remember
            var cached = {};
            this.filters
              .filter((t) => t.remember === true)
              .forEach((t) => {
                cached[t.name] = this.filterValues[t.name];
              });
            localStorage.setItem(
              "FILTER_" + this.reportName,
              JSON.stringify(cached)
            );

            this.filters.forEach((t) => {
              var v = this.filterValues[t.name];
              if (t.options && t.options.multiple) {
                let value = "";
                if (v && typeof v == "object" && v.length > 0) {
                  value = v.map((x) => "'" + x + "'").join(",");
                }
                sqlParameters.push({
                  name: t.name,
                  value,
                  valueSql: t.valueSql,
                  noneValueSql: t.noneValueSql,
                });
              } else {
                if (t.component == "el-date-picker") {
                  switch (t.options.type) {
                    case "date":
                      v = this.$ux.format.utcDateTime(
                        v,
                        t.options.dateTimeFormat
                      );
                      break;
                    case "datetime":
                      v = this.$ux.format.utcDateTime(
                        v,
                        t.options.dateTimeFormat
                      );
                      break;
                    case "year":
                      v = v ? v.getFullYear() : "";
                      break;
                    case "month":
                      v = v ? v.getMonth() + 1 : "";
                      break;
                  }
                }
                sqlParameters.push({
                  name: t.name,
                  value: v || (v === 0 ? v : ""),
                  valueSql: t.valueSql,
                  noneValueSql: t.noneValueSql,
                });
              }
            });
            resolve({
              sqlParameters,
              filterValues: this.filterValues,
            });
          } else {
            reject();
          }
        });
      });
    },
    async _initBySql(dsId, initSql, multiple) {

      var rVarArray = ReportUtil.getVariables();
      for (var i = 0; i < rVarArray.length; i++) {
        initSql = initSql.replaceAll("#" + rVarArray[i].name + "#", rVarArray[i].value);
      }

      var v = null;
      try {
        var res = await getData({ dataSourceId: dsId, sql: initSql });
        if (res && res.length > 0) {
          if (multiple) {
            v = res.map((t) => t.value);
          } else {
            v = res[0].value;
          }
        }
      } finally {
        return v;
      }
    },
    initFilterValue(filter, cached) {
      //新增需求， print按钮直接调用当前单据的对象
      if (filter.objectId && filter.inputObjectId) {
        return filter.inputObjectId;
      }
      var v = filter.remember ? cached[filter.name] : "";

      if (!v) {
        if (filter.inital) {
          if (filter.initialBySql) {
            return "";
          } else {
            if (filter.inital == "USER_ID") {
              return store.getters.userId;
            } else if (filter.inital == "ORG_ID") {
              return store.state.orgId; //todo:login未返回
            } else {
              try {
                var value = eval(filter.inital);
                return value;
              } catch {}
            }
          }
        }
      }
      return v;
    },
    initFilterValues() {
      //cached values
      var cachedJson = localStorage.getItem("FILTER_" + this.reportName);
      var cached = cachedJson ? JSON.parse(cachedJson) : {};

      //循环给初始值
      var filterValues = {};
      this.filters.forEach((t) => {
        let initV = this.initFilterValue(t, cached);

        switch (t.component) {
          case "el-input":
            filterValues[t.name] = initV || "";
            break;
          case "ux-number-input":
            filterValues[t.name] = initV || 0;
            break;
          case "el-date-picker":
            filterValues[t.name] = initV; //|| moment();
            break;
          case "el-checkbox":
            filterValues[t.name] = initV || false;
            break;
          case "el-select":
          case "ux-select-dict":
          case "ux-select":
          case "organization-select":
          case "category-select":
          case "area-select":
          case "sql-select":
            if (t.multiple) {
              filterValues[t.name] = initV;
            } else {
              filterValues[t.name] = initV || null;
            }
            break;
          case "sql-ref-select":
            filterValues[t.name] = t.multiple ? [] : null;
            break;
        }

        if (!initV) {
          //异步无法设置初值，所以要先给初值后调用
          if (t.inital && t.initialBySql) {
            this._initBySql(
              t.initialDataSourceId,
              t.inital,
              t.options ? t.options.multiple : false
            ).then((v) => {
              if (v || v === true || v === false) {
                switch (t.component) {
                  case "el-date-picker":
                    filterValues[t.name] = moment(v);
                    break;
                  default:
                    filterValues[t.name] = v;
                    break;
                }
              }
            });
          }
        }
      });
      return filterValues;
    },
  },
  computed: {
    rules() {
      var rules = {};
      this.filters.forEach((t) => {
        if (t.required) {
          rules[t.name] = [
            {
              required: true,
              message: this.l("Required"),
            },
          ];
        }
      });
      return rules;
    },
  },
};

function DAY(n) {
  return moment().add(n, "days").format(format.momentDateFormat);
}

function DAY_TIME(n) {
  return moment().add(n, "days").format(format.momentDateTimeFormat);
}

//挂载内部方法
</script>

<style>
</style>
