<template>
  <div class="ux-pivot" @resize="calcHeight()"
    ref="container" v-loading="loading">
    <DxPivotGrid ref="pivotGrid"
      :data-source="dataSource"
      :allow-sorting-by-summary="true"
      :allow-sorting="true"
      :allow-filtering="true" :show-borders="true"
      :height="height" @exporting="onExporting"
      :allowExpandAll="true"
      @initialized="handleInit"
      @contentReady="handleContentReady"
      @cell-click="onCellClick">
      <DxFieldChooser :enabled="true"
        :allow-search="true"
        apply-changes-mode="onDemand" />
      <DxScrolling mode="virtual" />
      <DxFieldPanel :show-column-fields="true"
        :show-data-fields="true"
        :show-filter-fields="true"
        :show-row-fields="true"
        :allow-field-dragging="true"
        :visible="true" />
      <DxExport :enabled="true" />
    </DxPivotGrid>

    <el-drawer :with-header="false"
      :visible.sync="drawer" direction="btt"
      size="50%" append-to-body>
      <div
        style="width:80%;height:100%;margin:15px auto;">
        <div class="flex"
          style="justify-content: space-between;align-items:center;border-bottom:1px solid #ccc;padding-bottom:5px;">
          <el-select v-model="chartType"
            size="small">
            <el-option v-for="t in chartTypes"
              :key="t" :value="t" :label="t">
              {{l(t)}}
            </el-option>
          </el-select>
          <div>
            <el-button size="small"
              icon="el-icon-printer"
              @click="print"></el-button>
            <el-button size="small"
              icon="el-icon-download"
              @click="saveAs"></el-button>
          </div>
        </div>
        <DxChart ref="chart"
          @initialized="handleChartInit">
          <DxCommonSeriesSettings
            :type="chartType" />
          <DxTooltip :enabled="true"
            :shared="true" />
        </DxChart>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
  DxStateStoring,
  DxFieldPanel,
  DxExport,
} from "devextreme-vue/pivot-grid";

import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,
  DxSize,
  DxTooltip,
} from "devextreme-vue/chart";

import "devextreme/dist/css/dx.carmine.compact.css";
import { getData } from "@/api/uxreport/query.js";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import {
  DxPivotGridFieldChooser,
  DxTexts,
} from "devextreme-vue/pivot-grid-field-chooser";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportPivotGrid } from "devextreme/excel_exporter";
import drillDownDataDialog from "./drillDownDataDialog.vue";
import pivotExport from "./pivot.export";
export default {
  mixins: [pivotExport],
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxPivotGridFieldChooser,
    DxTexts,
    DxScrolling,
    DxStateStoring,
    DxFieldPanel,
    DxExport,
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxTooltip,
  },
  props: {
    reportQuery: {},
    sql: {},
  },
  watch: {
    sql(n, o) {
      if (n != o) {
        this.dataSource.reload();
      }
    },
  },
  data() {
    var fields = this.reportQuery.reportSchema.fields.map((t) => {
      return {
        dataField: t.name,
        caption: t.caption,
        width: t.width,
        area: t.area,
        dataType: this.convertType(t.type),
        format: t.format,
        showGrandTotals: t.totalVisible,
        summaryType: t.summaryType,
        expanded: t.expand,
        displayFolder: t.group,
      };
    });
    var dataSource = new PivotGridDataSource({
      retrieveFields: false,
      fields: fields,
      load: (loadOptions) => {
        return getData({
          dataSourceId: this.reportQuery.dataSourceId,
          sql: this.sql,
        });
      },
    });
    return {
      dataSource: dataSource,
      // state: dataSource.state(),
      height: "100%",
      loading: false,
      drawer: false,
      chartType: "bar",
      chartTypes: [
        "area",
        "bar",
        "bubble",
        "candlestick",
        "fullstackedarea",
        "fullstackedbar",
        "fullstackedline",
        "fullstackedspline",
        "fullstackedsplinearea",
        "line",
        "rangearea",
        "rangebar",
        "scatter",
        "spline",
        "splinearea",
        "stackedarea",
        "stackedbar",
        "stackedline",
        "stackedspline",
        "stackedsplinearea",
        "steparea",
        "stepline",
        "stock",
      ],
    };
  },
  created() {},
  methods: {
    getState() {
      return { state: this.dataSource.state(), options: this.getOptions() };
    },
    loadState(arg) {
      if (this.contentReady) {
        this.tempLoadStateArg = null;
        this.pivotGrid.beginUpdate();
        if (arg.state) {
          this.dataSource.state(arg.state);
        }
        if (arg.options) {
          this.setOptions(arg.options);
        }
        this.pivotGrid.endUpdate();
      } else {
        this.tempLoadStateArg = arg;
      }
    },
    handleContentReady() {
      this.contentReady = true;
      if (this.tempLoadStateArg) {
        this.loadState(this.tempLoadStateArg);
      }
    },
    convertType(dbType) {
      dbType = dbType.toLowerCase();
      if (dbType.indexOf("date") > -1) {
        return "date";
      } else if (
        dbType.indexOf("int") > -1 ||
        dbType.indexOf("double") > -1 ||
        dbType.indexOf("decimal") > -1
      ) {
        return "number";
      } else if (dbType.indexOf("bit") > -1) {
        return "boolean";
      } else {
        return "string";
      }
    },
    calcHeight() {
      this.height = this.$refs.container.clientHeight;
    },
    print() {
      this.$refs.chart.instance.print();
    },
    saveAs() {
      this.$refs.chart.instance.exportTo(this.reportQuery.code, "png");
    },
    onCellClick(e) {
      if (e.area === "data") {
        const pivotGridDataSource = e.component.getDataSource();
        var drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(
          e.cell
        );
        drillDownDataSource.paginate(false);
        var fields = pivotGridDataSource
          .fields()
          .filter(
            (t) =>
              t.area == "row" ||
              t.area == "column" ||
              t.area == "data" ||
              t.area == "filter"
          );
        this.$dialog.open(drillDownDataDialog, {
          dataSource: drillDownDataSource,
          fields: fields,
        });
      }
    },
    getVisibleFields() {
      var rowAreaFields = this.dataSource.getAreaFields("row", true);
    },
    async onExporting(e) {
      e.cancel = true;
      try {
        var res = await this.$confirm(
          this.l("ExportStyle"),
          this.l("ExportData"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.l("Excel"),
            cancelButtonText: this.l("CSV"),
          }
        );
        this.exportExcel(e);
      } catch (action) {
        if (action === "cancel") {
          this.exportCsv(e);
        }
      }
    },
    exportCsv(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.reportQuery.code);
      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.csv.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], {
              type: "application/octet-stream",
            }),
            this.reportQuery.code + ".csv"
          );
        });
      });
    },
    exportExcel(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.reportQuery.code);

      exportPivotGrid({
        component: e.component,
        worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          var fields = e.component.getDataSource().fields();
          //   exportFilterHeaders(worksheet, fields, cellRange);
          //   exportAppliedFilters(worksheet, fields, cellRange);
          this.exportRowHeaders(worksheet, fields, cellRange);
          this.exportDataHeaders(worksheet, fields, cellRange);
          this.exportColumnHeaders(worksheet, fields, cellRange);
        })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: "application/octet-stream",
              }),
              this.reportQuery.code + ".xlsx"
            );
          });
        });
    },
    handleInit(e) {
      this.pivotGrid = e.component;
      this.$emit("init");
    },
    handleChartInit(e) {
      this.pivotGrid.bindChart(e.component, {
        dataFieldsDisplayMode: "splitPanes",
        alternateDataFields: false,
      });
    },
    getOptions() {
      var rowAreaFields = this.dataSource.getAreaFields("row", true);
      var expandAllRow = !rowAreaFields.find((t) => !t.expanded);

      var columnAreaFields = this.dataSource.getAreaFields("column", true);
      var expandAllColumn = !columnAreaFields.find((t) => !t.expanded);

      return {
        expandAllRow,
        expandAllColumn,
        treeHeaderLayout: this.pivotGrid.option("rowHeaderLayout") == "tree",
        showColumnTotals: this.pivotGrid.option("showColumnTotals"),
        showRowTotals: this.pivotGrid.option("showRowTotals"),
        showColumnGrandTotals: this.pivotGrid.option("showColumnGrandTotals"),
        showRowGrandTotals: this.pivotGrid.option("showRowGrandTotals"),
      };
    },
    setOptions(options) {
      var sourceOptions = this.getOptions();
      for (var p in sourceOptions) {
        if (sourceOptions[p] != options[p]) {
          if (p == "expandAllRow") {
            this.expandAllRow(options[p]);
          } else if (p == "expandAllColumn") {
            this.expandAllColumn(options[p]);
          } else if (p == "rowHeaderLayout") {
            this.treeHeaderLayout(options[p]);
          } else {
            this.pivotGrid.option(p, options[p]);
          }
        }
      }
    },
    showChart() {
      this.drawer = true;
    },
    resetLayout() {
      localStorage.removeItem("PIVOT_" + this.reportQuery.code);
      this.dataSource.state(null);
    },
    //extend functions
    expandAllRow(arg) {
      var rowAreaFields = this.dataSource.getAreaFields("row", true);
      if (arg) {
        rowAreaFields.forEach((t) => {
          this.dataSource.expandAll(t.dataField);
        });
      } else {
        rowAreaFields.forEach((t) => {
          this.dataSource.collapseAll(t.dataField);
        });
      }
    },
    expandAllColumn(arg) {
      var rowAreaFields = this.dataSource.getAreaFields("column", true);
      if (arg) {
        rowAreaFields.forEach((t) => {
          this.dataSource.expandAll(t.dataField);
        });
      } else {
        rowAreaFields.forEach((t) => {
          this.dataSource.collapseAll(t.dataField);
        });
      }
    },
  },
  mounted() {
    this.calcHeight();
  },
};
</script>

<style lang="scss">
.ux-pivot {
  width: 100%;
  height: 100%;
}

.dxc-tooltip {
  z-index: 9999;
}
</style>
