import request from '@/utils/request'

// 查询Item Pool列表
export function read({ userNotificationId, state, readAll }) {
    return request({
        url: '/uxretail/sysnotification/read',
        method: 'post',
        data: { userNotificationId, state, readAll }
    })
}

