<template>
  <div class="n-toolbar" :style="{ 'text-align': align }">
    <template v-for="(group, index) in actions">
      <el-button-group :key="index" class="ml5">
        <template v-for="(item, index1) in group">
          <template v-if="item.componentName">
            <component :is="item.componentName" v-bind="item.options" v-on="item.events" :key="index1"></component>
          </template>
          <template v-else-if="!item.actions">
            <el-button :class="item.class" :type="item.type" :size="btnSize" :key="item.id" :disabled="item.disabled"
              @click="handleClick(item)" :round="false" :icon="item.icon">{{ item.text }}</el-button>
          </template>
          <template v-else>
            <el-button :type="item.type" :size="btnSize" :key="item.id" :class="item.class" :disabled="item.disabled"
              :icon="item.icon">
              <el-dropdown @command="handleCommand" trigger="click" style="color: inherit">
                <span class="el-dropdown-link">
                  {{ item.text }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <template v-for="act in item.actions">
                    <el-dropdown-item :key="act.id" :command="act" v-if="!act.hidden">
                      <i :class="act.icon" v-if="act.icon"></i>
                      {{ act.text }}
                    </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </el-button>
          </template>
        </template>
      </el-button-group>
    </template>
  </div>
</template>

<script>
/**
 * [
 *   [
 *   {
 *     id: 唯一标识
 *     type: 类型[缺省,primary,success,info,warning,danger]
 *     disabled:禁用
 *     text:文字
 *     icon:图标
 *   },..
 *   ]  -- Group
 * ]
 */
export default {
  name: "n-toolbar",
  props: {
    actions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    align: {
      type: String,
      default: "right",
    },
    btnSize: {
      type: String,
      default: "medium",
    },
  },
  methods: {
    handleCommand(command) {
      if (command.handler) {
        command.handler(command);
      } else {
        this.handleClick(command);
      }
    },
    handleClick(act) {
      if (act.handler) {
        act.handler(act);
      } else {
        this.$emit("click", act.id);
      }
    },
  },
};
</script>
<style lang="scss">
.n-toolbar {
  margin: 5px 0px 5px 5px;
  .el-button {
    background-color: #707070 !important;
    color: #fff !important;
    border-left-color: #707070 !important;
    border-right-color: #707070 !important;
    border: 1px solid #dcdfe6 !important;
  }
  .el-button-group > .el-button + .el-button {
    margin-right: -1px;
  }

  .el-button:disabled {
    background-color: #bdbdbd !important;
  }
}
</style>
