import request from '@/utils/request'

// 查询【请填写功能名称】列表
export function listSysDragLayout({ keyword }) {
    return request({
        url: '/system/SysDragLayout/list',
        method: 'post',
        data: { keyword }
    })
}


// 查询【请填写功能名称】列表
export function pageSysDragLayout({ keyword }, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/system/SysDragLayout/page',
        method: 'post',
        data: { keyword },
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
// 查询【请填写功能名称】详细
export function infoSysDragLayout(id, code) {
    return request({
        url: '/system/SysDragLayout/info',
        method: 'post',
        data: { id }
    })
}

// 新增【请填写功能名称】
export function saveSysDragLayout(data) {
    return request({
        url: '/system/SysDragLayout/save',
        method: 'post',
        data: data
    })
}

// 删除【请填写功能名称】
export function deleteSysDragLayout(id) {
    return request({
        url: '/system/SysDragLayout/delete',
        method: 'post',
        data: { id }
    })
}
