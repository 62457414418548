<template>
  <el-card class="n-layout" :class="{'n-layout-no-toolbar':(actions==null||actions.length==0)&&title==''}">
    <div slot="header" v-if="(actions&&actions.length>0)||title!=''">
      <el-link class="n-layout-title"  :underline="false" type="primary">
        <el-button type="text" circle icon="el-icon-back" v-if="showBack" @click="handleBackClick"></el-button>
        <i :class="titleIcon" v-if="titleIcon"></i>
        &nbsp;{{layoutTitle}}
        <slot name="title-append"></slot>
        <small>{{description}}</small>
      </el-link>
      <div class="n-toolbar-panel">
        <n-toolbar :actions="actions" @click="handleClick"></n-toolbar>
      </div>
      <div class="n-toolbar-panel">
        <slot name="custom-actions"></slot>
      </div>
    </div>
    <div class="n-layout-container" :style="{'height':showFooter?'auto':'100%'}">
      <div :class="mainClass" :style="{'padding-bottom':showFooter?'60px':'10px'}">
        <slot></slot>
      </div>
    </div>

    <div class="n-layout-footer" v-if="showFooter">
      <slot name="footer"></slot>
    </div>
  </el-card>
</template>
<script>
import nToolbar from "../toolbar/nToolbar.vue";
import abstractLayout from "./abstractLayout";
export default {
  name: "n-layout",
  components: {
    nToolbar,
  },
  mixins: [nToolbar, abstractLayout],
  props: {
    mainClass: {
      type: String,
      default: "n-layout-main-class",
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    nameProperty: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick(actId) {
      this.$emit("click", actId);
    },
  },
  computed: {
    layoutTitle() {
      if (this.title) {
        return this.title;
      }
      let title = "";
      if (this.item) {
        if (this.item.id) {
          title = `${this.l("Edit")}${
            this.nameProperty ? "(" + this.item[this.nameProperty] + ")" : ""
          }`;
        } else {
          title = `${this.l("Create")}`;
        }
      }
      return title;
    },
  },
};
</script>
<style lang="scss">
.n-layout {
  height: 100%;
  .n-layout-title {
    font-weight: bold; 
    line-height: 46px;
    font-size: 18px;
  }
  .n-toolbar-panel {
    float: right;
  }
  .el-card__header {
    padding: 5px 15px;
  }
  > .el-card__body {
    overflow-y: auto;
    height: calc(100% - 57px);
    width: 100%;
    padding: 0;
    .n-layout-container {
      height: auto;
      min-height: 100%;
      width: 100%;
    }
    .n-layout-footer {
      height: 60px;
      width: 100%;
      line-height: 46px;
      margin-top: -60px;
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 400;
      color: #a9a9aa;
      padding: 7px 30px;
    }
  }
  .n-layout-main-class {
    position: relative;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
  }
}
</style>
