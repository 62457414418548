import request from '@/utils/request'

// 登录方法
export function login(username, password,shopOrgCode,counter, code, uuid) {
  const data = {
    username,
    password,
    shopOrgCode,
    counter,
    // code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 第一次登录强制修改密码
export function needChangePassword(data) {
  return request({
    url: '/changePasswordByLogin',
    method: 'post',
    data: data
  })
}

// 获取验证码
// export function getCodeImg() {
//   return request({
//     url: '/captchaImage',
//     method: 'get'
//   })
// }