<template>
<n-dialog-layout :title="l('SaveLayout')" ref="dialog">
    <div class="has-n-row">
        <el-form :model="item" :rules="rules" ref="form">
            <ux-form-item prop="name" :label="l('Name')">
                <el-input v-model="item.name">
                </el-input>
            </ux-form-item>
            <ux-form-item prop="isDefault">
                <el-checkbox v-model="item.isDefault">
                    {{l('Default')}}
                </el-checkbox>
            </ux-form-item>
            <ux-form-item prop="isPublic">
                <el-checkbox v-model="item.isPublic">
                    {{l('Public')}}
                </el-checkbox>
            </ux-form-item>
        </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">{{l('Cancel')}}</el-button>
        <el-button type="primary" @click="ok(0)">{{l('Save')}}</el-button>
        <el-button type="primary" @click="ok(1)" v-if="item.id">{{l('SaveAsNew')}}</el-button>
    </div>
</n-dialog-layout>
</template>

<script>
export default {
    data() {
        return {
            rules: {
                name: [{
                    required: true
                }],
            },
            item: {}
        };
    },
    methods: {
        open(data) {
            this.item = data || {
                isDefault: false,
                isPublic: false
            }
            this.oriName = this.item.name
            return this.$refs.dialog.open();
        },
        ok(type) {
            this.$refs.form.validate(v => {
                if (v) {
                    if (type == 1) {
                        if (this.oriName == this.item.name) {
                            this.$message.error(this.l('PleaseUseNewName'))
                            return;
                        }
                        this.item.id = null
                    }
                    this.$refs.dialog.close(this.item);
                }
            })
        },
        cancel() {
            this.$refs.dialog.cancel();
        },
    },
};
</script>
