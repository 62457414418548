<template>
  <div class="right-menu-item ux-message-box">
    <el-popover ref="popMessage"
      placement="bottom-end" width="360"
      trigger="click">
      <div class="ux-message-box-content"
        v-loading="loading">
        <el-tabs v-model="selectTabs" type="card">
          <el-tab-pane class="message"
            :label="l('Notice')" name="notice"
            style="height: 300px;overflow-y: auto; border: 0px;">
            <span slot="label">
              <i class="el-icon-chat-square"></i>
              {{l('Notice')}} ({{notice.length}})
            </span>
            <div
              v-for="(notification,index) in notice"
              :key="index">
              <div class="message-body">
                <div class="flex-between">
                  <div>
                    <i :class="notification.data.icon"
                      style="color:#67c23a"></i>
                    <a class="message-title"
                      v-if="notification.data.type=='link'">{{notification.data.title}}
                    </a>
                    <span class="message-title"
                      v-else>{{notification.data.title}}
                    </span>
                  </div>
                  <el-button size="mini"
                    v-if="notification.state==0"
                    type="text"
                    @click="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">
                    {{l("SetAsRead")}}</el-button>
                </div>
                <div class="flex-between">
                  <span
                    class="message-description">{{notification.data.description}}</span>
                  <el-tag class="message-time"
                    size="mini">
                    {{getLoginAttemptTime(notification.creationTime)}}
                  </el-tag>
                </div>
              </div>
            </div>
            <div class="message-no-content"
              v-if="!notice.length">
              {{l("ThereIsNoNotification")}}</div>
          </el-tab-pane>
          <el-tab-pane class="message" name="task"
            style="height: 300px;overflow-y: auto; border: 0px;">
            <span slot="label">
              <i class="el-icon-eleme"></i>
              {{l('Task')}} ({{task.length}})
            </span>
            <div
              v-for="(notification,index) in task"
              :key="index">
              <div class="message-body">
                <div class="flex-between">
                  <div>
                    <i :class="notification.data.icon"
                      style="color:#67c23a"></i>
                    <a class="message-title"
                      v-if="notification.data.type=='link'"
                      @click="handleLink(notification.data.extendData)">{{notification.data.title}}
                    </a>
                    <span class="message-title"
                      v-else>{{notification.data.title}}
                    </span>
                  </div>
                  <el-button size="mini"
                    v-if="notification.state==0"
                    type="text"
                    @click="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">
                    {{l("SetAsRead")}}</el-button>
                </div>
                <div class="flex-between">
                  <span
                    class="message-description">{{notification.data.description}}</span>
                  <el-tag class="message-time"
                    size="mini">
                    {{getLoginAttemptTime(notification.creationTime)}}
                  </el-tag>
                </div>
              </div>
            </div>
            <div class="message-no-content"
              v-if="!task.length">
              {{l("ThereIsNoTask")}}</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="flex-between mt20">
        <router-link size="mini"
          to="/loadNotification"><i
            class="iconfont icon-fullscreen"></i>
        </router-link>
        <el-button size="mini" type="primary"
          @click="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
          v-if="unreadNotificationCount">
          {{l("SetAllAsRead")}}</el-button>
      </div>
      <a slot="reference">
        <el-badge v-if="unreadNotificationCount>0"
          :value="unreadNotificationCount">
          <i class="fa fa-bell-o fa-fw"
            style="font-size:20px !important;"></i>
        </el-badge>
        <i class="fa fa-bell-o fa-fw"
          style="font-size:20px !important;"
          v-else></i>
      </a>
    </el-popover>
  </div>
</template>

<script>
import QueryAdapter from "@/utils/QueryAdapter.js";
import store from "@/store";
import moment from "moment";
import { read } from "@/api/uxretail/notify/sysNotification.js";
import VFrame from "@/framework/VFrame.js";
import { getToken } from "@/utils/auth";
var ws; //WebSocket连接对象
export default {
  name: "message-box",
  data() {
    return {
      selectTabs: "notice",
      unreadNotificationCount: 0,
      notifications: [],
      loading: false,
    };
  },
  methods: {
    initWs() {
      var prefixUrl = VFrame.wsUrl;
      console.log(prefixUrl + getToken());
      ws = new WebSocket(prefixUrl + getToken());
      //连接成功建立的回调方法
      ws.onopen = (event) => {
        console.log("socket connection success");
      };

      //接收到消息的回调方法
      ws.onmessage = (event) => {
        if (event.data) {
          var d = JSON.parse(event.data);
          if (d.type == "notify") {
            this.$notify.info({
              title: d.data,
            });
            this.fetchNotify();
          } else if (d.type == "QueryBuilderUpdated") {
            //query builder更新
            //刪除緩存的
            var messageData = JSON.parse(d.data);
            console.log("QueryBuilderUpdated" + d.data);
            sessionStorage.removeItem("QUERY-" + messageData.data);
          }
        }
      };

      //连接发生错误的回调方法
      ws.onerror = (event) => {
        ws = null;
        this.initWs();
      };
      //连接关闭的回调方法
      ws.onclose = (event) => {
        ws = null;
      };
    },
    fetchNotify() {
      this.loading = true;
      QueryAdapter.excute(
        "QUERY-NotifyPage",
        {
          userId: store.getters.userId,
        },
        {
          pageNum: 1,
          pageSize: 100,
        }
      )
        .then((res) => {
          this.notifications = res.list.map((t) => ({
            ...t,
            data: JSON.parse(t.data),
          }));
          this.unreadNotificationCount = res.list.filter(
            (t) => t.state == 0
          ).length;
        })
        .finally(() => (this.loading = false));
    },
    handleLink(url) {
      this.$router.push(
        url,
        () => {},
        () => {}
      );
    },
    getLoginAttemptTime(time) {
      return moment(time).fromNow();
    },
    setAllNotificationsAsRead() {
      this.loading = true;
      read({
        readAll: true,
      })
        .then(() => {
          this.fetchNotify();
        })
        .finally(() => (this.loading = false));
    },
    setNotificationAsRead(userNotification) {
      this.loading = true;
      read({
        userNotificationId: userNotification.userNotificationId,
        state: 1,
      })
        .then(() => {
          userNotification.state = 1;
        })
        .finally(() => (this.loading = false));
    },
    getUiIconBySeverity(severity) {
      switch (severity) {
        case abp.notifications.severity.SUCCESS:
          return "fa fa-check";
        case abp.notifications.severity.WARN:
          return "fa fa-warning";
        case abp.notifications.severity.ERROR:
          return "fa fa-bolt";
        case abp.notifications.severity.FATAL:
          return "fa fa-bomb";
        case abp.notifications.severity.INFO:
        default:
          return "fa fa-info";
      }
    },
  },
  computed: {
    notice() {
      var noticeList = this.notifications.filter(
        (t) => t.notificationName != "task"
      );
      return noticeList;
    },
    task() {
      var taskList = this.notifications.filter(
        (t) => t.notificationName == "task"
      );
      return taskList;
    },
  },
  created() {
    this.fetchNotify();
    this.initWs();
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/element-variables.scss";

.ux-message-box {
  .content {
    padding: -8px -16px;
  }

  .noFound {
    text-align: center;
    padding: 73px 0 88px;
    color: rgba(0, 0, 0, 0.45);
  }

  .noFound .iconfont {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .noTitle {
    font-size: 14px;
  }

  .list {
    max-height: 400px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }

  .list * {
    outline: none;
  }

  .list-item {
    border-bottom: 1px solid #e8e8e8;
  }

  .list-item {
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .list-item:hover {
    background: #e6f7ff;
  }

  .list-item-meta {
    -ms-flex-align: start;
    align-items: flex-start;
    display: flex;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    font-size: 0;
    width: 100%;
  }

  .el-badge {
    vertical-align: text-bottom;
  }

  .el-badge__content.is-fixed {
    top: 10px;
  }
}

.ux-message-box-content {
  a.message-title {
    text-decoration: underline;
  }

  .message-title {
    padding-left: 2px;
    font-size: 14px;
    font-weight: 600;
  }

  .message-description {
    padding-left: 2px;
    font-size: 12px;
    font-weight: 400;
  }

  .message-body {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;

    .message-title {
      color: $--color-primary;
    }
  }

  .message-body:hover {
    background: #f95603;
    color: #fff !important;

    .message-title {
      color: #fff;
    }
  }

  .message-sub_content {
    font-size: 90%;
    font-weight: 400;
    padding-top: 5px;
  }

  .message-time {
  }

  .message-no-content {
    text-align: center;
    color: #e4e4e4;
    line-height: 300px;
    font-size: 22px;
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  .message::-webkit-scrollbar {
    width: 6px;
    height: 16px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  .message::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #eeeeee;
    background-color: #e4e4e4;
  }

  /*定义滑块 内阴影+圆角*/
  .message::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #707070;
    background-color: #707070;
  }
}
</style>
