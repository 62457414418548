import Vue from 'vue'
import NP from 'number-precision'

Vue.prototype.$NP = NP;


//业务扩展
Number.prototype.cost = function (arg) {
    return NP.round(this, 2);
}
Number.prototype.currency = function (arg) {
    return NP.round(this, 6);
}
Number.prototype.amount = function (arg) {
    return NP.round(this, 2);
}
Number.prototype.price = function (arg) {
    return NP.round(this, 2);
}

Number.prototype.percent = function (arg) {
    return NP.round(this, 2);
}


//除法
Number.prototype.div = function (arg) {
    return NP.divide(this, arg || 0);
}

//乘法
Number.prototype.mul = function (arg) {
    return NP.times(this, arg || 0);
}

//加法
Number.prototype.add = function (arg) {
    return NP.plus(this, arg || 0);
}

//减法
Number.prototype.sub = function (arg) {
    return NP.minus(this, arg || 0);
}

//round
Number.prototype.round = function (arg) {
    return NP.round(this, arg || 0);
}

String.prototype.div = function (arg) {
    return NP.divide(this, arg || 0);
}

String.prototype.mul = function (arg) {
    return NP.times(this, arg || 0);
}

String.prototype.add = function (arg) {
    return NP.plus(this, arg || 0);
}

String.prototype.sub = function (arg) {
    return NP.minus(this, arg || 0);
}

String.prototype.contains = function (arg) {
    return this.indexOf(arg) > -1;
}