<template>
<div class="ux-pivot" @resize="calcHeight()" ref="container" v-loading="loading">
    <DxDataGrid ref="grid" :data-source="dataSource" :columns="columns" :row-alternation-enabled="true" @initialized="$emit('init')" :allow-column-reordering="true" :allow-column-resizing="true" :show-borders="true" @exporting="onExporting" width="100%" :height="height">
        <DxLoadPanel :enabled="true" />
        <DxScrolling mode="virtual" />
        <DxSorting mode="none" />
        <DxExport :enabled="true" />
        <DxGroupPanel :visible="true" />
        <DxSearchPanel :visible="true" />
        <DxGrouping />
        <DxSummary v-if="summaryColumns&&summaryColumns.length>0">
            <template v-for="s in summaryColumns">
                <template v-for="su in s.summaryTypes">
                    <DxTotalItem :column="s.name" :summary-type="su" :key="s.dataField+'-'+su" />
                </template>
            </template>

        </DxSummary>
    </DxDataGrid>
</div>
</template>

<script>
import {
    DxDataGrid,
    DxScrolling,
    DxSorting,
    DxLoadPanel,
    DxSummary,
    DxTotalItem,
    DxExport,
    DxGroupPanel,
    DxSearchPanel,
    DxGrouping,
} from "devextreme-vue/data-grid";

import "devextreme/dist/css/dx.carmine.compact.css";
import {
    getData
} from "@/api/uxreport/query.js";
import {
    Workbook
} from "exceljs";
import {
    saveAs
} from "file-saver";
import {
    exportDataGrid
} from "devextreme/excel_exporter";

export default {
    components: {
        DxDataGrid,
        DxScrolling,
        DxSorting,
        DxLoadPanel,
        DxSummary,
        DxTotalItem,
        DxExport,
        DxGroupPanel,
        DxSearchPanel,
        DxGrouping,
    },
    props: {
        reportQuery: {},
        sql: {},
    },
    watch: {
        sql(n, o) {
            if (n != o) {
                this.fetch();
            }
        },
    },
    data() {
        var columns = this.reportQuery.reportSchema.fields.map((t) => {
            return {
                dataField: t.name,
                caption: t.caption,
                width: t.width || "auto",
                dataType: this.convertType(t.type),
                format: t.format,
                fixed: t.fix,
            };
        });

        var summaryColumns = this.reportQuery.reportSchema.fields.filter(
            (t) => t.summaryTypes && t.summaryTypes.length > 0
        );

        return {
            dataSource: [],
            columns,
            summaryColumns,
            height: 470,
            loading: false,
        };
    },
    created() {
        this.fetch();
    },
    methods: {
        getState() {
            return this.$refs.grid.instance.state()
        },
        loadState(state) {
            this.$refs.grid.instance.state(state)
        },
        fetch() {
            this.loading = true;
            getData({
                    dataSourceId: this.reportQuery.dataSourceId,
                    sql: this.sql,
                })
                .then((res) => (this.dataSource = res))
                .finally(() => (this.loading = false));
        },
        convertType(dbType) {
            dbType = dbType.toLowerCase();
            if (dbType.indexOf("date") > -1) {
                return "date";
            } else if (
                dbType.indexOf("int") > -1 ||
                dbType.indexOf("double") > -1 ||
                dbType.indexOf("decimal") > -1
            ) {
                return "number";
            } else if (dbType.indexOf("bit") > -1) {
                return "boolean";
            } else {
                return "string";
            }
        },
        calcHeight() {
            this.height = this.$refs.container.clientHeight;
        },
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(this.reportQuery.code);

            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], {
                            type: "application/octet-stream"
                        }),
                        this.reportQuery.code + ".xlsx"
                    );
                });
            });
            e.cancel = true;
        },
    },
    mounted() {
        this.calcHeight();
    },
};
</script>

<style lang="scss">
.ux-pivot {
    width: 100%;
    height: 100%;
}
</style>
