<template>
  <div class="has-n-row" v-loading="loading">
    <template v-for="(f, j) in attrData">
      <template v-if="j % 3 == 0">
        <n-row :key="j">
          <el-col :span="8" v-for="i in [0,1,2]"
            :key="i">
            <template
              v-if="(j+i)<attrData.length">
              <ux-form-item
                :prop="'attrsData.' + attrData[j+i].id">
                <label slot="label">
                  <label
                    v-text="attrData[j+i].label"></label>
                  <el-tooltip effect="dark"
                    v-if="attrData[j+i].remark">
                    <div slot="content"
                      v-html="attrData[j+i].remark">
                    </div>
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </label>
                <component
                  :is="attrData[j+i].component"
                  v-bind="attrData[j+i].options"
                  v-model="item.attrsData[attrData[j+i].id]">
                  <template
                    v-if="attrData[j+i].component=='el-select'">
                    <el-option
                      v-for="opt in attrData[j+i].options.selectOptions"
                      :value="opt" :key="opt">
                    </el-option>
                  </template>
                </component>
              </ux-form-item>
            </template>
          </el-col>
        </n-row>
      </template>
    </template>
  </div>
</template>

<script>
import { getDynamicAttributeList } from "@/api/uxretail/ref/dynamicAttribute.js";
import MultipleSelect from "./tpl/MultipleSelect.vue";
export default {
  components: {
    "multiple-select": MultipleSelect,
  },
  name: "ux-dynamic-attributes",
  props: {
    attributeGroup: {},
    rules: {},
    item: {},
    dynamicAttributeList: {},
  },
  data() {
    return {
      loading: true,
      attrData: [],
    };
  },
  watch: {
    "item.attrsData": {
      deep: true,
      handler: function (val, oldVal) {
        if (this.item.attrsData) {
          this.updateDynamicResult();
        }
      },
    },
    "item.syncRowVersion"() {
      console.log("syncRowVersion");
      this.updateDynamic();
    },
  },
  methods: {
    searchByCode() {},
    //获取产品动态属性
    getAttributes() {
      this.loading = true;
      getDynamicAttributeList(this.attributeGroup)
        .then((result) => {
          this.attrData = result.items.map((t) => {
            t.options = JSON.parse(t.options || "{}");
            if (t.component == "el-date-picker") {
              t.options = {
                ...t.options,
                type: t.options.dateType,
              };
            } else if (t.component == "ux-select-dict") {
              t.options = {
                ...t.options,
                number: false,
                multipleOption: ",",
              };
            } else if (t.component == "el-select" && t.options.multiple) {
              t.component = "multiple-select";
            }
            return t;
          });

          this.updateDynamic();
          this.$emit("update:rules", this.rules);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("loaded", this.loading);
          this.$emit("getAttrs", this.attrData);
        });
    },
    updateDynamic() {
      this.$set(this.item, "attrsData", {});
      let data = {};
      this.attrData.forEach((t) => {
        //设置值
        let exsitAttribute = this.dynamicAttributeList.find(
          (x) => x.dynamicAttributeId == t.id
        );
        data[t.id] = exsitAttribute
          ? exsitAttribute.dynamicAttributeValue
          : null;
        //设置规则
        var rule = {};
        if (t.rule) {
          rule = JSON.parse(t.rule);
        }
        if (rule.required) {
          if (t.component == "el-select" && t.options.multiple) {
            //特殊
            this.rules["attrsData." + t.id] = [
              {
                validator: (rule, value, callback) => {
                  if (!value || value.length == 0) {
                    return callback(new Error(this.l("Required")));
                  } else {
                    callback();
                  }
                },
              },
            ];
          } else {
            this.rules["attrsData." + t.id] = [rule];
          }
        }
      });
      console.log(data);
      this.$set(this.item, "attrsData", data);
    },
    updateDynamicResult() {
      this.attrData.forEach((t) => {
        //设置值
        let exsitAttribute = this.dynamicAttributeList.find(
          (x) => x.dynamicAttributeId == t.id
        );

        let value = this.item.attrsData ? this.item.attrsData[t.id] : null;

        if (exsitAttribute) {
          exsitAttribute.dynamicAttributeValue = value;
        } else {
          this.dynamicAttributeList.push({
            dynamicAttributeId: t.id,
            dynamicAttributeCode: t.code,
            lineSort: t.sort,
            dynamicAttributeValue: value,
          });
        }
      });
    },
  },
  created() {
    this.getAttributes();
  },
};
</script>
 