<template>
  <ux-select v-bind="options" v-on="localEvents" @change="handleChange" :api="options.api" v-model="currentValue"
    :filterDto="options.filterDto">
  </ux-select>
</template>

<script>
//:labelPath="options.bindRowTextField" :valuePath="options.bindRowValueField"
export default {
  props: {
    params: {}, //row,column
    options: {
      //bindRowTextField   这两个对应的是row的 text和value field
      //bindRowValueField
      //labelPath，valuePath 對應的是組件内options的text和value
      api: {
        default: () => {
          api: "";
        },
      },
      filterDto: {
        default: () => ({ keyword: "" }),
      },
    },
    events: {},
  },
  data() {
    return {
      currentValue: _.get(this.params.row, this.options.bindRowValueField),
      currentText: _.get(this.params.row, this.options.bindRowTextField),
    };
  },
  methods: {
    handleChange({ row, value, label }) {
      if (row) {
        _.set(this.params.row, this.options.bindRowValueField, value);
        _.set(this.params.row, this.options.bindRowTextField, label);
      } else {
        _.set(this.params.row, this.options.bindRowValueField, null);
        _.set(this.params.row, this.options.bindRowTextField, "");
      }
    },
  },
  watch: {
    // currentValue() {
    //   _.set(this.params.row, this.options.bindRowValueField, this.currentValue);
    // },
    // currentText() {
    //   _.set(this.params.row, this.options.bindRowTextField, this.currentText);
    // },
  },
  computed: {
    localEvents() {
      let temp = {};
      for (var p in this.events) {
        let event = this.events[p];
        let row = this.params.row;
        temp[p] = function () {
          event(
            row,
            arguments[0],
            arguments[1],
            arguments[2],
            arguments[3],
            arguments[4],
            arguments[5]
          );
        };
      }
      return temp;
    },
  },
  created() {},
};
</script>

<style>
</style>