<template>
  <n-dialog-layout :title="l('Setting')" ref="dialog">
    <div class="has-n-row">
      <template v-if="options.type=='pivot'">
        <n-row>
          <el-col :span="8">
            <el-switch v-model="options.expandAllRow" :active-text="l('ExpandAllRow')">
            </el-switch>
          </el-col>
          <el-col :span="8">
            <el-switch v-model="options.expandAllColumn" :active-text="l('ExpandAllColumn')">
            </el-switch>
          </el-col>
          <el-col :span="8">
            <el-switch v-model="options.treeHeaderLayout" :active-text="l('TreeHeaderLayout')">
            </el-switch>
          </el-col>
        </n-row>
        <n-row class="mt10">
          <el-col :span="8">
            <el-switch v-model="options.showColumnTotals" :active-text="l('ShowColumnTotals')">
            </el-switch>
          </el-col>
          <el-col :span="8">
            <el-switch v-model="options.showRowTotals" :active-text="l('ShowRowTotals')">
            </el-switch>
          </el-col>
          <el-col :span="8">
            <el-switch v-model="options.showColumnGrandTotals" :active-text="l('ShowColumnGrandTotals')">
            </el-switch>
          </el-col>
        </n-row>
        <n-row class="mt10">
          <el-col :span="8">
            <el-switch v-model="options.showRowGrandTotals" :active-text="l('ShowRowGrandTotals')">
            </el-switch>
          </el-col>
        </n-row>
      </template>

    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel()">{{l('Cancel')}}</el-button>
      <el-button type="primary" @click="ok()">{{l('Confirm')}}</el-button>
    </div>
  </n-dialog-layout>
</template>
<script>
export default {
  data() {
    return {
      options: {},
    };
  },
  methods: {
    open(options) {
      this.options = options;
      return this.$refs.dialog.open();
    },
    ok() {
      this.$refs.dialog.close(this.options);
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
  },
};
</script>
