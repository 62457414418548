<template>
  <el-select :multiple="multiple" filterable
    v-model="currentValue" @change="handleChange"
    :clearable="true" v-loading="loading">
    <el-option v-for="opt in options"
      :value="opt.value" :label="opt.text"
      :key="opt.value">
      {{opt.text}}</el-option>
  </el-select>
</template>

<script>
import { getSqlFilters } from "@/api/uxreport/query.js";
import RsHelper from "@/utils/RsHelper.js";
import ReportUtil from "@/components/Report/reportUtil.js";
export default {
  props: ["value", "dataSourceId", "multiple", "dataSourceSql", "filterValues"],
  data() {
    return {
      currentValue: this.value,
      options: [],
      loading: false,
      variables: [],
      triggerCount: 0,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  methods: {
    fetch(sql, value) {
      if (this.lastSql) {
        if (this.lastSql == sql && this.lastValue == value) {
          return;
        }
      }
      this.lastSql = sql; //上次执行的SQL
      this.lastValue = value;

      this.loading = true;
      getSqlFilters({
        sql: sql,
        dataSourceId: this.dataSourceId,
        value: value,
      })
        .then((res) => {
          this.options = res.items;
          if (!this.options.find((x) => x.value == this.currentValue)) {
            this.currentValue = null;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleChange() {
      this.$emit("input", this.currentValue);
    },
    _canFetch() {
      var allHasValue = true;
      var sql = this.dataSourceSql;
      var rVarArray = ReportUtil.getVariables();
      for (var i = 0; i < rVarArray.length; i++) {
        sql = sql.replaceAll("#" + rVarArray[i].name + "#", rVarArray[i].value);
      }
      for (var i = 0; i < this.variables.length; i++) {
        var t = this.variables[i];
        if (
          this.filterValues[t] ||
          (typeof this.filterValues[t] == "number" &&
            this.filterValues[t] === 0) ||
          (typeof this.filterValues[t] == "object" &&
            this.filterValues[t] &&
            this.filterValues[t].hasOwnProperty("length") &&
            this.filterValues[t].length > 0)
        ) {
          let v = "";
          if (
            typeof this.filterValues[t] == "object" &&
            this.filterValues[t] &&
            this.filterValues[t].hasOwnProperty("length")
          ) {
            if (this.filterValues[t].length > 0) {
              v = this.filterValues[t].map((x) => "'" + x + "'").join(",");
            } else {
              //增加一重判斷 如果為空則不需要鍵入
              allHasValue = false;
              break;
            }
          } else {
            v = this.filterValues[t];
          }
          sql = sql.replaceAll("{" + t + "}", v);
        } else {
          allHasValue = false;
          break;
        }
      }
      if (!allHasValue) {
        this.currentValue = null;
        this.options = [];
      }
      return allHasValue ? sql : "";
    },
    handleRefChange() {
      var sql = this._canFetch();
      if (sql) {
        this.fetch(sql, null);
      }
    },
    fetchByValue() {
      var sql = this._canFetch(form);
      if (sql) {
        this.fetch(sql, this.currentValue);
      }
    },
  },
  created() {
    this.variables = RsHelper.getFilterParamsFromSql(this.dataSourceSql);
    this.$root.eventBus.$on("ref-change", () => {
      this.triggerCount = this.triggerCount + 1;
      var count = this.triggerCount;
      setTimeout(() => {
        if (count < this.triggerCount) {
          return;
        }
        this.handleRefChange();
      }, 500);
    });
    if (this.currentValue) {
      this.fetchByValue();
    } else {
      this.handleRefChange(); //初始化触发一次
    }
  },
  destroyed() {
    this.$root.eventBus.$off("ref-change");
  },
};
</script>

<style>
</style>