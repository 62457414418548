import VFrame from '@/framework/VFrame'
import Vue from 'vue'
import CONSTANT from '@/CONSTANT.js'

export default {
    namespaced: true,
    state: {
        dragLayouts: [],
        routerName2DragLayouts: {},
        tableSchema: []
    },
    mutations: {
        updateSchema(state, playload) {
            var exist = state.tableSchema.find(t => t.sid == playload.sid)
            if (exist) {
                exist.json = playload.schema.json
                exist.appJson = playload.schema.appJson
            } else {
                state.tableSchema.push({
                    sid: playload.sid,
                    json: playload.schema.json,
                    appJson: playload.schema.appJson
                })
            }
        },
        addOrUpdateLayout(state, playload) {
            console.log('store:' + playload.name)
            if (
                state.routerName2DragLayouts[playload.name]
            ) {
                state.routerName2DragLayouts[playload.name].push(playload.layout);
            } else {
                Vue.set(state.routerName2DragLayouts, playload.name, [playload.layout])
            }
        },
        removeLayout(state, playload) {
            state.routerName2DragLayouts[playload.name].remove(playload.layout)
        },
        initLayout(state) {
            state.dragLayouts = [];
            state.routerName2DragLayouts = {}
            state.dragLayouts = VFrame.applicationResource[CONSTANT.DRAG_LAYOUTS]
            if (state.dragLayouts) {
                for (var i = 0; i < state.dragLayouts.length; i++) {
                    let layout = state.dragLayouts[i]
                    if (state.routerName2DragLayouts[layout.routerName]) {
                        state.routerName2DragLayouts[layout.routerName].push(layout)
                    } else {
                        state.routerName2DragLayouts[layout.routerName] = [layout]
                    }
                }
            }
        }
    },
}