<template>
  <el-button @click="()=>print()"
    :loading="loading" size="medium">
    {{l('Print')}}</el-button>
</template>

<script>
import { infoRpQuery } from "@/api/uxreport/query.js";
import ReportUtil from "../Report/reportUtil.js";
import filterDialog from "@/components/ReportQuery/filterDialog.vue";
export default {
  name: "ReportButton",
  data() {
    return {
      loading: false,
      reportJson: null,
    };
  },
  props: {
    objectId: {},
    code: {},
  },
  methods: {
    doPrint(inputFilters) {
      let filters = this.copy(this.reportQuery.reportSchema.filters);
      for (var i = 0; i < filters.length; i++) {
        var t = filters[i];
        if (t.objectId) {
          t.inputObjectId = this.objectId;
        }
      }
      if (!inputFilters) {
        this.$dialog
          .open(filterDialog, {
            filters: filters,
            reportName: this.reportQuery.code,
            sql: this.reportQuery.sql,
          })
          .then(({ filters }) => {
            this.loading = true;
            ReportUtil.print(
              this.reportQuery.reportSchema.reportJson,
              [
                {
                  name: "ObjectId",
                  value: this.objectId,
                },
                ...filters,
              ],
              () => (this.loading = false)
            );
          });
      } else {
        this.loading = true;
        ReportUtil.print(
          this.reportQuery.reportSchema.reportJson,
          [
            {
              name: "ObjectId",
              value: this.objectId,
            },
            ...inputFilters,
          ],
          () => (this.loading = false)
        );
      }
    },
    async print(inputFilters) {
      let encyptQueryModel =
        this.$ux.v.VueInstance.$store.state.app.printQuery[
          `PRINT-${this.code}`
        ];
      if (!this.reportQuery && encyptQueryModel) {
        this.reportQuery = JSON.parse(encyptQueryModel);
      } else {
        try {
          this.loading = true;
          var res = await infoRpQuery(null, this.code);
          this.reportQuery = res;
          this.$ux.v.VueInstance.$store.dispatch("app/setPrintQuery", {
            printCode: `PRINT-${this.code}`,
            infoPrintQuery: JSON.stringify(res),
          });
        } finally {
          this.loading = false;
        }
      }
      this.doPrint(inputFilters);
    },
  },
};
</script>

<style>
</style>
