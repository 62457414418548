<template>
  <n-dialog-layout ref="layoutDialog" class="layout-dialog-page" width="100%" :fullscreen="true" v-loading="loading">
    <div slot="title">{{ l("LayoutSetting") }}</div>
    <!-- body -->
    <el-container :style="{ height: height + 'px' }">
      <el-aside width="200px">
        <table class="w100">
          <tr v-for="(f, index) in fields" :key="index">
            <td>{{ f.label }}</td>
            <td>
              <template v-if="!f.required">
                <el-button type="text" @click="changeFieldLayoutItem(f)">
                  <template v-if="f.visible">
                    <i class="iconfont icon-jianhao4"></i>
                  </template>
                  <template v-else>
                    <i class="iconfont icon-jiahao2"></i>
                  </template>
                </el-button>
              </template>
              <template v-else>
                <span class="label-required">*</span>
              </template>
            </td>
          </tr>
        </table>
      </el-aside>
      <el-main style="padding: 0;position: relative;overflow: hidden;">
        <!--橫向ruler-->
        <div class="h-ruler">
          <label>0</label>
          <label>100</label>
          <label>200</label>
          <label>300</label>
          <label>400</label>
          <label>500</label>
          <label>600</label>
          <label>700</label>
          <label>800</label>
          <label>900</label>
          <label>1000</label>
          <label>1100</label>
          <label>1200</label>
          <label>1300</label>
          <label>1400</label>
          <label>1500</label>
          <label>1600</label>
          <label>1700</label>
          <label>1800</label>
          <label>1900</label>
          <label>2000</label>
        </div>
        <!--豎向ruler-->
        <div class="h-ruler v-ruler">
          <label>0</label>
          <label>100</label>
          <label>200</label>
          <label>300</label>
          <label>400</label>
          <label>500</label>
          <label>600</label>
          <label>700</label>
          <label>800</label>
          <label>900</label>
          <label>1000</label>
          <label>1100</label>
          <label>1200</label>
          <label>1300</label>
          <label>1400</label>
          <label>1500</label>
          <label>1600</label>
          <label>1700</label>
          <label>1800</label>
          <label>1900</label>
          <label>2000</label>
        </div>
        <div style="padding-left:20px;padding-top:20px;height:calc(100%);width:calc(100% );">
          <component v-if="editComponent" :is="editComponent" ref="editCom"></component>
        </div>
      </el-main>
    </el-container>
    <!-- body -->
    <div slot="footer">
      <el-form :inline="true" :inline-message="true" size="small" class="footer-form" :model="layout" :rules="rules"
        ref="footerForm">
        <ux-form-item prop="layoutName" :label="l('LayoutName')" style="line-height:32px;">
          <el-input :placeholder="l('PleaseEnterLayoutName')" v-model="layout.layoutName"
            style="width: 300px !important"></el-input>
        </ux-form-item>
        <ux-form-item style="line-height: 40px" v-if="hasEditPermission">
          <ux-checkbox v-model="layout.isPublic">{{ l("Public") }}</ux-checkbox>
        </ux-form-item>
        <ux-form-item style="line-height: 40px">
          <ux-checkbox v-model="layout.isDefault">{{
            l("Default")
          }}</ux-checkbox>
        </ux-form-item>
        <el-button @click="cancel" size="small">{{ l("Cancel") }}</el-button>
        <el-button type="primary" @click="save" size="small">{{
          l("Save")
        }}</el-button>
      </el-form>
    </div>
  </n-dialog-layout>
</template>

<script>
import { saveSysDragLayout } from "@/api/uxretail/sys/sysDragLayout.js";
export default {
  name: "edit-layout-dialog",
  data() {
    return {
      loading: false,
      fields: [],
      editComponent: null,
      layout: {
        routerName: "",
        layoutName: "",
        layoutJson: "",
        isPublic: false,
        isDefault: false,
      },
      rules: {
        layoutName: [{ required: true }],
      },
      layoutJson: [],
      height: window.innerHeight - 55 - 63,
    };
  },
  computed: {
    hasEditPermission() {
      return true; //this.checkPermi("Pages.App.PublicDragLayout");
    },
  },
  methods: {
    changeFieldLayoutItem(field) {
      field.currentLayoutItem = {
        ...this.getLastLayoutItem(),
        w: field.currentLayoutItem.w,
        h: field.currentLayoutItem.h,
      };
      field.visible = !field.visible;
      if (field.visible) {
        field.active = true;
      }
    },
    open(data) {
      this.fields = [];
      this.editComponent = data.component;
      this.layout = data.layout;
      this.layout.routerName = data.routeName;
      if (this.layout.layoutJson) {
        this.layoutJson = JSON.parse(this.layout.layoutJson);
      }
      return this.$refs.layoutDialog.open();
    },
    save() {
      this.$refs.footerForm.validate(async (v) => {
        if (v) {
          try {
            let layout = this.fields.map((t) => {
              return {
                prop: t.currentProp,
                visible: t.visible,
                layoutItem: t.currentLayoutItem,
              };
            });
            this.layout.layoutJson = JSON.stringify(layout);
            this.loading = true;
            var res = await saveSysDragLayout(this.layout);
            this.$refs.layoutDialog.close(res);
          } finally {
            this.loading = false;
          }
        }
      });
      // var inputDto = this.fields.map(t => { return { prop: t.currentProp, visible: t.visible, layoutItem: t.currentLayoutItem } })
      // let layoutJson = JSON.stringify(inputDto)
      // localStorage.setItem("LAYOUT_" + this.routeName, layoutJson)
      // window.location.reload();
    },
    cancel() {
      this.$refs.layoutDialog.cancel();
    },
    getLastLayoutItem() {
      let item = {};
      let maxY = _.max(
        this.fields
          .filter((t) => t.visible)
          .map((t) => t.currentLayoutItem.y + t.currentLayoutItem.h)
      );
      item.y = maxY || 0;
      item.x = 0;
      item.w = 280;
      item.h = 70;
      item.tabIndex = this.fields.filter((t) => t.visible).length + 1;
      return item;
    },
    addField(field) {
      let existField = this.layoutJson.find((t) => t.prop == field.currentProp);
      if (existField) {
        field.visible = existField.visible;
        field.currentLayoutItem = existField.layoutItem;
      } else if (field.required) {
        field.currentLayoutItem = {
          ...this.getLastLayoutItem(),
          w: field.currentLayoutItem.w,
          h: field.currentLayoutItem.h,
        };
      }
      if (!field.currentLayoutItem.tabIndex) {
        field.currentLayoutItem.tabIndex = this.fields.length + 1;
      }
      this.fields.push(field);
    },
  },
  created() {
    this.eventBus.$on("editing.dragDialog.addField", this.addField);
  },
  beforeDestroy() {
    this.eventBus.$off("editing.dragDialog.addField", this.addField);
  },
};
</script>

<style lang="scss">
.layout-dialog-page {
  height: 100%;
  width: 100%;

  .el-aside {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .el-dialog {
    margin: 0px !important;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog__footer {
    padding: 10px;
  }

  .n-layout-main-class {
    background-size: 20px 20px;
    background-color: rgb(251, 251, 251);
    background-image: linear-gradient(
        90deg,
        rgba(220, 223, 230, 0.5) 10%,
        rgba(0, 0, 0, 0) 10%
      ),
      linear-gradient(rgba(220, 223, 230, 0.5) 10%, rgba(0, 0, 0, 0) 10%);
  }

  .footer-form {
    .ux-form-item {
      margin-bottom: 0px;
    }
  }

  .h-ruler {
    background-color: #ffffff;
    background-image: linear-gradient(
        90deg,
        rgba(73, 73, 73, 0.5) 0,
        rgba(73, 73, 73, 0.5) 2%,
        transparent 2%
      ),
      linear-gradient(180deg, #ffffff 50%, transparent 50%),
      linear-gradient(
        90deg,
        transparent 50%,
        rgba(73, 73, 73, 0.5) 50%,
        rgba(73, 73, 73, 0.5) 52%,
        transparent 52%
      ),
      linear-gradient(180deg, #ffffff 70%, transparent 70%),
      linear-gradient(
        90deg,
        transparent 10%,
        rgba(73, 73, 73, 0.4) 10%,
        rgba(73, 73, 73, 0.4) 12%,
        transparent 12%,
        transparent 20%,
        rgba(73, 73, 73, 0.4) 20%,
        rgba(73, 73, 73, 0.4) 22%,
        transparent 22%,
        transparent 30%,
        rgba(73, 73, 73, 0.4) 30%,
        rgba(73, 73, 73, 0.4) 32%,
        transparent 32%,
        transparent 40%,
        rgba(73, 73, 73, 0.4) 40%,
        rgba(73, 73, 73, 0.4) 42%,
        transparent 42%,
        transparent 60%,
        rgba(73, 73, 73, 0.4) 60%,
        rgba(73, 73, 73, 0.4) 62%,
        transparent 62%,
        transparent 70%,
        rgba(73, 73, 73, 0.4) 70%,
        rgba(73, 73, 73, 0.4) 72%,
        transparent 72%,
        transparent 80%,
        rgba(73, 73, 73, 0.4) 80%,
        rgba(73, 73, 73, 0.4) 82%,
        transparent 82%,
        transparent 90%,
        rgba(73, 73, 73, 0.4) 90%,
        rgba(73, 73, 73, 0.4) 92%,
        transparent 92%
      );
    background-size: 50px 20px;
    background-repeat: repeat-x;
    min-height: 20px;

    /* only needed for labels */
    white-space: nowrap;
    font-size: 0;
    margin: 0;
    padding: 0;
    label {
      font-size: 9px;
      padding-top: 2px;
      display: inline-block;
      width: 100px;
      text-indent: 3px;
    }
  }

  .v-ruler {
    position: absolute;
    z-index: 999;
    transform: rotateZ(90deg);
    left: -1040px;
    top: 1010px;
  }
}
</style>
