import VFrame from '@/framework/VFrame'
export default {
  start: {
    type: "start",
    properties: { title: VFrame.l('Sponsor'), approvers: [] }
  },
  approver: {
    type: "approver",
    properties: { title: VFrame.l('Approver'), approvers: [] }
  },
  copy: {
    type: 'copy',
    properties: {
      title: VFrame.l('CC'),
      menbers: [],
      userOptional: true
    }
  },
  condition: {
    type: "condition",
    properties: { title: VFrame.l('Condition'), conditions: [] }
  },
  branch: { type: "branch", properties: {} },
  empty: { type: "empty", properties: {} }
}