<template>
<el-button @click="handleClick" :disabled="disabled">
    {{text||l('Import')}}
</el-button>
</template>

<script>
import dialog from './dialog.vue'
export default {
    name: "ImportButton",
    props: {
        text: {},
        disabled: {
            default: false
        },
        code: {},
        id: {}
    },
    methods: {
        handleClick() {
            this.$dialog.open(dialog, {
                code,
                id
            })
        }
    }
}
</script>

<style>

</style>
