
import Cookies from "js-cookie";
import CONSTANT from "@/CONSTANT.js";

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localeEn from 'element-ui/lib/locale/lang/en'
import localeTw from 'element-ui/lib/locale/lang/zh-TW'
import localeZh from 'element-ui/lib/locale/lang/zh-CN'

import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import zhTW from 'vxe-table/lib/locale/lang/zh-TW'

Vue.use(VueI18n)

const messages = {
  'zh-CN': {
    ...zhCN,
    ...localeZh
  },
  'en-GB': {
    ...enUS,
    ...localeEn
  },
  'zh-HK': {
    ...zhTW,
    ...localeTw
  }
}

var currentLanguage = Cookies.get(CONSTANT.UX_LANGUAGE) || "en-GB";

const i18n =new VueI18n({
  locale: currentLanguage,
  messages,
})
 


export default i18n