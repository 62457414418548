import VXETable from 'vxe-table'
import VFrame from '@/framework/VFrame.js'

VXETable.renderer.add('tag-primary', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [
            <el-tag type="primary">{row[column.property]}</el-tag>
        ]
    }
})
VXETable.renderer.add('tag-danger', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [
            <el-tag type="danger">{row[column.property]}</el-tag>
        ]
    }
})
VXETable.renderer.add('tag-normal', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        console.log(renderOpts);
        let { row, column } = params
        return [
            <el-tag type="normal">{row[column.property]}</el-tag>
        ]
    }
})

VXETable.renderer.add('qv-checkbox', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [
            <el-checkbox disabled v-model={row[column.property]}></el-checkbox>
        ]
    }
})



VXETable.renderer.add('qv-orgtype-tag', {
    autofocus: '.el-checkbox__original',
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        let v = _.get(row, column.property);
        let text = ""
        switch (v) {
            case 0: text = "RootNode"; break;
            case 1: text = "Company"; break;
            case 2: text = "BranchCompany"; break;
            case 3: text = "Franchisees"; break;
            case 4: text = "StatisticsNode"; break;
            case 5: text = "Warehouse"; break;
            case 6: text = "Shop"; break;
            case 11: text = "Supplier"; break;
            case 12: text = "Customer"; break;

        }
        return [
            <el-tag>{VFrame.l(text)}</el-tag>
        ]
    }
})

VXETable.renderer.add('qv-businessType', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        let v = _.get(row, column.property);
        let text = ""
        switch (v) {
            case 1: text = "Other"; break;
            case 2: text = "Newlyadded"; break;
            case 3: text = "Modify"; break;
            case 4: text = "List"; break;
            case 5: text = "Page"; break;
            case 6: text = "Info"; break;
            case 7: text = "Save"; break;
            case 8: text = "Deleted"; break;
            case 9: text = "Authorization"; break;
            case 10: text = "Exprot"; break;
            case 11: text = "Forcedretreat"; break;
            case 12: text = "GenerateCode"; break;
            case 13: text = "ClearData"; break;
            default:text = "Other"; break;
        }
        return [
            <span>{VFrame.l(text)}</span>
        ]
    }
})


VXETable.renderer.add('qv-link', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [
            <router-link to={'/dict/type/data/' + row.dictType} class="link-type">
                <span>{row[column.property]}</span>
            </router-link>
        ]
    }
})

VXETable.renderer.add('approval-status', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [<el-tag type="normal">{this.l(row.status)}</el-tag>]
    }
})




VXETable.renderer.add('tag-status', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        if (row.status == 0) {
            return [
                <el-tag type="normal">{this.l('Normal')}</el-tag>
            ]
        } else {
            return [
                <el-tag type="danger">{this.l('Deactivate')}</el-tag>
            ]
        }
    }
})

VXETable.renderer.add('tag-status-log', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        if (row.status == 0) {
            return [
                <el-tag type="normal">{this.l('Success')}</el-tag>
            ]
        } else {
            return [
                <el-tag type="danger">{this.l('Failure')}</el-tag>
            ]
        }
    }
})

VXETable.renderer.add('qv-select-dict', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        return [
            <ux-select-dict dictType="sys_oper_type" v-model={row.businessType} disabled={true}></ux-select-dict>
        ]

    }
})

VXETable.renderer.add('tag-status-notice', {
    renderDefault(h, renderOpts, params) {
        const { row, column } = params
        const arr = [
            { type: 'danger', name: this.l('Unread') },
            { type: 'normal', name: this.l('Read') }
        ]
        const state = arr[row.state]
        const type = state ? state.type : 'normal'
        const name = state ? state.name : this.l('Read')
        return [
            <el-tag type={type}>{name}</el-tag>
        ]
    }
})

VXETable.renderer.add('tag-status-notice-color', {
    renderDefault(h, renderOpts, params) {
        const { row, column } = params
        const { severity } = row
        const r = 255
        const g = 200 - (severity * 15) < 0 ? 0 : 200 - (severity * 15)
        const b = 200 - (severity * 15) < 0 ? 0 : 200 - (severity * 15)
        const bg = `rgb(${r},${g}, ${b})`
        return [
            <el-tag style={{ background: bg, color: '#fff', borderColor: bg }}>{severity}</el-tag>
        ]
    }
})

VXETable.renderer.add('charge-unit-tag', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params
        let v = _.get(row, column.property);
        let text = ""
        switch (v) {
            case 0: text = "FixAmount"; break;
            case 1: text = "%OfSalesAmount"; break;
            case 2: text = "FixAmountQty"; break;

        }
        return [
            <span>{VFrame.l(text)}</span>
        ]
    }
})