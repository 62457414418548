<template>
  <treeselect name="organization-select"
    :autoDeselectDescendants="autoSelect"
    :autoSelectDescendants="autoSelect"
    :flat="flat"
    :defaultExpandLevel="alwaysOpen?Infinity:0"
    :multiple="multiple" :clearable="clearable"
    :searchable="searchable" :disabled="disabled"
    :clear-on-select="true"
    :close-on-select="true"
    :always-open="alwaysOpen"
    :placeholder="l('Select')" :options="disabledOptions"
    :limit="3" v-model="currentValue"
    :max-height="maxHeight"
    :appendToBody="!alwaysOpen"
    :zIndex="alwaysOpen?999:9999"
    :loadingText="l('Loading')">
    <label slot="option-label"
      slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
      :class="labelClassName">
      {{ node.label }}
      <el-button v-if="alwaysOpen&&showButton"
        type="text" icon="el-icon-edit"
        class="fr mr-5"
        @click="$emit('select',node.raw.source)">
      </el-button>
      <el-tag size="mini">{{node.raw.source.code}}
      </el-tag>
      <el-tag
        v-show="node.raw.source.organizationType < 7"
        size="mini">
        {{filterOrgType(node.raw.source)}}
      </el-tag>
    </label>
  </treeselect>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import treeSelect from "@riophae/vue-treeselect";
import request from "@/utils/request.js";
export default {
  name: "organization-select",
  components: {
    treeselect: treeSelect,
  },
  props: {
    flat: {
      default: false, //平板模式 互相不关联
    },
    autoSelect: {
      //当用户取消选择节点时，将自动取消选择其祖先。仅适用于平面模式。
      //当用户取消选择节点时，将自动取消选择其后代。仅适用于平面模式。
      //当用户选择节点时，将自动选择其祖先。仅适用于平面模式。
      //当用户选择一个节点时，将自动选择其后代。仅适用于平面模式。
      default: false,
    },
    alwaysOpen: {
      default: false,
    },
    showButton: {
      default: true,
    },
    multiple: {
      default: false,
    },
    searchable: {
      default: true,
    },
    disabled: {
      default: false,
    },
    clearable: {
      default: true,
    },
    maxHeight: {
      default: 700,
    },
    value: {},
    disabledNodesByTreecode: { default: "" },
  },
  data() {
    var index = this.$store.state.user.languageIndex;
    return {
      currentValue: this.value,
      options: [],
      languageIndex: index <= 0 ? "" : index,
      orgType: [],
    };
  },
  watch: {
    currentValue() {
      this.$emit("input", this.currentValue);
    },
    value() {
      this.currentValue = this.value;
    },
  },
  computed: {
    disabledOptions() {
      if (this.disabledNodesByTreecode) {
        let _opt = this.copy(this.options);
        return this.deepDisableNodes(_opt);
      } else {
        return this.options;
      }
    },
  },
  methods: {
    deepDisableNodes(_opt) {
      for (var i = 0; i < _opt.length; i++) {
        if (_opt[i].treeCode) {
          if (_opt[i].treeCode.indexOf(this.disabledNodesByTreecode) == 0) {
            _opt[i].isDisabled = true;
          }
        }
        if (_opt[i].children) {
          this.deepDisableNodes(_opt[i].children);
        }
      }
      return _opt;
    },
    refresh(id, node, callback) {
      request({
        url: "/uxretail/DocOrganization/treeList",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (!id) {
          this.options = this.genOptions(res.items, null);
          // 屏蔽 Root Node之外的树
          this.options.map((item, index) => {
            if (item.id != 10001) {
              this.options.splice(index, 1);
            }
          });
        }
        if (callback) {
          callback();
        }
      });
    },
    genOptions(dtoList, pId) {
      var options = [];
      var currentList = dtoList.filter((t) => t.parentId == pId);

      if (!currentList) {
        return null;
      }
      for (var i = 0; i < currentList.length; i++) {
        var dto = currentList[i];
        var opt = {
          id: dto.id,
          label: this.getLabel(dto),
          source: dto,
          treeCode: dto.treeCode,
          isBranch: dto.hasChildren,
        };
        if (dto.hasChildren) {
          if (dto.id == 10002) {
            console.log(currentList);
          }
          opt.children = this.genOptions(dtoList, dto.id);
        }
        options.push(opt);
      }
      return options;
    },
    filterOrgType(item) {
      if (item) {
        let typeName = this.orgType.filter(
          (t) => Number(t.dictValue) === item.organizationType
        );
        if (typeName.length > 0) {
          return typeName[0].dictLabel;
        }
      }
    },
    getLabel(dto) {
      return dto["name" + this.languageIndex] || dto["name"];
    },
    getOrgType() {
      this.getDicts(this.CONSTANT.ORGANIZATION_TYPE).then((response) => {
        this.orgType = response.data;
      });
    },
  },
  created() {
    this.getOrgType();
    this.refresh();
  },
};
</script>

<style lang="scss">
.vue-treeselect__label {
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
}

.vue-treeselect--single .vue-treeselect__input {
  height: auto;
}
</style>
