/**
 * Created by M on 2018/10/24.
 */

import store from '@/store'
import VFrame from "@/framework/VFrame";
import { Loading } from 'element-ui';
class ReportUtil {

  loadScript() {
    return new Promise(function (resolve, reject) {
      $.when(
        $.ajax({
          url: "/static/stimulsoft/stimulsoft.reports.engine.pack.js", dataType: "script",
          cache: true
        }),
        $.ajax({
          url: "/static/stimulsoft/stimulsoft.reports.export.pack.js", dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.reports.chart.pack.js', dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.reports.maps.pack.js', dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.reports.import.xlsx.pack.js', dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.viewer.pack.js', dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.designer.pack.js', dataType: "script",
          cache: true
        }),
        $.ajax({
          url: '/static/stimulsoft/stimulsoft.blockly.editor.pack.js', dataType: "script",
          cache: true
        })
      ).done(function () {
        resolve();
      }).fail(function (jqxhr, settings, exception) {
        reject(exception);
      });
    });
  }
  async initConfig() {
    if (!window.Stimulsoft) {
      let busy = Loading.service({ text: VFrame.l("InstallReportModule"), fullscreen: true });
      try {
        await this.loadScript()
      } catch (e) {
        VFrame.VueInstance.$message.error(VFrame.l('InstallREportModuleError'))
      } finally {
        busy.close()
      }
    }
    Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHml1BjP5EJc54uzSftIccF8JhifWY6WNUyPa9XBkMhkS7gKKp" +
      "c8lyOyPui8sMvwhn24oy/HoJkYcRkTt2RNRoGBJ+h3OPZNoY39zwUZ8dL2/CLe8dtlOK0VIW/rDggBDu65hzlSrXL0" +
      "lE8JowD0T5BM4N6AXNCyaXIOWEdCOi8LtQqXGFDl3jzvmBHvd60RJQiQW1HPucM4L90aALgil+oXzjLDTcuKBf5ins" +
      "uNxGy+u3AgjBkMWLgekRbkpMi87IhsU56IdZ2NbRrgSRod/2yR/z3ARkHRRXpwUB+W8lYBnutcCF/BLSf4VvRGqHKo" +
      "W81sAZIya1TX0db4fn+JhIoedW0W6Jr6pS60iETeg9zjFhgUPWK0f8VGBa/DBqpLhczTDVsdxFndSgcXXPSzfvJm8P" +
      "LcrwRy6ovikXwpTo15PrM5oGqLwfdUbXLJMqwAQzwTR5bNie4dc1XEJ5bODiu4unWJOs6OJfL5CznPY/CvCjXmC43K" +
      "XyhmCxh0rzMD6o+AutQarfHWZecCkd/9XXTW";
    Stimulsoft.StiOptions.WebServer.url = process.env.VUE_APP_BASE_API + "/report"
    Stimulsoft.StiOptions.WebServer.encryptData = false;
  }

  getExploreName() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      return 'Opera';
    }
    else if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1) {
      return 'IE';
    }
    else if (userAgent.indexOf("Edge") > -1) {
      return 'Edge';
    }
    else if (userAgent.indexOf("Firefox") > -1) {
      return 'Firefox';
    }
    else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1) {
      return 'Safari';
    }
    else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1) {
      return 'Chrome';
    }
    else if (!!window.ActiveXObject || "ActiveXObject" in window) {
      return 'IE>=11';
    }
    else {
      return 'Unkonwn';
    }
  }

  getVariables() {
    var shopOrgId = VFrame.applicationResource.storeSetting ? VFrame.applicationResource.storeSetting["Store.ShopOrgId"] : null
    return [
      { name: "UserId", value: store.getters.userId },
      { name: "Language", value: store.getters.language },
      { name: "LanguageIndex", value: store.getters.languageIndex == 0 ? '' : store.getters.languageIndex },
      {
        name: "TimezoneOffset", value: new Date().getTimezoneOffset() / -60
      },
      { name: "ShopOrgId", value: shopOrgId },
      { name: "UserAgent", value: this.getExploreName() }
    ]
  }

  async multipleDataSourceReport(reportJson, reportInstance, dataSources, inputVariables) {
    await this.initConfig()
    var stiReport = new Stimulsoft.Report.StiReport();
    if (reportJson) {
      stiReport.load(reportJson);
    } else if (reportInstance) {
      stiReport = reportInstance;
    }
    var variables = this.getVariables();
    if (variables) {
      for (var i = 0; i < variables.length; i++) {
        this.setVariable(variables[i].name, variables[i].value, stiReport, false)
      }
      for (var i = 0; i < inputVariables.length; i++) {
        this.setVariable(inputVariables[i].name, inputVariables[i].value, stiReport, true)
      }
    }
    if (dataSources) {
      for (var i = 0; i < dataSources.length; i++) {
        var existDb = stiReport.dictionary.databases.getByName(dataSources[i].code);
        var db = existDb;
        let connectionStr = `jdbc.url=${dataSources[i].connectionStr};jdbc.username=${dataSources[i].dbUser};jdbc.password=${dataSources[i].dbPwd}`;
        if (dataSources[i].connectionType == 'oracle.jdbc.driver.OracleDriver') {
          db = new Stimulsoft.Report.Dictionary.StiOracleDatabase(dataSources[i].code,
            dataSources[i].note,
            connectionStr, false, null);
        } else if (dataSources[i].connectionType == 'com.mysql.jdbc.Driver') {
          db = new Stimulsoft.Report.Dictionary.StiMySqlDatabase(dataSources[i].code,
            dataSources[i].note,
            connectionStr, false, null);
        } else if (dataSources[i].connectionType == 'com.microsoft.sqlserver.jdbc.SQLServerDriver') {
          db = new Stimulsoft.Report.Dictionary.StiSqlDatabase(dataSources[i].code,
            dataSources[i].note,
            connectionStr, false, null);
        }
        if (!existDb) {
          stiReport.dictionary.databases.add(db);
        } else {
          stiReport.dictionary.databases.setByName(dataSources[i].code, db);
        }
      }
    }
    stiReport.dictionary.synchronize();
    return stiReport
  }

  /*
   @report:报表json字符串
   @data:数据json对象
   */
  async getReport(report, notSync) {
    await this.initConfig()
    var stiReport = new Stimulsoft.Report.StiReport();
    if (report) {
      stiReport.load(report);
    }
    var variables = this.getVariables();
    if (variables) {
      for (var i = 0; i < variables.length; i++) {
        this.setVariable(variables[i].name, variables[i].value, stiReport)
      }
    }
    if (!notSync) {
      stiReport.dictionary.synchronize();
    }
    return stiReport;
  }
  /*
   @{stiReport,report,data}
 
   @@stiReport:报表对象， 可以通过reportViewer或reportDesigner获取
   互斥
   @@report:报表json字符串
   @@data:数据json对象
   */
  async print(report, params, callback) {
    var sReport =await this.getReport(report, true);
    if (params) {
      for (var i = 0; i < params.length; i++) {
        this.setVariable(params[i].name, params[i].value, sReport, false)
      }
    }
    sReport.dictionary.synchronize();
    sReport.renderAsync(function () {
      sReport.print();
      if (callback) callback()
    });
  }

  setVariable(name, value, stiReport, requestFromUser) {
    var variables = new Stimulsoft.Report.Dictionary.StiVariable();
    variables.category = requestFromUser ? "inputs" : "ux";
    variables.name = name;
    variables.alias = name;
    variables.requestFromUser = false;
    variables.value = value;

    if (stiReport.dictionary.variables[name]) {
      stiReport.dictionary.variables[name] = variables;
    } else {
      stiReport.dictionary.variables.add(variables);
    }
  }
}

export default new ReportUtil()
