<template>
  <el-date-picker :clearable="true" style="width:100%" v-model="currentValue" type="year">
  </el-date-picker>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: {},
  },
  data() {
    var v = null;
    if (this.value) {
      v = moment(this.value, "YYYY");
    }
    return {
      currentValue: v,
    };
  },
  watch: {
    value() {
      var v = null;
      if (this.value) {
        v = moment(this.value, "YYYY");
      }
      this.currentValue = v;
    },
    currentValue() {
      this.$emit("input", moment(this.currentValue).year());
    },
  },
};
</script>

<style>
</style>