import {
    infoSysQueryBuilder,
    dynamicQueryList,
    dynamicQueryPage,
    dynamicQueryOne
} from "@/api/uxretail/sys/sysQueryBuilder.js";

//todo:将QueryView的请求替换为Adapter
class QueryAdapter {
    queryModel = null;
    page = {
        pageNum: 1,
        pageSize: 10,
    };
    sql = "";
    total = 0;

    getSql (params) {
        var sql = this.queryModel.querySQL;
        this.queryModel.sys_params.forEach((t) => {
            sql = sql.replaceAll(
                "{" + t.paramName + "}",
                params[t.paramName] || t.defaultValue
            );
        });

        return sql;
    }

    async fetchSchema (code) {
        if (code) {
            var encyptQueryModel = sessionStorage.getItem(code);
            if (encyptQueryModel) {
                this.queryModel = JSON.parse(encyptQueryModel);

                // if (!this.lazyFetch) this.refresh();
            } else {
                this.loading = true;
                try {
                    var res = await infoSysQueryBuilder(null, code.replace("QUERY-", ""));
                    this.queryModel = JSON.parse(res.configJson);
                    sessionStorage.setItem(code, res.configJson);
                } catch {
                    console.error("fetch schema error");
                }
            }
        } else {
            console.log("query-view code required");
        }
    }

    async excute (code, sys_params, page) {
        await this.fetchSchema(code);
        var res = await dynamicQueryPage(
            {
                queryCode: code,
                dataScopeActive: this.queryModel.dataScope ? this.queryModel.dataScope.active : false,
                orgAlias: this.queryModel.dataScope ? this.queryModel.dataScope.orgAlias : "",
                userAlias: this.queryModel.dataScope ? this.queryModel.dataScope.userAlias : "",
                sql: this.getSql(sys_params),
                params: [],
            },
            page || this.page
        )
        this.list = res.items;
        this.total = res.totalCount;

        return {
            list: this.list,
            total: this.total
        }
    }


    async excuteAll (code, sys_params) {
        await this.fetchSchema(code);
        var res = await dynamicQueryList(
            {
                queryCode: code,
                dataScopeActive: this.queryModel.dataScope ? this.queryModel.dataScope.active : false,
                orgAlias: this.queryModel.dataScope ? this.queryModel.dataScope.orgAlias : "",
                userAlias: this.queryModel.dataScope ? this.queryModel.dataScope.userAlias : "",
                sql: this.getSql(sys_params),
                params: [],
            }
        )
        this.list = res.items;

        return {
            list: this.list
        }
    }

    async excuteOne (code, sys_params) {
        await this.fetchSchema(code);
        var res = await dynamicQueryOne(
            {
                queryCode: code,
                dataScopeActive: this.queryModel.dataScope ? this.queryModel.dataScope.active : false,
                orgAlias: this.queryModel.dataScope ? this.queryModel.dataScope.orgAlias : "",
                userAlias: this.queryModel.dataScope ? this.queryModel.dataScope.userAlias : "",
                sql: this.getSql(sys_params),
                params: [],
            }
        )
        this.result = res

        return this.result
    }
}

export default new QueryAdapter()