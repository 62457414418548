import request from "@/utils/request";

export function saveUserConfig(data) {
  return request({
    url: "/uxretail/SysUserConfig/set",
    method: "post",
    data: data
  });
}


export function getUserConfig(data) {
  return request({
    url: "/uxretail/SysUserConfig/get",
    method: "post",
    data: data
  });
}
