import request from '@/utils/request'
export default {
    props: {
        "codeType": {},
        item: {},
    },
    data() {
        return {
            autoGen: true,
            loading: false,
            autoGenCodeEvent: "input"
        }
    },
    created() {
        var autoGen = null;
        if (Array.isArray(this.$ux.v.applicationResource.autoCodeGenMap)) {
            autoGen = this.$ux.v.applicationResource.autoCodeGenMap.find(t => t.type == this.codeType)
        }
        if (this.item && autoGen) {
            var config = autoGen.config
            config = config.replaceAll("#id", "UX_CODE_ID");
            config = config.replaceAll('#', 'item.')
            this.autoGenFunction = new Function("item", "UX_CODE_ID", "return " + config)
            if (!this.item.id) {
                this.fetchNewId();
            }
        } else {
            this.autoGen = false
        }
    },
    methods: {
        genCode() {
            var code = this.autoGenFunction(this.item, this.tempId)
            this.$emit(this.autoGenCodeEvent, code)
        },
        fetchNewId() {
            this.loading = true;
            request({
                url: '/system/SysIdentityConfig/getIdentity',
                method: 'post',
                data: { type: this.codeType }
            }).then(res => {
                this.tempId = res
                this.genCode()
            }).finally(() => this.loading = false)
        }
    },
    watch: {
        item: {
            handler: function (val, oldVal) {
                if (this.autoGen) {

                    if (this.item && !this.item.id) {
                        if (val != oldVal) {
                            //新创建判断
                            this.autoGen = true
                            this.fetchNewId();
                        } else {
                            this.genCode()
                        }
                    }
                }
            },
            deep: true
        }
    }

}