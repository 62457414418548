import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";
import ParentView from "@/components/ParentView";
/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: resolve => require(["@/views/redirect"], resolve)
      }
    ]
  },
  {
    path: "/quotationRedirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/quotationRedirect", //quotationRedirect?id=xxx
        component: resolve => require(["@/views/pos/terminal/quotationRedirect.vue"], resolve)
      }
    ]
  },
  {
    path: "/register",
    component: resolve => require(["@/views/uxretail/member/register"], resolve),
    name: "Register",
  },
  {
    path: "/information",
    component: resolve => require(["@/views/uxretail/member/information"], resolve),
    name: "Information",
  },
  {
    path: "/forget",
    component: resolve => require(["@/views/uxretail/forget/index.vue"], resolve),
    name: "forget",
  },
  {
    path: "/resetPassword",
    component: resolve => require(["@/views/uxretail/resetPassword/index.vue"], resolve),
    name: "resetPassword",
  },

  {
    path: "/404",
    component: resolve => require(["@/views/error/404"], resolve),
    hidden: true
  },
  {
    path: "/401",
    component: resolve => require(["@/views/error/401"], resolve),
    hidden: true
  },
  // {
  //   path: "/",
  //   // redirect: "index",
  //   component: Layout,
  //   children: [
  //     // {
  //     //   path: "index",
  //     //   component: resolve => require(["@/views/index"], resolve),
  //     //   name: "Home",
  //     //   meta: { title: "Home", icon: "dashboard", noCache: true, affix: true }
  //     // },
  //     {
  //       path: "PosPrint",
  //       component: resolve => require(["@/views/pos/reprint/reprint.vue"], resolve),
  //       name: "PosPrint",
  //       hidden: true,
  //       meta: { title: "Print" }
  //     }
  //   ]
  // },
  {
    path: "/user",
    component: Layout,
    hidden: true,
    redirect: "noredirect",
    children: [
      {
        path: "profile",
        component: resolve =>
          require(["@/views/system/user/profile/index"], resolve),
        name: "Profile",
        meta: { title: "PersonalCenter", icon: "user" }
      }
    ]
  },
  {
    path: "/dict",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "type/data/:dictType",
        component: resolve => require(["@/views/system/dict/data"], resolve),
        name: "Data",
        meta: { title: "DataEntry", icon: "" }
      }
    ]
  },
  {
    path: "/job",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "log",
        component: resolve => require(["@/views/monitor/job/log"], resolve),
        name: "JobLog",
        meta: { title: "OperationLog" }
      }
    ]
  },
  {
    path: "/gen",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "edit/:tableId(\\d+)",
        component: resolve => require(["@/views/tool/gen/editTable"], resolve),
        name: "GenEdit",
        meta: { title: "ModifyingBuildConfiguration" }
      }
    ]
  },
  {
    path: "/language",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/LanguageTexts",
        name: "LanguageTexts",
        component: resolve => require(["@/views/uxretail/sys/language/languageTexts.vue"], resolve),
        hidden: false,
        meta: { title: "LanguageTexts" }
      }
    ]
  },
  // {
  //   path: "/Reports",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "Query/:id",
  //       name: "ReportQuery",
  //       component: resolve => require(["@/views/uxreport/view/index.vue"], resolve),
  //       hidden: false,
  //       meta: { title: "ReportQuery", noCache: false }
  //     }
  //   ]
  // },
  {
    path: "/VipGradeSetting",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/vipGradeRuleDetail",
        name: "vipGradeRuleDetail",
        component: resolve => require(['@/views/uxretail/vip/vipGradeSetting/vipGradeRuleDetail.vue'], resolve),
        hidden: false,
        meta: { title: "vipGradeRuleDetail" }
      }
    ]
  },
  {
    path: "/ItemQualifier",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/itemQualifier",
        name: "itemQualifier",
        component: resolve => require(['@/views/uxretail/promotion/itemQualifier/index.vue'], resolve),
        hidden: false,
        meta: { title: "ItemQualifier" }
      }
    ]
  },
  {
    path: '/loadNotification',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/loadNotification',
        name: 'loadNotification',
        component: resolve => require(['@/views/loadNotification/index.vue'], resolve),
        hidden: false,
        meta: { title: 'loadNotification' }
      }
    ]
  },
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});
