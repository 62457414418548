export default {
    methods: {
        //data在 0,0开始
        exportDataHeaders(worksheet, fields, cellRange) {
            var fs = this.getFields(fields, 'data', r => r.caption);
            fs.forEach((field, index) => {
                var cell = worksheet.getRow(1).getCell(index + 1);
                cell.value = field
                this.applyStyles(cell);
            })
        },
        //row是 3,0 开始
        exportRowHeaders(worksheet, fields, cellRange) {
            var fs = this.getFields(fields, "row", (r) => r.caption);
            fs.forEach((field, index) => {
                var cell = worksheet.getRow(3).getCell(index + 1);
                cell.value = field
                this.applyStyles(cell);
            })
        },

        //column需要计算 col，row在2
        exportColumnHeaders(worksheet, fields, cellRange) {
            var fs = this.getFields(fields, 'column', r => r.caption);
            console.log(fs)
            fs.forEach((field, index) => {
                var cell = worksheet.getRow(2).getCell(worksheet.views[0].xSplit + 1 + index);
                cell.value = field
                this.applyStyles(cell);
            })
        },

        applyStyles(cell) {
            cell.alignment = { horizontal: "left", vertical: "top", wrapText: true };
            cell.font = { bold: true, color: { argb: "3F3F3F" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "F2F2F2" },
            };
            var borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            cell.border = {
                bottom: borderStyle,
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
            };
        },

        getFields(fields, area, mapper) { 
            var fs = _.sortBy(fields.filter(r => r.area === area),t=>t.areaIndex) 
            if (area != 'data' && fs.length > 1) {
                var tfs = fs.filter(x => x.expanded)
                if (tfs.length == 0) {
                    fs = [fs[0]]
                }
            }
            return [...new Set(fs.map(mapper))];
        }
    }
}