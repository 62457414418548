import { _ } from 'core-js';
import VXETable from 'vxe-table'
import UxtQueryInput from './editor/uxtQueryInput.vue'
import UxtSelect from "./editor/uxSelect.vue"
import UxtSelectDict from "./editor/uxSelectDict.vue"
import UxtSelectEnum from "./editor/uxSelectEnum.vue"
import UxtDetailExtract from "./editor/uxtDetailExtract.vue"
import UxtQuerySelect from "./editor/uxtQuerySelect.vue"

var propertyConvert = (row, property) => {
    var propertyArray = property.split('.')
    var p;
    for (var i = 0; i < p.length; i++) {
        p = row[p[i]]
    }
    return p
}


VXETable.renderer.add('uxt-input', {
    // 默认显示模板
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        let disabled;
        if (props.disabled) {
            if (typeof props.disabled == 'function') {
                disabled = props.disabled(row)
            } else {
                disabled = props.disabled
            }
        }

        return [
            <el-input class="uxt-input" disabled={disabled} value={_.get(row, column.property)} on-input={e => _.set(row, column.property, e)} ></el-input>
        ]
    }
})

VXETable.renderer.add('uxt-number', {
    autofocus: 'input.dx-texteditor-input',
    // 默认显示模板
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        let disabled;
        if (props.disabled) {
            if (typeof props.disabled == 'function') {
                disabled = props.disabled(row)
            } else {
                disabled = props.disabled
            }
        }

        return [
            <ux-number-input class="uxt-input" type={props.type} selectAll={true} max={props.max} min={props.min} disabled={disabled} precision={props.precision} value={_.get(row, column.property)} on-input={e => _.set(row, column.property, e)} autofocus={true}></ux-number-input>
        ]
    }
})


VXETable.renderer.add('uxt-detail-qty', {
    autofocus: '.dx-texteditor-input',
    // 默认显示模板
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        var disabled = false;
        let max = Infinity
        if (props.limitByExtract) {
            var maxQty = _.get(row, props.maxQtyField);
            if (maxQty == 0) {
                disabled = true;
            } else {
                max = maxQty < 0 ? 99999999999 : maxQty
            }
        }
        return [
            <ux-number-input selectAll={true} class="uxt-input" disabled={disabled} max={max} min={props.min}
                precision={props.precision} value={_.get(row, column.property)}
                on-input={e => _.set(row, column.property, e)} autofocus={true} ></ux-number-input>
        ]
    }
})

// 抽单数量 是否多收 uxt-detail-extract
VXETable.renderer.add('uxt-detail-extract', {
    autofocus: '.dx-texteditor-input',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        var disabled = false;
        let max = Infinity;
        let extractOrderName = props.extractOrderName;
        let byExtractOrderName = props.byExtractOrderName;
        if (props.limitByExtract) {
            var maxQty = _.get(row, props.maxQtyField);
            if (maxQty == 0) {
                disabled = true;
            } else {
                max = maxQty < 0 ? 99999999999 : maxQty
            }
        }
        return [
            <UxtDetailExtract selectAll={true} class="uxt-input" disabled={disabled} max={max} maxQty={maxQty} extractOrderName={extractOrderName} byExtractOrderName={byExtractOrderName} precision={props.precision}
                value={_.get(row, column.property)} setV={e => { _.set(row, column.property, e) }} autofocus={true}></UxtDetailExtract>
        ]
    }
})

VXETable.renderer.add('uxt-checkbox', {
    autofocus: '.el-checkbox__original',
    // 默认显示模板
    renderEdit(h, renderOpts, params) {
        let { row, column } = params
        return [
            <el-checkbox value={_.get(row, column.property)} on-input={e => _.set(row, column.property, e)} ></el-checkbox>
        ]
    }
})


VXETable.renderer.add('uxt-query-input', {
    // 默认显示模板
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        if (props.options.setV) {
            props.options.setV.row = row;
        }
        return [
            <UxtQueryInput params={params} options={props.options} events={props.events}  >
            </UxtQueryInput>
        ]
    }
})

VXETable.renderer.add('uxt-query-select',{
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        if (props.options.setV) {
            props.options.setV.row = row;
        }
        return [
            <UxtQuerySelect disabled={props.disabled} params={params} options={props.options} events={props.events}></UxtQuerySelect>
        ]
    }
})

VXETable.renderer.add('uxt-select-dict', {
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        return [
            <UxtSelectDict disabled={props.disabled} params={params} options={props.options} events={props.events}  ></UxtSelectDict>
        ]
    }
})

VXETable.renderer.add('uxt-select-enum', {
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        return [
            <UxtSelectEnum customDisableFilter={props.customDisableFilter} disabled={props.disabled} params={params} options={props.options} events={props.events}  ></UxtSelectEnum>
        ]
    }
})

VXETable.renderer.add('uxt-select', {
    autofocus: '.el-input__inner',
    renderEdit(h, renderOpts, params) {
        let props = renderOpts.props || {}
        let { row, column } = params
        if (props.dataSource) {
            let slotOptions = props.dataSource.map(t => (<el-option value={t.value} label={t.name}></el-option>))
            return [
                <el-select clearable={props.clearable} disabled={props.disabled} value={row[props.bindRowValueField]} on-change={(value) => {
                    row[props.bindRowValueField] = value
                    props.events.change(row, props.dataSource.find(t => t.value == value))
                }}>{slotOptions}</el-select>
            ]
        }
        return [
            <UxtSelect params={params} options={props.options} events={props.events} api={props.api} ></UxtSelect>
        ]
    }
})
