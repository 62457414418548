<template>
  <el-select
    v-model="currentValue"
    class="query-select has-prefix"
    :placeholder="l(placeholder)"
    :disabled="disabled"
    @change="handleChange"
    @visible-change="visibleChange"
    :clearable="clearable"
    filterable
    style="flex: 1"
    :multiple="multiple"
    :collapse-tags="true"
  >
    <slot></slot>
    <el-option
      v-for="item in list"
      :key="item[valuePath]"
      :label="item[_labelPath] || item[labelPath]"
      :value="item[valuePath]"
    >
    </el-option>
    <div slot="prefix">
      <el-button
        type="text"
        icon="el-icon-refresh"
        @click="refresh"
        :loading="loading"
      >
      </el-button>
    </div>
  </el-select>
</template>

<script>
import BaseQuery from "./BaseQuery.js";

export default {
  mixins: [BaseQuery],
  name: "ux-query-select",
  props: {
    disabled: false,
    value: {},
    text: {},
    labelPath: {
      default: "name",
    },
    valuePath: {
      default: "id",
    },
    multiple: {
      default: false,
    },
    multipleOption: {
      default: "array",
    },
    clearable: {
      default: true,
    },
    page: {
      default: null,
    },
    placeholder: {
      type: String,
      default: () => "PleaseSelect",
    },
    defaultSelect: {
      default: true,
    },
    isDefault: {
      default: false,
    },
    //注意BaseQuery中还有属性
  },
  data() {
    return {
      loading: false,
      currentValue: this.getValue(),
    };
  },
  computed: {
    _labelPath() {
      if (this.queryModel) {
        var label = this.queryModel.columns.find(
          (x) => x.columnName == this.labelPath
        );
        if (label && label.multipleKey) {
          return this.languageGroupColumn[label.multipleKey].columnName;
        }
      }
    },
  },
  watch: {
    value() {
      this.currentValue = this.getValue();
    },
  },
  methods: {
    //------实现abstract-----------
    afterColumnsRender(defaultSort) {
      this.defaultSort = defaultSort;
      this.refresh();
    },
    beforeRefresh(sql) {
      if (this.defaultSort && this.defaultSort.property) {
        sql += ` order by ${this.defaultSort.property} ${
          this.defaultSort.order || ""
        }`;
      }
      return sql;
    },
    afterRefresh() {
      if (!this.defaultSelect) {
        return;
      }
      //設置默認值
      var defaultOne = this.list.find(t=> t[this.valuePath]==this.value) || this.list.find((t) =>t.isDefault);
      if (defaultOne && this.isDefault ) {
        // this.value 有值是也需触发 change(特殊定义)
        this.$nextTick(() => {
          this.$emit("change", {
            row: this.multiple ? [defaultOne] : defaultOne,
            sourceValue: this.value,
            value: this.multiple
              ? [defaultOne[this.valuePath]]
              : defaultOne[this.valuePath],
            label: defaultOne[this._labelPath] || defaultOne[this.labelPath],
          });
        });
      }
      if (defaultOne && !this.value && this.value !== "0") {
        console.debug("default:" + JSON.stringify(defaultOne));
        this.$nextTick(() => {
          this.emitChange(
            defaultOne[this._labelPath] || defaultOne[this.labelPath],
            this.multiple
              ? [defaultOne[this.valuePath]]
              : defaultOne[this.valuePath],
            this.multiple ? [defaultOne] : defaultOne
          );
        });
      }
    },
    //------实现abstract end---------
    getValue() {
      if (this.value || this.value == 0) {
        if (this.multiple) {
          if (this.multipleOption == "array") {
            return this.value;
          } else {
            if (Array.isArray(this.value)) {
              return this.value;
            }
            return this.value
              ? this.value
                  .split(this.multipleOption)
                  .map((t) => this.tryNumber(t))
              : [];
          }
        } else {
          return this.value;
        }
      } else {
        if (!this.clearable) {
          this.afterRefresh();
        }
      }
      return this.multiple ? [] : null;
    },
    tryNumber(value) {
      if (
        value &&
        typeof value == "string" &&
        value.indexOf("0") == 0 &&
        value.length > 1
      ) {
        return value; //特殊情况 例如value=001
      }
      var v = parseInt(value);
      if (v == value) {
        return v;
      } else {
        return value;
      }
    },
    getCurrentValue() {
      if (this.multiple) {
        if (this.multipleOption == "array") {
          return this.currentValue;
        } else {
          return this.currentValue.join(this.multipleOption);
        }
      } else {
        return this.currentValue;
      }
    },
    emitChange(text, input, rows) {
      this.$emit("update:text", text);
      this.$emit("input", input);
      this.$emit("change", {
        row: rows,
        sourceValue: this.value,
        value: input,
        label: text,
      });
    },
    handleChange() {
      if (
        this.currentValue ||
        this.currentValue === 0 ||
        this.currentValue === "0"
      ) {
        if (this.multiple) {
          let rows = this.list.filter(
            (t) => this.currentValue.indexOf(t[this.valuePath]) > -1
          );
          let labels = rows
            .map((row) => row[this._labelPath] || row[this.labelPath])
            .join(this.multipleOption.replace("array", ","));
          this.emitChange(labels, this.getCurrentValue(), rows);
        } else {
          let row = this.list.find(
            (t) => t[this.valuePath] == this.currentValue
          );
          this.emitChange(
            row[this._labelPath] || row[this.labelPath],
            this.getCurrentValue(),
            row
          );
        }
      } else {
        this.emitChange("", this.getCurrentValue(), null);
      }
    },
    visibleChange(flag){
      this.$emit("visibleChange",flag)
    }
  },
};
</script>

<style lang="scss">
</style>
