<template>
  <div style="display:flex">
    <el-select v-model="currentValue" :size="size"
      :placeholder="l('PleaseSelect')"
      class="has-prefix" @change="handleChange"
      :disabled="disabled" style="flex:1"
      filterable :clearable="clearable"
      :multiple="multiple" :collapse-tags="true">
      <el-option v-for="item in options"
        :key="item.dictValue"
        :disabled="customDisableFilter(item)||disabledByStatus(item)"
        :label="(i==0?item.dictLabel:(i==1?item.dictLabel1:item.dictLabel2)||item.dictLabel)"
        :value="tryNumber(item.dictValue)">
      </el-option>
      <div slot="prefix">
        <el-button type="text"
          icon="el-icon-refresh"
          @click="refresh(true)"
          :loading="loading"></el-button>
      </div>
    </el-select>
    <el-button type="text"
      icon="el-icon-edit-outline"
      v-if="!!$router&&showEditer"
      @click="handleEdit"
      v-hasPermi="['system:dict:edit']">
    </el-button>
  </div>
</template>

<script>
import cookie from "js-cookie";
export default {
  name: "ux-select-dict",
  props: {
    number: { default: true },
    dictType: {},
    size: {},
    value: {},
    text: {},
    disabled: {
      default: false,
    },
    clearable: {
      default: false,
    },
    showEditer: {
      default: true,
    },
    customDisableFilter: {
      default: (dataItem) => false,
      type: Function,
    },
    multiple: {
      default: false,
    },
    multipleOption: {
      default: "array",
    }, //array|| splitChar like ,
    cache: {
      default: true,
    },
  },
  created() {
    //字典
    this.refresh(this.cache);
  },
  data() {
    return {
      languages: this.$ux.v.languages,
      i: null, //多语言下标
      loading: false,
      currentValue: this.getValue(),
      options: this.$store.state.app.dictDatas[this.dictType] || [],
    };
  },
  watch: {
    value() {
      this.currentValue = this.getValue();
    },
  },
  methods: {
    disabledByStatus(x) {
      return x.status == "1";
    },
    getValue() {
      if (this.value || this.value == 0) {
        if (this.multiple) {
          if (this.multipleOption == "array") {
            return this.value;
          } else {
            return this.value
              ? this.value
                  .split(this.multipleOption)
                  .map((t) => this.tryNumber(t))
              : [];
          }
        } else {
          return this.value;
        }
      } else {
        //空值查看默认值
        if (this.defaultOne && !this.clearable) {
          this.$nextTick(() => {
            this.emitChange(
              this.defaultOne.dictLabel,
              this.tryNumber(this.defaultOne.dictValue),
              this.defaultOne
            );
          });
        }
      }
      return this.multiple ? [] : null;
    },
    getCurrentValue() {
      if (this.multiple) {
        if (this.multipleOption == "array") {
          return this.currentValue;
        } else {
          return this.currentValue.join(this.multipleOption);
        }
      } else {
        return this.currentValue;
      }
    },
    handleEdit() {
      if (this.$router) {
        this.$router.push("/dict/type/data/" + this.dictType);
      }
    },
    tryNumber(value) {
      if (!this.number) {
        return value;
      }
      if (
        value &&
        typeof value == "string" &&
        value.indexOf("0") == 0 &&
        value.length > 1
      ) {
        return value; //特殊情况 例如value=001
      }
      var v = parseInt(value);
      if (v == value) {
        return v;
      } else {
        return value;
      }
    },
    emitChange(text, input, rows) {
      this.$emit("update:text", text);
      this.$emit("input", input);
      this.$emit("change", {
        row: rows,
        value: input,
        label: text,
      });
    },
    handleChange() {
      if (
        this.currentValue ||
        this.currentValue === 0 ||
        this.currentValue === "0"
      ) {
        if (this.multiple) {
          let rows = this.options.filter(
            (t) => this.currentValue.indexOf(t.dictValue) > -1
          );
          let labels = rows
            .map((row) => row.dictLabel)
            .join(this.multipleOption.replace("array", ","));

          this.emitChange(labels, this.getCurrentValue(), rows);
        } else {
          let row = this.options.find((t) => t.dictValue == this.currentValue);

          this.emitChange(row.dictLabel, this.getCurrentValue(), row);
        }
      } else {
        this.emitChange("", this.getCurrentValue(), null);
      }
    },
    refresh(reload) {
      this.loading = true;
      this.$store
        .dispatch("app/getDictData", {
          dictType: this.dictType,
          reload: reload,
        })
        .then(() => {
          this.loading = false;
          this.options = this.$store.state.app.dictDatas[this.dictType] || [];
          var defaultOne = this.options.find((t) => t.isDefault == "Y");
          if (defaultOne && !this.value && this.value !== "0") {
            this.defaultOne = defaultOne;
            this.$nextTick(() => {
              this.emitChange(
                defaultOne.dictLabel,
                this.tryNumber(defaultOne.dictValue),
                defaultOne
              );
            });
          }
        });

      this.i = this.languages.indexOf(cookie.get(this.CONSTANT.UX_LANGUAGE));
    },
  },
};
</script>

<style>
</style>
