import Vue from "vue";
import Cookies from "js-cookie";

import Element from "element-ui";
import "./assets/styles/element-variables.scss";

import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/uxsoft.scss"; // uxsoft css
import App from "./App";
import store from "./store";
import router from "./router";
import permission from "./directive/permission";
import "./assets/icons"; // icon
import "./permission"; // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  download,
  handleTree
} from "@/utils/uxsoft";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar";
import VFrame from "./framework/VFrame";
import "./register"; //register components
import format from '@/utils/format'
import '@/utils/math'
import util from '@/lib/util'
import { checkPermi, checkRole } from "@/utils/permission"; // 权限判断函数


import i18n from './main.vendor.locale'
import VXETable from "vxe-table";

import * as Sentry from "@sentry/vue";

import "@/assets/styles/pos.scss"

// 全局方法挂载
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.download = download;
Vue.prototype.handleTree = handleTree;
Vue.prototype.l = VFrame.l;
Vue.prototype.eventBus = new Vue()
Vue.prototype.checkPermi = checkPermi
Vue.prototype.checkRole = checkRole
Vue.prototype.$ux = {
  format: format,
  util: util,
  v: VFrame
}

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
};

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

Vue.prototype.propToLabel = function (str) {
  var firstLetter = str.substring(0, 1);
  var last = str.substring(1);
  return firstLetter.toUpperCase() + last;
};
Vue.prototype.copy = (model) => JSON.parse(JSON.stringify(model))

// 全局组件挂载
Vue.component("Pagination", Pagination);
Vue.component("RightToolbar", RightToolbar);
Vue.use(permission);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
  size: Cookies.get("size") || "medium" // set element-ui default size
});

VXETable.setup({
  // 对组件内置的提示语进行国际化翻译
  i18n: (key, args) => i18n.t(key, args),
  table: {
    rowId: '_XID', // 行数据的唯一主键字段名
  }
})

Vue.config.productionTip = false;

VFrame.start().then(() => {
  router.addRoutes([{
    path: "/login",
    component: resolve => require(["@/views/" + VFrame.loginPage], resolve),
    hidden: true,
  }, {
    path: "/posLogin",
    component: resolve => require(["@/views/" + VFrame.posLoginPage], resolve),
    hidden: true,
  },])

  Sentry.init({
    Vue,
    dsn: VFrame.sentry,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["*"],
    // Performance Monitoring 
    //保留10%的性能采样
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //出错100%复现
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  // redmine:24806,同源网址只允许存在一个浏览器窗口
  // (function () {
  //   const listeners = "ux-halo";
  //   const id = Date.parse(new Date()).toString()
  //   window.addEventListener("unload", removeKeyFromLocalStorage)
  //   window.addEventListener("load", () => {
  //     // 浏览器更新事件
  //     const key = JSON.parse(localStorage.getItem(listeners));
  //     if (Array.isArray(Object.keys(key)) && Object.keys(key).length == 1 && Object.keys(key)[0] == id) {
  //       localStorage.removeItem(listeners)
  //     } else {
  //       localStorage.removeItem(listeners);
  //       getChanel()
  //     }
  //   })
  //   function removeKeyFromLocalStorage() {
  //     const key = JSON.parse(localStorage.getItem(listeners));
  //     delete key[id];
  //     localStorage.setItem(listeners, JSON.stringify(key));
  //   }
  //   function getChanel() {
  //     // 创建通道
  //     let key = localStorage.getItem(listeners);
  //     if (key) {
  //       key = JSON.parse(key);
  //       key[id] = id
  //     } else {
  //       key = {};
  //       key[id] = id
  //     }
  //     if (Array.isArray(Object.keys(key)) && Object.keys(key).length > 1) {
  //       removeKeyFromLocalStorage()
  //       alert("已存在同源窗口");
  //       // 防止有些电脑的浏览器窗口不能被window.close()所关闭
  //       window.location.href = "http://127.0.0.1"
  //       window.close();
  //     } else {
  //       localStorage.setItem(listeners, JSON.stringify(key))
  //     }
  //   }
  //   getChanel()
  // })()

  store.commit("dragLayout/initLayout")
  var vue = new Vue({
    el: "#app",
    router,
    store,
    i18n,
    render: h => h(App)
  });
  // vue.CONSTANT.LANGUAGES = VFrame.languages
  VFrame.VueInstance = vue
})


//dialog方法
Vue.prototype.$dialog = {
  open(component, args) {
    return new Promise((resolve, reject) => {
      let Dialog = Vue.extend(component);
      var $vm = new Dialog({
        el: document.createElement("div"),
        router,
        store,
        eventBus: new Vue(),
      });
      var node = document.body.appendChild($vm.$el);

      $vm.open(args).then(
        result => {
          if (resolve) {
            resolve(result);
          }
          node.remove();
          $vm.$destroy();
        },
        (arg) => {
          if (reject) {
            reject(arg)
          }
          node.remove();
          $vm.$destroy();
        }
      );
    });
  }
};


//spring socket
//https://www.cnblogs.com/hhhshct/p/9507146.html