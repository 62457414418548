import cookie from "js-cookie";
import {
    infoSysQueryBuilder,
    dynamicQueryPage,
    dynamicQueryList
} from "@/api/uxretail/sys/sysQueryBuilder.js";
import SqlString from 'sqlstring'
import store from '@/store'
export default {
    components: {},
    props: {
        code: { default: "" },
        sys_params: { default: () => [] },
        page: {
            default: () => ({
                pageNum: 1,
                pageSize: 10,
            })
        },
        listFilter: {} //不启用page时可以用listFilter过滤结果
    },
    data() {
        return {
            languages: this.$ux.v.languages,
            languageGroupColumn: {},
            loading: false,
            list: [],
            total: 0,

            multipleSelection: [],
            queryModel: { columns: [], sql: "" },
            columns: [],
            defaultSort: {},

        };
    },
    watch: {
        queryModel() {
            this.fetchColumns();
        },
    },
    computed: {
        //sql会根据sys_params变化
        sql() {
            var sql = this.queryModel.querySQL;
            this.queryModel.sys_params.forEach((t) => {
                // console.log(SqlString.escape(this.sys_params[t.paramName] || t.defaultValue))
                // console.log(this.sys_params[t.paramName] || t.defaultValue)
                // sql = sql.replaceAll(
                //     "'{" + t.paramName + "}'",
                //     this.sys_params[t.paramName] || t.defaultValue
                // SqlString.escape(this.sys_params[t.paramName] || t.defaultValue)
                // );
                sql = sql.replaceAll(
                    "{" + t.paramName + "}",
                    this.$ux.util.stripscript(this.sys_params[t.paramName] || t.defaultValue)
                    // SqlString.escape(this.sys_params[t.paramName] || t.defaultValue)
                );
            });

            return sql;
        },
    },
    methods: {
        //------abstract-----------
        afterColumnsRender(defaultSort) {

        },
        beforeRefresh(sql, params) {
            return sql
        },
        afterRefresh() {

        },
        //------abstract end---------



        customStringFilterMethod() {
            return true;
        },
        fetchColumns() {
            var grouped = _.groupBy(this.queryModel.columns, (t) => t.multipleKey);

            var currentLanguageIndex = this.languages.indexOf(
                cookie.get(this.CONSTANT.UX_LANGUAGE)
            );
            var cols = [];
            for (var key in grouped) {
                if (key == "undefined" || key == "") {
                    cols = cols.concat(grouped[key]);
                } else {
                    var mg = grouped[key];
                    for (var p in mg) {
                        if (
                            currentLanguageIndex > 0 &&
                            p.lastIndexOf(currentLanguageIndex) == p.length - 1
                        ) {
                            cols.push(mg[p]);
                        } else if (
                            currentLanguageIndex == 0 &&
                            p.lastIndexOf("1") == -1 &&
                            p.lastIndexOf("2") == -1
                        ) {
                            cols.push(mg[p]);
                        }
                    }
                    this.languageGroupColumn[key] = cols[cols.length - 1]
                }
            }
            this.columns = _.sortBy(cols, (t) => t.sortIndex)
                .filter((t) => t.visible)
                .map((t) => {
                    var title = this.l(t.displayKey);
                    var align =
                        t.type == "number" ||
                            t.type == "amount" ||
                            t.type == "price" ||
                            t.type == "qty" ||
                            t.type == "cost" ||
                            t.type == "currency"
                            ? "right"
                            : "left";

                    var sCol = {
                        field: t.columnName,
                        title: title,
                        width: t.width,
                        align: align,
                        sortable: t.sortable,
                        filterColumnName: t.filterColumnName,
                        type: t.type,
                        filterOperate: "like",
                        filterValue: "",
                    };

                    if (!t.renderFunc) {
                        sCol.formatter = ({ cellValue, row, column }) => {
                            return this.$ux.format.columnFormatter(t.type)(
                                row,
                                column,
                                cellValue
                            );
                        };
                    } else {
                        sCol.renderFunc = { name: t.renderFunc };
                    }
                    return sCol;
                });

            let defaultSort = cols.find((t) => t.visible && t.sortable && t.sortDir);
            this.afterColumnsRender(defaultSort);
        },
        fetchSchema() {
            if (this.code) {
                var encyptQueryModel = store.state.app.queryBuilders[this.code] //sessionStorage.getItem(this.code);
                if (encyptQueryModel) {
                    this.queryModel = JSON.parse(encyptQueryModel);
                    // if (!this.lazyFetch) this.refresh();
                } else {
                    this.loading = true;
                    infoSysQueryBuilder(null, this.code.replace("QUERY-", ""))
                        .then((res) => {
                            this.queryModel = JSON.parse(res.configJson);
                            store.commit("app/SET_QUERY_BUILDER", { code: this.code, q: res.configJson });

                            // if (!this.lazyFetch) this.refresh();
                        })
                        .finally((this.loading = false));
                }
            } else {
                console.log("query-view code required");
            }
        },
        _refresh() {
            if (this.sql) {
                var params = [];
                var sql = this.sql;

                sql = this.beforeRefresh(sql, params)


                if (this.page) {
                    return dynamicQueryPage(
                        {
                            queryCode: this.code,
                            dataScopeActive: this.queryModel.dataScope ? this.queryModel.dataScope.active : false,
                            orgAlias: this.queryModel.dataScope ? this.queryModel.dataScope.orgAlias : "",
                            userAlias: this.queryModel.dataScope ? this.queryModel.dataScope.userAlias : "",
                            sql: sql,
                            params: params,
                        },
                        this.page
                    );
                } else {
                    return dynamicQueryList({
                        queryCode: this.code,
                        dataScopeActive: this.queryModel.dataScope ? this.queryModel.dataScope.active : false,
                        orgAlias: this.queryModel.dataScope ? this.queryModel.dataScope.orgAlias : "",
                        userAlias: this.queryModel.dataScope ? this.queryModel.dataScope.userAlias : "",
                        sql: sql,
                        params: params,
                    })
                }
            } else {
                return Promise.reject();
            }
        },
        refresh() {
            this.loading = true;
            this._refresh()
                .then((response) => {
                    const rows = [];
                    this.list = this.listFilter ? this.listFilter(response.items) : response.items;
                    this.total = response.totalCount;
                    this.list.forEach(t=>{
                        // queryView行数据是否勾选在这里处理
                        if (this.multiple && this.echoField && this.valueField) {
                            // 切换分页时,根据回显的数据源决定是否勾选行
                            if(this.echoData && this.echoData.length>0 && this.echoData[0][this.echoField]){
                                if(this.echoData.find(x => t[this.valueField] == x[this.valueField])){
                                    rows.push(t)
                                }
                                return;
                            }
                            // 首次打开弹窗
                            let value = Array.isArray(this.value)? this.value : this.value ? this.value.split(",") : [];
                            if(this.value && value.find(x => t[this.valueField] == x)){
                                rows.push(t)
                            }
                        }
                    })
                    if (this.multiple && rows.length > 0) {
                        this.$nextTick(() => {
                            this.$refs.vtable.setCheckboxRow(rows, true);
                        })
                    }
                    if(this.multiple && this.echoField){
                        // 根据this.value获取对应数据,queryView作为弹框展示且可多选时有用
                        this.search();
                    }
                    this.$emit('loaded', this.list)
                    this.afterRefresh()
                })
                .finally(() => (this.loading = false));
        },
        // recalculate() {
        //     console.log("re-calc");
        //     this.$refs.vtable.recalculate();
        // },
        // getSelected() {
        //     return this.$refs.vtable.getCheckboxRecords();
        // },
        getAll() {
            return this.list;
        },
    },
    created() {
        this.fetchSchema();
    },
};