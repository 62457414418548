/**
 * Created by M on 2018/2/6.
 */
class RsHelper {
  /*
   通过Sql提取查询参数
   */
  getFilterParamsFromSql(sql) {
    var params = sql.match(/{[\w\d]+}/g)

    params = params.map(t => t.replace('{', '').replace('}', ''))

    if (!params || params.length == 0) {
      return [];
    } else {
      params = this.unique(params);
      return params;
    }
    return params;
  }

  unique(arr) {
    var result = [], hash = {};
    for (var i = 0, elem; (elem = arr[i]) != null; i++) {
      if (!hash[elem]) {
        result.push(elem);
        hash[elem] = true;
      }
    }
    return result;
  }

  checkFilterItemError(filterItem) {
    var errorMsg = "";

    //通用判断
    if (!filterItem.name) {
      errorMsg += "Filter item name required. \r\n";
    }
    if (!filterItem.caption) {
      errorMsg += "Filter item caption required. \r\n";
    }
    if (!filterItem.sort) {
      errorMsg += "Filter item sort required. \r\n";
    }

    if (filterItem.type.indexOf("combox") > -1) {

      if (!filterItem.dataSourceId) {
        errorMsg += "Filter item datasource required. \r\n";
      }

      if (!filterItem.sql) {
        errorMsg += "Filter item sql required. \r\n";
      }

      if (filterItem.type.indexOf("ref") > -1) {

        var params = filterItem.sql.match(/{[\w\d]+}/g);

        if (!params || params.length == 0) {
          errorMsg += "Ref control {paramName} required. \r\n";
        }
      }
    }

    return errorMsg;
  }


  getSql(filterItems) {

  }
}


export default new RsHelper()
