import request from '@/utils/request'

// 查询QueryBuilder列表
export function listSysQueryBuilder({ keyword }) {
    return request({
        url: '/uxretail/SysQueryBuilder/list',
        method: 'post',
        data: { keyword }
    })
}

// fetchColumns
export function fetchColumnsSysQueryBuilder(data) {
    return request({
        url: '/uxretail/SysQueryBuilder/fetchColumns',
        method: 'post',
        data: data
    })
}

// 查询QueryBuilder列表
export function pageSysQueryBuilder({ keyword }, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/uxretail/SysQueryBuilder/page',
        method: 'post',
        data: { keyword },
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
// 查询QueryBuilder详细
export function infoSysQueryBuilder(id, code) {
    return request({
        url: '/uxretail/SysQueryBuilder/info',
        method: 'post',
        data: { id, code }
    })
}

// 新增QueryBuilder
export function saveSysQueryBuilder(data) {
    return request({
        url: '/uxretail/SysQueryBuilder/save',
        method: 'post',
        data: data
    })
}

// 删除QueryBuilder
export function deleteSysQueryBuilder(id) {
    return request({
        url: '/uxretail/SysQueryBuilder/delete',
        method: 'post',
        data: { id }
    })
}

export function dynamicQueryList(data) {
    return request({
        url: '/uxretail/SysQueryBuilder/dynamicQueryList',
        method: 'post',
        data: data,
    })
}

export function dynamicQueryOne(data) {
    return request({
        url: '/uxretail/SysQueryBuilder/dynamicQueryOne',
        method: 'post',
        data: data,
    })
}

export function dynamicQueryPage(data, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/uxretail/SysQueryBuilder/dynamicQueryPage',
        method: 'post',
        data: data,
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
