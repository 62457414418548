<template>
  <div style="display:flex;align-items:center;">
    <el-tag v-for="(sg) in value"
      :key="sg[idPath]"
      :closable="closeable?closeable(sg):true"
      :type="type?type(sg):''" class="mr5"
      @close="closeTag(sg)">
      {{sg[textPath]}}
    </el-tag>
    <el-button size="mini" type="text"
      @click="handleAddTag" :disabled="disabled">
      + {{l('Add')}}
    </el-button>
  </div>
</template>

<script>
import QueryDialog from "@/components/Query/QueryDialog.vue";
export default {
  name: "query-tag-input",
  props: {
    title: {
      default: "",
    },
    value: {
      default: () => [],
    },
    closeable: {},
    type: {},
    closeFunc: {},
    idPath: {
      default: "code",
    },
    textPath: {},
    queryCode: {},
    sys_params: { default: () => ({}) },
    title: {},
    disabled:{
      default:false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleAddTag() {
      this.$dialog
        .open(QueryDialog, {
          title: this.title,
          config: {
            multiple: true,
            code: this.queryCode,
            sys_params: this.sys_params,
          },
        })
        .then((res) => {
          res.forEach((t) => {
            if (!this.value.find((x) => x[this.idPath] == t[this.idPath]))
              this.value.push(t);
          });
          this.$emit("change");
        });
    },
    closeTag(sg) {
      this.closeFunc ? this.closeFunc(sg) : this.value.remove(sg);
      this.$emit("change");
    },
  },
};
</script>

<style>
</style>
