<template>
  <treeselect name="area-select"
    :defaultExpandLevel="alwaysOpen?Infinity:0"
    :multiple="multiple" :clearable="clearable"
    :searchable="searchable" :disabled="disabled"
    :clear-on-select="true"
    :close-on-select="true"
    :always-open="alwaysOpen"
    :placeholder="l('Select')"
    :options="disabledOptions"
    v-model="currentValue"
    :load-options="loadOptions"
    :max-height="maxHeight"
    :appendToBody="!alwaysOpen"
    :zIndex="alwaysOpen?999:9999"
    :loadingText="l('Loading')">
    <label slot="option-label"
      slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
      :class="labelClassName">
      {{ node.label }}
      <el-button v-if="alwaysOpen" type="text"
        icon="el-icon-edit" class="fr mr-5"
        @click="$emit('select',node.raw.source)">
      </el-button>
    </label>
  </treeselect>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import treeSelect from "@riophae/vue-treeselect";
import { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from "@riophae/vue-treeselect";
import request from "@/utils/request.js";
export default {
  name: "area-select",
  components: {
    treeselect: treeSelect,
  },
  props: {
    alwaysOpen: {
      default: false,
    },
    multiple: {
      default: false,
    },
    searchable: {
      default: true,
    },
    disabled: {
      default: false,
    },
    clearable: {
      default: true,
    },
    maxHeight: {
      default: 600,
    },
    value: {},
    disabledNodesByTreecode: { default: "" },
  },
  data() {
    var index = this.$store.state.user.languageIndex;
    return {
      currentValue: this.value,
      options: [],
      languageIndex: index <= 0 ? "" : index,
    };
  },
  watch: {
    currentValue() {
      this.$emit("input", this.currentValue);
    },
    value() {
      this.currentValue = this.value;
    },
  },
  computed: {
    disabledOptions() {
      if (this.disabledNodesByTreecode) {
        let _opt = this.copy(this.options);
        return this.deepDisableNodes(_opt);
      } else {
        return this.options;
      }
    },
  },
  methods: {
    deepDisableNodes(_opt) {
      for (var i = 0; i < _opt.length; i++) {        
        if (_opt[i].treeCode) {
          if (_opt[i].treeCode.indexOf(this.disabledNodesByTreecode) == 0) {
            _opt[i].isDisabled = true;
          }
        }
        if (_opt[i].children) {
          this.deepDisableNodes(_opt[i].children);
        }
      }
      return _opt;
    },
    refresh(id, node, callback) {
      request({
        url: "/uxretail/RefArea/treeList",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (!id) {
          this.options = this.genOptions(res.items, null);
        } else {
          node.children = this.genOptions(res.items, id);
        }
        if (callback) {
          callback();
        }
      });
    },
    search(text, callback) {
      request({
        url: "/uxretail/RefArea/treeList",
        method: "post",
        data: {
          code: text,
        },
      }).then((res) => {
        if (callback) {
          callback(null, this.genOptions(res.items, null));
        }
      });
    },
    loadOptions({ action, parentNode, searchQuery, callback }) {
      console.log(action);
      if (action === LOAD_CHILDREN_OPTIONS) {
        if (parentNode.isBranch) {
          console.log(parentNode.children);
          if (parentNode.children) {
            callback();
          } else {
            this.refresh(parentNode.id, parentNode, callback);
          }
        } else {
          callback();
        }
      } else if (action === ASYNC_SEARCH) {
        this.search(searchQuery, callback);
      }
    },
    genOptions(dtoList, pId) {
      var options = [];
      var currentList = dtoList.filter((t) => t.parentId == pId);
      if (!currentList || currentList.length == 0) {
        return null;
      }
      for (var i = 0; i < currentList.length; i++) {
        var dto = currentList[i];
        var opt = {
          id: dto.id,
          label: this.getLabel(dto),
          source: dto,
          treeCode: dto.treeCode,
          isBranch: dto.hasChildren,
        };
        if (dto.hasChildren) {
          opt.children = this.genOptions(dtoList, dto.id);
        }
        options.push(opt);
      }
      return options;
    },
    getLabel(dto) {
      return dto["name" + this.languageIndex];
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style lang="scss">
.vue-treeselect__label {
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
}

.vue-treeselect--single .vue-treeselect__input {
  height: auto;
}
</style>
