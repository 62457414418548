<template>
  <div style="display: none">
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    field: { type: String, default: "" },
    label: { type: String, default: null },
    type: { type: String, default: "string" },
    sortable: { type: Boolean, default: true }, //扩展属性
    requiredCol: { type: Boolean, default: false }, //不会通过table chooser隐藏的列
    validate: { type: Function, default: null },
    initStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    width: { type: String, default: "auto" },
    invisible: { type: Boolean, default: false },
    readonly: { default: null },
    textTransform: { type: String, default: null }, // replace uppercase prop
    textAlign: { type: String, default: null },

    keyField: { type: Boolean, default: false },
    sticky: { type: Boolean, default: false },
    change: { type: Function, default: null },

    allowKeys: {
      type: Array,
      default() {
        return null;
      },
    },
    mandatory: { type: Boolean, default: false },
    lengthLimit: { type: Number, default: 0 },
    autocomplete: { type: Boolean, default: null },
    pos: { type: Number, default: 0 },
    options: {
      default() {
        return () => [];
      },
    },
    selectOptions: {
      type: Object,
      default: () => {
        return { label: "code", value: "id" };
      },
    },
    summary: {},
    toValue: {
      type: Function,
      default(text) {
        switch (this.textTransform) {
          case "uppercase":
            text = text.toUpperCase();
            break;
          case "lowercase":
            text = text.toLowerCase();
            break;
        }
        switch (this.type) {
          case "datetick":
            return moment(text, "YYYY-MM-DD").valueOf();
          case "datetimetick":
            return moment(text, "YYYY-MM-DD HH:mm").valueOf();
          case "datetimesectick":
            return moment(text, "YYYY-MM-DD HH:mm:ss").valueOf();
          case "check10":
          case "checkYN":
          case "checkTF":
            return text.toUpperCase();
          default:
            return text;
        }
      },
    },
    toText: {
      type: Function,
      default(val) {
        // § magic to hide the temp key
        if (this.keyField && val && val.toString().startsWith("§")) return "";

        switch (this.type) {
          case "date":
            return val ? moment(val).format("YYYY-MM-DD") : "";
          case "datetick":
            return moment(Number(val)).format("YYYY-MM-DD");
          case "datetimetick":
            return moment(Number(val)).format("YYYY-MM-DD HH:mm");
          case "datetimesectick":
            return moment(Number(val)).format("YYYY-MM-DD HH:mm:ss");
          default:
            return val;
        }
      },
    },
    register: { type: Function, default: null },
  },
  created() {
    this.init();
  },
  destroyed() {
    if (!this.$parent) return;
    this.$parent.unRegisterColumn({
      name: this.field,
    });
  },
  methods: {
    init() {
      let style = this.initStyle;
      let validate = this.validate;
      let allowKeys = this.allowKeys;
      let lengthLimit = this.lengthLimit;

      switch (this.type) {
        case "number":
          style.textAlign = "right";
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            ".",
            "-",
          ];
          if (!validate) {
            validate = (val) => {
              if (parseFloat(val) != val) {
                return this.$parent.localizedLabel.invalidInputValue;
              }
              return "";
            };
          }
          break;
        case "date":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
          ];
          if (!validate)
            validate = (val) => {
              if (!moment(val, "YYYY-MM-DD", true).isValid())
                return this.$parent.localizedLabel.invalidInputValue;
              return "";
            };
          break;
        case "datetime":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            " ",
            ":",
          ];
          if (!validate)
            validate = (val) => {
              if (!moment(val, "YY-MM-DD hh:mm", true).isValid())
                return this.$parent.localizedLabel.invalidInputValue;
              return "";
            };
          break;
        case "datetimesec":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            " ",
            ":",
          ];
          if (!validate)
            validate = (val) => {
              if (!moment(val, "YY-MM-DD hh:mm:ss", true).isValid())
                return this.$parent.localizedLabel.invalidInputValue;
              return "";
            };
          break;
        case "mobile":
        case "datetick":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            " ",
            ":",
          ];
          break;
        case "datetimetick":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            " ",
            ":",
          ];
          break;
        case "datetimesectick":
          allowKeys = allowKeys || [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            " ",
            ":",
          ];
          break;
        case "check10":
          style.textAlign = "center";
          style.textTransform = "uppercase";
          allowKeys = allowKeys || ["0", "1"];
          lengthLimit = lengthLimit || 1;

          validate = (val) =>
            val == "1" || val == "0" || val === true || val === false
              ? ""
              : this.$parent.localizedLabel.invalidInputValue;
          break;
        case "checkYN":
          style.textAlign = "center";
          style.textTransform = "uppercase";
          allowKeys = allowKeys || ["Y", "N"];
          lengthLimit = lengthLimit || 1;

          validate = (val) =>
            val == "Y" || val == "N" || val === true || val === false
              ? ""
              : this.$parent.localizedLabel.invalidInputValue;
          break;
        case "checkTF":
          style.textAlign = "center";
          style.textTransform = "uppercase";
          allowKeys = allowKeys || ["T", "F"];
          lengthLimit = lengthLimit || 1;

          validate = (val) =>
            val == "T" || val == "F" || val === true || val === false
              ? ""
              : this.$parent.localizedLabel.invalidInputValue;
          break;
        case "select":
        case "custom":
        case "string":
          break;
        default:
          throw new Error("VueExcelColumn: Not supported type:" + this.type);
      }

      if (this.textTransform) style.textTransform = this.textTransform;
      if (this.textAlign) style.textAlign = this.textAlign;

      this.$parent.registerColumn({
        name: this.field,
        label: this.label === null ? this.field : this.label,
        type: this.type,
        width: this.width,
        validate: validate,
        change: this.change,
        keyField: this.keyField,
        sticky: this.sticky,
        allowKeys: allowKeys,
        mandatory: this.mandatory,
        lengthLimit: Number(lengthLimit),
        autocomplete:
          this.autocomplete === null
            ? this.$parent.autocomplete
            : this.autocomplete,
        initStyle: style,
        invisible: this.invisible,
        readonly: this.readonlyFunc(),
        pos: Number(this.pos),
        options: this.options,
        selectOptions: this.selectOptions,
        summary: this.summary,
        toValue: this.toValue,
        toText: this.toText,
        register: this.register,
        renderCell: this.renderCell,
        sortable: this.sortable,
        requiredCol: this.requiredCol,
        emitEvent: (eventName, args) => {
          this.$emit(eventName, args);
        },
      });
    },
    readonlyFunc() {
      if (this.$scopedSlots && this.$scopedSlots.default) {
        return () => {
          return true;
        };
      } else if (this.readonly !== null) {
        if (typeof this.readonly == "function") {
          return this.readonly;
        } else {
          return () => {
            return this.readonly;
          };
        }
      } else {
        return () => {
          return this.$parent.readonly;
        };
      }
    },
    renderCell(h, data, text) {
      let com = null;
      if (this.$scopedSlots && this.$scopedSlots.default) {
        com = this.$scopedSlots.default(data);
      } else {
        com = <span>{text}</span>;
      }
      return com;
    },
  },
};
</script>
