<template>
  <el-tabs v-model="activeName"
    class="language-tab-input">
    <el-tab-pane
      v-for="(language, index) in languages"
      :key="language" :label="l(language)"
      :name="language">
      <ux-form-item v-for="(f, index1) in fields"
        :key="index1"
        :label="labels.length > 0 ? labels[index1] : l(propToLabel(f))"
        :prop="f.name ? f.name : f">
        <el-input
          v-model="form[f.name ? f.name + (index == 0 ? '' : index) : f + (index == 0 ? '' : index)]"
          :rows="f.rows || 4"
          :type="f.type || 'text'"
          @change="v=>handleChange(index,f,v)">
          <slot :name="'suffix'+index1"
            slot="suffix"></slot>
        </el-input>
      </ux-form-item>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import QueryDialog from "@/components/Query/QueryDialog.vue";
export default {
  name: "language-input",
  props: {
    form: {},
    fields: {
      default: () => {
        return ["name"];
      },
    },
    labels: {
      default: () => [],
    },
    suffix: {
      default: () => false,
    },
  },
  watch: {
    "form.code"() {
      this.activeName = this.languages[0];
    },
    form(n, o) {
      if (n != o) {
        if (!this.form.id) {
          //填充字段跟踪
          for (var i = 0; i < this.fields.length; i++) {
            var fname = this.fields[i].name || this.fields[i];
            this.$set(this.form, fname, "");
            this.$set(this.form, fname + "1", "");
            this.$set(this.form, fname + "2", "");
          }
        }
      }
    },
  },
  data() {
    return {
      languages: this.$ux.v.languages,
      activeName: this.$ux.v.languages[0],
      tempCaches: {},
    };
  },
  methods: {
    handleChange(index, f) {
      if (index == 0) {
        if (!this.form.id) {
          var attr = f.name ? f.name : f;
          var value = this.form[attr];
          if (this.tempCaches[attr] != value) {
            if (
              !this.form[attr + "1"] ||
              this.form[attr + "1"] == this.tempCaches[attr]
            ) {
              this.form[attr + "1"] = value;
            }
            if (
              !this.form[attr + "2"] ||
              this.form[attr + "2"] == this.tempCaches[attr]
            ) {
              this.form[attr + "2"] = value;
            }

            this.tempCaches[attr] = value;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" >
.language-tab-input {
  height: auto;
}
.language-tab-input .el-tabs__header {
  margin: 0 0 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.language-tab-input .el-tabs__content {
  padding: 5px 5px 0px 5px;
  border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
}
</style>>
