import request from '@/utils/request'

// 查询Customer详细
export function infoSysImportTemplate(id, code) {
    return request({
        url: '/uxretail/SysImportTemplate/info',
        method: 'post',
        data: { id, code }
    })
}

// 新增Customer
export function saveSysImportTemplate(data) {
    return request({
        url: '/uxretail/SysImportTemplate/save',
        method: 'post',
        data: data
    })
}

// 删除Customer
export function deleteSysImportTemplate(id) {
    return request({
        url: '/uxretail/SysImportTemplate/delete',
        method: 'post',
        data: { id }
    })
}
