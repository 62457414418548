<template>
<n-layout v-loading="loading" :title="reportQuery?l(reportQuery.code):l('ReportQuery')" :description="reportLayout?'('+reportLayout.name+')':''" :actions="actions" @click="handleClick">
    <div class="ux-report-container">
        <div class="ux-report-body">
            <template v-if="reportQuery">
                <template v-if="sql">
                    <template v-if="reportQuery.reportQueryType=='pivot'">
                        <pivot :reportQuery="reportQuery" :sql="sql" ref="pivot" @init="handleInit"></pivot>
                    </template>
                    <template v-else-if="reportQuery.reportQueryType=='grid'">
                        <grid :reportQuery="reportQuery" :sql="sql" ref="grid" @init="handleInit"></grid>
                    </template>
                </template>
                <template v-else-if="reportQuery.reportQueryType=='report'&&(filters.length>0||reportQuery.reportSchema.filters==0)">
                    <n-report-viewer ref="report" :reportJson="reportQuery.reportSchema.reportJson" :filters="filters"></n-report-viewer>
                </template>
            </template>
        </div>
    </div>
</n-layout>
</template>

<script>
import pivot from "@/components/ReportQuery/components/pivot.vue";
import grid from "@/components/ReportQuery/components/grid.vue";
import filterDialog from "@/components/ReportQuery/filterDialog.vue";
import {
    infoRpQuery,
    infoRpLayout,
    saveRpLayout,
    deleteRpLayout
} from "@/api/uxreport/query.js";
import settingDialogVue from "./settingDialog.vue";
import nReportViewer from "@/components/Report/reportViewer.vue";
import saveLayoutDialog from './saveLayoutDialog.vue';
import layoutListDialog from './layoutListDialog.vue';
export default {
    components: {
        pivot,
        grid,
        nReportViewer
    },
    name: "ReportQueryViewer",
    data() {
        return {
            loading: false,
            reportQuery: null,
            reportLayout: null,
            sql: "",
            gridOptions: ["ShowBorders", "ShowColumnLines", "ShowRowLines"],
            filters: [],
        };
    },
    created() {
        var id = this.$route.meta.templateId;
        this.fetchReportQuery(id);
    },
    methods: {
        getState() {
            if (this.reportQuery.reportQueryType == 'pivot') {
                return this.$refs.pivot.getState()
            } else {
                return this.$refs.grid.getState()
            }
        },
        loadState(state) {
            if (this.reportQuery.reportQueryType == 'pivot') {
                return this.$refs.pivot.loadState(state)
            } else {
                return this.$refs.grid.loadState(state)
            }
        },
        pivotActHandler(act) {
            act.select = !act.select;
            act.text = act.select ? act.selectText : act.text;
            this.$refs.pivot[act.id](act.select);
        },
        handlePivotOptionChange(opt, e) {
            this.$refs.pivot[opt](e);
        },
        fetchReportQuery(id) {
            this.loading = true;
            infoRpQuery(id, null)
                .then((res) => {
                    this.reportQuery = res;
                    this.reportLayout = res.defaultLayout;
                    this.fetch();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleInit() {
            if (this.reportLayout) {
                this.loadState(JSON.parse(this.reportLayout.layout))
            }
        },
        fetch() {
            this.$dialog
                .open(filterDialog, {
                    filters: this.reportQuery.reportSchema.filters,
                    reportName: this.reportQuery.code,
                    sql: this.reportQuery.sql,
                })
                .then(({
                    filters,
                    sql
                }) => {
                    this.sql = sql;
                    this.filters = filters;
                    if (
                        this.reportQuery.reportQueryType == "report" &&
                        this.$refs.report
                    ) {
                        this.$nextTick(() => {
                            this.$refs.report.reload();
                        });
                    }
                });
        },
        handleClick(actionId) {
            if (actionId == "Filter") {
                this.fetch();
            } else if (actionId == "Reset") {
                if (this.reportQuery.reportQueryType == 'grid') {
                    this.loadState(null);
                } else {
                    this.$refs.pivot.resetLayout();
                }

            } else if (actionId == "Chart") {
                this.$refs.pivot.showChart();
            } else if (actionId == 'SaveLayout') {
                this.saveLayout()
            } else if (actionId == 'LoadLayout') {
                this.$dialog.open(layoutListDialog, {
                    queryId: this.reportQuery.id
                }).then(layout => {
                    if (layout) {
                        this.loading = true;
                        infoRpLayout({
                            id: layout.id
                        }).then(res => {
                            this.reportLayout = res;
                            this.loadState(JSON.parse(this.reportLayout.layout))
                        }).finally(() => this.loading = false)
                    } else {
                        this.reportLayout = null;
                    }
                })
            } else {
                this.$dialog
                    .open(settingDialogVue, {
                        type: this.reportQuery.reportQueryType,
                        ...this.$refs.pivot.getOptions(),
                    })
                    .then((res) => {
                        this.$refs.pivot.setOptions(res);
                    });
            }
        },
        saveLayout() {
            this.$dialog.open(saveLayoutDialog, this.reportLayout).then(res => {
                res.layout = JSON.stringify(this.getState())
                res.reportId = this.reportQuery.id;
                this.loading = true;
                saveRpLayout(res).then(r => {
                    this.$message.success(this.l('SavedSuccessfully'))
                    res.id = r;
                    this.reportLayout = res
                }).finally(() => this.loading = false)
            })
        }
    },
    computed: {
        actions() {
            let layoutActions = [{
                    id: "SaveLayout",
                    type: "primary",
                    text: this.l("SaveLayout"),
                    icon: "fa fa-save hvr-icon",
                },
                {
                    id: "LoadLayout",
                    type: "primary",
                    text: this.l("LoadLayout"),
                    icon: "fa fa-save hvr-icon",
                },
                {
                    id: "Reset",
                    type: "primary",
                    text: this.l("Reset"),
                    icon: "fa fa-gear hvr-icon",
                },
            ];
            if (this.reportQuery && this.reportQuery.reportQueryType == "pivot") {
                return [
                    // ...this.pivotActions,
                    [{
                            id: "Chart",
                            type: "primary",
                            text: this.l("Chart"),
                            icon: "fa fa-chart hvr-icon",
                        },
                        ...layoutActions
                    ],
                    [{
                            id: "Setting",
                            type: "primary",
                            text: this.l("Setting"),
                            icon: "fa fa-gear hvr-icon",
                        },
                        {
                            id: "Filter",
                            type: "primary",
                            text: this.l("Filter"),
                            icon: "fa fa-edit hvr-icon",
                        },
                    ],
                ];
            } else if (this.reportQuery && this.reportQuery.reportQueryType == 'grid') {
                return [
                    layoutActions,
                    [{
                        id: "Filter",
                        type: "primary",
                        text: this.l("Filter"),
                        icon: "fa fa-edit hvr-icon",
                    }, ],
                ]
            } else {
                return [
                    [{
                        id: "Filter",
                        type: "primary",
                        text: this.l("Filter"),
                        icon: "fa fa-edit hvr-icon",
                    }, ],
                ];
            }
        },
    },
};
</script>

<style lang="scss">
.ux-report-container {
    height: 100%;
    width: 100%;
    position: relative;

    .ux-report-toolbar {}

    .ux-report-body {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        position: absolute;
    }
}
</style>
