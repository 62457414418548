<template>
  <el-select v-model="currentValue"
    :placeholder="l('PleaseSelect')"
    :clearable="clearable" :disabled="disabled"
    @change="handleChange" filterable
    :multiple="multiple" :collapse-tags="true">
    <el-option v-for="item in options"
      :key="item.value" :label="l(item.key)"
      :disabled="customDisableFilter(item)"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import request from "@/utils/request";
import enumList from "@/ENUMS.js";

export default {
  name: "ux-select-enum",
  props: {
    disabled: false,
    enum: {},
    value: {},
    multiple: {
      default: false,
    },
    customDisableFilter: {
      default: (dataItem) => false,
      type: Function,
    },//Disable drop-down options
    multipleOption: {
      default: "array",
    }, //array|| splitChar like ,
    clearable: {
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      currentValue: this.getValue(),
    };
  },
  watch: {
    value() {
      this.currentValue = this.getValue();
    },
  },
  computed: {
    options() {
      return enumList[this.enum];
    },
  },
  methods: {
    getValue() {
      if (this.value || this.value == 0) {
        if (this.multiple) {
          if (this.multipleOption == "array") {
            return this.value;
          } else {
            return this.value.split(this.multipleOption);
          }
        } else {
          return this.value;
        }
      }
      return this.multiple ? [] : null;
    },
    getCurrentValue() {
      if (this.multiple) {
        if (this.multipleOption == "array") {
          return this.currentValue;
        } else {
          return this.currentValue.join(this.multipleOption);
        }
      } else {
        return this.currentValue;
      }
    },
    emitChange(text, input, rows) {
      this.$emit("update:text", text);
      this.$emit("input", input);
      this.$emit("change", {
        row: rows,
        value: input,
        label: text,
      });
    },
    handleChange() {
      if (this.currentValue || this.currentValue == 0) {
        if (this.multiple) {
          let rows = this.options.filter(
            (t) => this.currentValue.indexOf(t.value) > -1
          );
          let labels = rows
            .map((row) => this.l(row.key))
            .join(this.multipleOption.replace("array", ","));
          this.emitChange(labels, this.getCurrentValue(), rows);
        } else {
          let row = this.options.find((t) => t.value == this.currentValue);
          this.emitChange(this.l(row.key), this.getCurrentValue(), row);
        }
      } else {
        this.emitChange("", this.getCurrentValue(), null);
      }
    },
  },
  created() {},
};
</script>

<style>
</style>
