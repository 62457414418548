<template>
  <el-select multiple filterable v-model="currentValue" @change="handleChange">
    <el-option v-for="opt in selectOptions" :value="opt" :key="opt"></el-option>
  </el-select>
</template>

<script>
export default {
  props: ["value", "selectOptions"],
  data() {
    return {
      currentValue: this.getV(),
    };
  },
  watch: {
    value() {
      this.currentValue = this.getV();
    },
  },
  methods: {
    getV() {
      if (this.value && this.value.indexOf("[") > -1) {
        return JSON.parse(this.value);
      } else {
        return null;
      }
    },
    handleChange() {
      this.$emit("input", JSON.stringify(this.currentValue));
    },
  },
};
</script>

<style>
</style>