import variables from '@/assets/styles/element-variables.scss'
import defaultSettings from '@/settings'

const { sideTheme, showSettings, topNav, tagsView, fixedHeader, sidebarLogo } = defaultSettings

import { getUserConfig } from "@/api/uxretail/sys/sysUserConfig.js";
import CONSTANT from '@/CONSTANT.js'
const state = {
  theme: variables.theme,
  sideTheme: sideTheme,
  showSettings: showSettings,
  topNav: topNav,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo
}
const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  INIT_USER_SETTING: (state, configData) => {
    if (configData.configValue) {
      var settings = JSON.parse(configData.configValue);
      state.theme = settings.theme
      state.sideTheme = settings.sideTheme
      state.showSettings = settings.showSettings
      state.topNav = settings.topNav
      state.tagsView = settings.tagsView
      state.fixedHeader = settings.fixedHeader
      state.sidebarLogo = settings.sidebarLogo

    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

