<template>
  <!-- <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners" />
  <svg v-else :class="svgClass" aria-hidden="true" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg> -->
  <i :class="['iconfont','icon-'+iconClass ]" class="svg-icon" :style="{fontSize:size+'px',color:color,paddingRight:paddingRight}"></i>
</template>

<script>
import { isExternal } from "@/utils/validate";

export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    size: { default: 14 },
    color: { type: String, default: "#EEEEEE" },
    paddingRight: { default: "0" },
  },
};
</script> 
