import request from '@/utils/request'

// 查询DynamicAttribute列表
export function listRefDynamicAttribute({ keyword }) {
    return request({
        url: '/uxretail/RefDynamicAttribute/list',
        method: 'post',
        data: { keyword }
    })
}

// 导出DynamicAttribute
export function exportRefDynamicAttribute({ keyword }) {
    return request({
        url: '/uxretail/RefDynamicAttribute/export',
        method: 'post',
        data: { keyword }
    })
}

// 查询DynamicAttribute列表
export function pageRefDynamicAttribute({ keyword }, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/uxretail/RefDynamicAttribute/page',
        method: 'post',
        data: { keyword },
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
// 查询DynamicAttribute详细
export function infoRefDynamicAttribute(id, code) {
    return request({
        url: '/uxretail/RefDynamicAttribute/info',
        method: 'post',
        data: { id, code }
    })
}

// 新增DynamicAttribute
export function saveRefDynamicAttribute(data) {
    return request({
        url: '/uxretail/RefDynamicAttribute/save',
        method: 'post',
        data: data
    })
}

// 删除DynamicAttribute
export function deleteRefDynamicAttribute(id) {
    return request({
        url: '/uxretail/RefDynamicAttribute/delete',
        method: 'post',
        data: { id }
    })
}


export function getDynamicAttributeList(group, isPromotionAttribute) {
    return request({
        url: '/uxretail/RefDynamicAttribute/getDynamicAttributeList',
        method: 'post',
        data: { id: group, promotionAttribute: isPromotionAttribute }
    })
}