<template>
  <n-dialog-layout :title="l('DrillDownData')" ref="dialog">
    <DxDataGrid :data-source="dataSource" :columns="fields" :row-alternation-enabled="true" :allow-column-reordering="true" :allow-column-resizing="true"
      :show-borders="true" width="100%" height="600" @exporting="onExporting">
      <DxLoadPanel :enabled="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="none" />
      <DxExport :enabled="true" />
      <DxGroupPanel :visible="true" />
      <DxSearchPanel :visible="true" />
      <DxGrouping />
    </DxDataGrid>
  </n-dialog-layout>
</template>
<script>
import {
  DxDataGrid,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxSummary,
  DxTotalItem,
  DxExport,
  DxGroupPanel,
  DxSearchPanel,
  DxGrouping,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxSorting,
    DxLoadPanel,
    DxSummary,
    DxTotalItem,
    DxExport,
    DxGroupPanel,
    DxSearchPanel,
    DxGrouping,
  },
  data() {
    return {
      dataSource: [],
      fields: [],
    };
  },
  methods: {
    open({ dataSource, fields }) {
      this.dataSource = dataSource;
      this.fields = fields.map((t) => {
        return {
          dataField: t.dataField,
          caption: t.caption,
          width: t.width || "auto",
          dataType: t.type,
          format: t.format,
        };
      });
      return this.$refs.dialog.open();
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('data');

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            this.l('DrillDownData') + ".xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
