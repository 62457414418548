<template>
  <div :class="{'has-logo':showLogo}"
    :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg }">
    <logo v-if="showLogo"
      :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme"
      wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu"
        :collapse="true"
        :class="isCollapse?'side-zoom':''"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg"
        :text-color="settings.sideTheme === 'theme-dark' ? 'rgba(255,255,255,.65)' : 'rgba(0,0,0,.65)'"
        :active-text-color="'#53A8ff'"
        :collapse-transition="false"
        mode="vertical">
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path  + index" :item="route"
          :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
    <!-- <div class="version"> -->
    <div class="version" :class="{isCollapse}">
      <img v-if="!isCollapse" :src="maxLogo" class="max-logo">
      <img v-else :src="miniLogo" class="mini-logo" />
      <span>{{l('Version 1.0.0 By UxSoft')}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";

export default {
  components: { SidebarItem, Logo },
  data() {
    return { logo18: null ,logo:null};
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    miniLogo() {
      if (this.logo18) {
        return process.env.VUE_APP_BASE_API + this.logo18;
      } else {
        return "";
      }
    },
    maxLogo() {
      if (this.logo) {
        return process.env.VUE_APP_BASE_API + this.logo;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getConfigKey("ux.version.logo").then((response) => {
      this.logo18 = response.msg;
    });
    this.getConfigKey("ux.versionMax.logo").then((response) => {
      this.logo = response.msg;
    });
  },
};
</script>
<style lang="scss">
.version {
  // width: 67%;
  height: 80px;
  font-size: 12px;
  text-align: center;
  color: rgb(191, 203, 217) !important;
  z-index: 1500 !important;
  margin: 0 auto;
  margin-top: -70px;
  span{
    display: block;
    height: 20px;
    margin-top: 10px;
  }
  img {
    height: 40px;
  }
  .mini-logo{
    height: 32px;
  }
  .max-logo{
    margin-bottom: -10px;
  }
}
.isCollapse{
  margin-top: -95px;
  span{
    display: block;
    height: 20px;
    margin-top: 0px;
  }
}
.side-zoom {
  width: 94px !important;

  li span {
    display: none !important;
  }
  li > div {
    justify-content: center;
  }

  //图标
  .el-submenu__icon-arrow {
    display: none !important;
  }
}
</style>