import VueExcelFilter from './VueExcelFilter.vue'
import PanelFilter from './panelFilter.vue'
// import PanelSetting from './panelSetting.vue'
import PanelFind from './panelFind.vue'
// import DatePicker from 'vue2-datepicker'
import XLSX from 'xlsx/dist/xlsx.full.min.js'
// import 'vue2-datepicker/index.css'
import InlineDatePicker from './components/InlineDatePicker.vue'
import _ from 'lodash'
import { relativeTimeThreshold } from 'moment'


let renderSlot = {
    render(h) {
        let dom = this.renderCell.call(this._renderProxy,
            this.$createElement,
            this.data,
            this.text)
        return dom
    },
    props: {
        renderCell: {},
        data: {},
        text: {}
    }
}

export default {
    components: {
        'vue-excel-filter': VueExcelFilter,
        'panel-filter': PanelFilter,
        // 'panel-setting': PanelSetting,
        'panel-find': PanelFind,
        "render-slot": renderSlot,
        'date-picker': InlineDatePicker,
    },
    props: {
        //扩展属性
        autoAddRow: { type: Function },
        sortable: { type: Boolean, default: true },
        //原有属性
        value: { type: Array, default() { return [] } },
        rowStyle: { type: Function, default() { return {} } },
        cellStyle: { type: Function, default() { return {} } },
        headerLabel: {
            type: Function,
            default(label) {
                return label
            }
        },
        recordLabel: {                                  // return the row header
            type: Function,
            default(pos) {
                return pos
            }
        },
        noFinding: { type: Boolean, default: false },
        noFindingNext: { type: Boolean, default: false },
        filterRow: { type: Boolean, default: false },
        freeSelect: { type: Boolean, default: false },
        noFooter: { type: Boolean, default: false },
        noPaging: { type: Boolean, default: false },
        noNumCol: { type: Boolean, default: false },
        page: { type: Number, default: 0 },               // prefer page size, auto-cal if not provided
        newRecord: { type: Function, default: null },     // return the new record from caller if provided
        nFilterCount: { type: Number, default: 1000 },    // show top n values in filter dialog
        height: { type: String, default: '' },
        width: { type: String, default: '100%' },
        autocomplete: { type: Boolean, default: false },  // Default autocomplete of all columns
        readonly: { type: Boolean, default: false },
        readonlyStyle: { type: Object, default() { return { backgroundColor: '#f6f8fa' } } },
        remember: { type: Boolean, default: false },
        register: { type: Function, default: null },
        localizedLabel: {
            type: Object,
            default() {
                return {
                    footerLeft: (top, bottom, total) => `Record ${top} to ${bottom} of ${total}`,
                    first: 'First',
                    previous: 'Previous',
                    next: 'Next',
                    last: 'Last',
                    footerRight: {
                        selected: 'Selected:',
                        filtered: 'Filtered:',
                        loaded: 'Loaded:'
                    },
                    processing: 'Processing',
                    tableSetting: 'Table Setting',
                    exportExcel: 'Export Excel',
                    importExcel: 'Import Excel',
                    back: 'Back',
                    reset: 'Default',
                    sortingAndFiltering: 'Sorting And Filtering',
                    sortAscending: 'Sort Ascending',
                    sortDescending: 'Sort Descending',
                    near: '≒ Near',
                    exactMatch: '= Exact Match',
                    notMatch: '≠ Not Match',
                    greaterThan: '&gt; Greater Than',
                    greaterThanOrEqualTo: '≥ Greater Than or Equal To',
                    lessThan: '&lt; Less Than',
                    lessThanOrEqualTo: '≤ Less Than Or Equal To',
                    regularExpression: '~ Regular Expression',
                    customFilter: 'Custom Filter',
                    listFirstNValuesOnly: n => `List first ${n} values only`,
                    apply: 'Apply',
                    noRecordIsRead: 'No record is read',
                    readonlyColumnDetected: 'Readonly column detected',
                    columnHasValidationError: (name, err) => `Column ${name} has validation error: ${err}`,
                    noMatchedColumnName: 'No matched column name',
                    invalidInputValue: 'Invalid input value',
                    missingKeyColumn: 'Missing key column'
                }
            }
        },
        showImportExport: { type: Boolean, default: true },
        importDisabled: { type: Boolean, default: false }
    },
    data() {
        const pageSize = this.noPaging ? 999999 : 20
        const dataset = {
            version: '1.2',
            tableContent: null,           // Table parent
            systable: null,               // TABLE dom node
            colgroupTr: null,             // colgroup TR dom node
            labelTr: null,                // THEAD label dom node
            filterTr: null,               // THEAD filter dom node
            recordBody: null,             // TBODY dom node
            footer: null,                 // TFOOTER dom node

            pageSize: pageSize,
            pageTop: 0,                   // Current page top pos of [table] array

            selected: {},                 // selected storage in hash, key is the pos of [table] array
            selectedCount: 0,             // selected row count
            prevSelect: -1,               // previous select pos of [table] array
            processing: false,            // current general-purpose processing status

            rowIndex: {},                 // index of the record key to pos of [table] array

            currentRecord: null,          // focusing TR dom node
            currentRowPos: -1,             // focusing array pos of [table] array
            currentColPos: 0,             // focusing pos of column/field
            currentField: null,           // focusing field object
            currentCell: null,
            inputBox: null,
            inputBoxShow: 0,
            inputSquare: null,
            autocompleteInputs: [],
            autocompleteSelect: -1,

            errmsg: {},
            tip: '',

            colHash: '',
            fields: [],
            focused: false,
            mousein: false,
            inputBoxChanged: false,

            columnFilter: {},             // set filter storage in hash, key is the column pos

            inputFind: '',
            calCellLeft: 0,
            calCellTop: 0,
            calCellTop2: 29,

            frontdrop: null,              // frontdrop dom node

            sortPos: 0,                   // Sort column position
            sortDir: 0,                   // Sort direction, 1=Ascending, -1=Descending
            redo: [],                     // redo log

            lazyTimeout: {},
            lazyBuffer: {},
            hScroller: {},
            vScroller: {},
            leftMost: 0,

            showDatePicker: false,
            inputDateTime: '',

            table: [],
            summaryRow: false,
            summary: {},
            showFilteredOnly: true,
            showSelectedOnly: false,
            // contextMenus: [
            //     { display: this.l('ExportExcel'), icon: "fa fa-download", handler: () => this.exportTable() },
            //     { id: 'import', display: this.l('ImportExcel'), icon: "fa fa-upload", handler: () => this.importTable() },
            // ]
        }
        return dataset
    },
    computed: {
        _contextMenus() {
            if (!this.showImportExport) {
                return this.contextMenus.slice(2)
            }
            return this.contextMenus
        },
        token() {
            const id = Array.from(document.querySelectorAll('.vue-excel-editor')).indexOf(this.$el)
            return `vue-excel-editor-${id}`
        },
        columnFilterString() {
            Object.keys(this.columnFilter).forEach((key) => {
                if (this.columnFilter[key].trim() === '') delete this.columnFilter[key]
            })
            return JSON.stringify(this.columnFilter)
        },
        pagingTable() {
            return this.table.slice(this.pageTop, this.pageTop + this.pageSize)
        },
        pageBottom() {
            if (this.value.length === 0) return 0
            else return this.pageTop + this.pageSize > this.table.length ? this.table.length : this.pageTop + this.pageSize
        },
        setting: {
            get() {
                return null
            },
            set(setter) {
                if (setter.fields) {
                    // ignore if fields counts are different
                    if (setter.fields.length !== this.fields.length) return
                    let valid = true
                    const newFields = setter.fields.map(local => {
                        const current = this.fields.find(f => f.name === local.name)
                        if (!current) valid = false
                        current.invisible = local.invisible
                        current.width = local.width
                        return current
                    })
                    if (valid) {
                        this.fields = newFields
                        this.$forceUpdate()
                    }
                }
            }
        },
        //currentRecord可能为空
        currentRow() {
            return this.table[this.currentRowPos]
        }
    },
    watch: {
        focused(n, o) {
            if (n) {
                this.eventBus.$emit('excel-editor-focus', this)
            }
        },
        value() {
            // detect a loading process, refresh something
            // this.redo = []
            // this.errmsg = {}           
            this.autoAddRow(this.value.length == 0 ? null : this.value[this.value.length - 1])
            this.lazy(this.refresh)
        },
        columnFilterString() {
            this.processing = true
            setTimeout(() => {
                this.refresh()
                this.processing = false
            }, 0)
        },
        fields: {
            handler() {
                this.lazy(() => {
                    const setting = this.getSetting()
                    if (this.remember) localStorage[window.location.pathname + '.' + this.token] = JSON.stringify(setting)
                    this.$emit('setting', setting)
                })
            },
            deep: true
        },
        processing(newVal) {
            if (newVal) {
                const rect = this.$el.children[0].getBoundingClientRect()
                this.frontdrop.style.top = rect.top + 'px'
                this.frontdrop.style.left = rect.left + 'px'
                this.frontdrop.style.height = rect.height + 'px'
                this.frontdrop.style.width = rect.width + 'px'
            }
        },
        currentRowPos() {
            this.$emit('rowChange', this.currentRow)
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.winResize)
        window.removeEventListener('paste', this.winPaste)
        window.removeEventListener('keydown', this.winKeydown)
        window.removeEventListener('scroll', this.winScroll)
    },
    mounted() {
        this.tableContent = this.$refs.tableContent
        this.systable = this.$refs.systable
        this.colgroupTr = this.systable.children[0]
        this.labelTr = this.systable.children[1].children[0]
        this.filterTr = this.systable.children[1].children[1]
        this.recordBody = this.systable.children[2]
        this.footer = this.$refs.footer
        this.inputSquare = this.$refs.inputSquare
        this.inputBox = this.$refs.inputBox
        this.frontdrop = this.$refs.frontdrop

        if (this.height)
            this.systable.parentNode.style.height = this.height

        this.reset()
        this.lazy(() => {
            this.labelTr.children[0].style.height = this.labelTr.offsetHeight + 'px'
            this.calCellTop2 = this.labelTr.offsetHeight
            this.refreshPageSize()
            this.tableContent.scrollTo(0, this.tableContent.scrollTop)
            this.calStickyLeft()
        }, 200)

        window.addEventListener('resize', this.winResize)
        window.addEventListener('paste', this.winPaste)
        window.addEventListener('keydown', this.winKeydown)
        window.addEventListener('scroll', this.winScroll)

        if (this.remember) {
            const saved = localStorage[window.location.pathname + '.' + this.token]
            if (saved) {
                const data = JSON.parse(saved)
                if (data.colHash === this.colHash)
                    this.setting = data
            }
        }

        this.eventBus.$on('excel-editor-focus', (excel) => {
            if (excel != this) {
                this.blur()
            }
        })

        if (this.autoAddRow)
            this.autoAddRow()
    },
    updated() {
        if (this.$refs.hScroll && this.hScroller.tableUnseenWidth == 0) {
            this.lazy(() => {
                this.refreshPageSize();
            })
        }
    },
    methods: {
        handleContextMenu(e) {
            console.log(e)
        },
        _getByPropertyPath(item, path) {
            return _.get(item, path)
        },
        _setByPropertyPath(item, path, value) {
            _.set(item, path, value)
        },
        blur() {
            this.focused = false
        },
        focus() {
            this.moveTo(this.currentRowPos == -1 ? 0 : this.currentRowPos, this.currentColPos)
        },
        removeRow() {
            //todo: 这里使用了abp 需要考虑解耦
            this.$confirm(this.l('SureRemoveRowIndexOf', this.currentRowPos + 1)).then(() => {
                //todo:这里使用了 array的扩展方法remove
                this.table.remove(this.currentRow)
            })
        },
        validate() {
            if (Object.keys(this.errmsg).length > 0) {
                let valid = true;
                for (var p in this.errmsg) {
                    let err = this.errmsg[p];
                    if (err.rowIndex == this.value.length - 1) {
                        //最后一行忽略
                    } else {
                        valid = false;
                    }
                }
                return valid;
            } else {
                return true;
            }
        },
        handleAddClick() {
            this.$emit('addRow')
        },
        handleRemoveClick() {
            this.$emit('removeRows', this.getSelectedRecords());
        },
        reset() {
            this.errmsg = {}
            this.redo = []
            this.showFilteredOnly = true
            this.showSelectedOnly = false
            this.columnFilter = {}
            this.sortPos = 0
            this.sortDir = 0
            this.inputFind = ''
            this.pageTop = 0
            this.selected = {}
            this.selectedCount = 0
            this.prevSelect = -1
            this.processing = false
            this.rowIndex = {}
            this.refresh()
        },
        toggleSelectView(e) {
            if (e) e.stopPropagation()
            this.showSelectedOnly = !this.showSelectedOnly
            return this.refresh()
        },
        toggleFilterView(e) {
            if (e) e.stopPropagation()
            this.showFilteredOnly = !this.showFilteredOnly
            return this.refresh()
        },
        resetColumn() {
            this.fields = []
            this.$slots.default.forEach(col => col.componentInstance ? col.componentInstance.init() : 0)
            this.tableContent.scrollTo(0, this.tableContent.scrollTop)
            this.calStickyLeft()
        },
        unRegisterColumn(field) {
            let exist = this.fields.find(t => t.name == field.name)
            if (exist) {
                this.fields.remove(exist)
            }
        },
        registerColumn(field) {
            let pos = this.fields.findIndex(item => item.pos > field.pos)
            if (pos === -1) pos = this.fields.length
            this.fields.splice(pos, 0, field)
            if (this.register) this.register(field, pos)
            if (field.register) field.register(field, pos)
            if (field.summary) this.summaryRow = true
            this.colHash = this.hashCode(this.version + JSON.stringify(this.fields))
        },
        refresh() {
            this.pageTop = 0
            this.prevSelect = -1
            this.calTable()
            this.refreshPageSize()
        },
        calTable() {
            // add unique key to each row if no key is provided
            let seed = new Date().getTime() - 1578101000000
            this.value.forEach((rec, i) => {
                if (!rec.$id) rec.$id = seed + '-' + i
            })

            if (this.showFilteredOnly === false) {
                this.table = this.value
            }
            else {
                const filterColumnList = Object.keys(this.columnFilter)
                const filter = {}
                filterColumnList.forEach((k) => {
                    switch (true) {
                        case this.columnFilter[k].startsWith('<='):
                            filter[k] = { type: 1, value: this.columnFilter[k].slice(2).trim().toUpperCase() }
                            if (this.fields[k].type === 'number') filter[k].value = Number(filter[k].value)
                            break
                        case this.columnFilter[k].startsWith('<>'):
                            filter[k] = { type: 9, value: this.columnFilter[k].slice(2).trim().toUpperCase() }
                            break
                        case this.columnFilter[k].startsWith('<'):
                            filter[k] = { type: 2, value: this.columnFilter[k].slice(1).trim().toUpperCase() }
                            if (this.fields[k].type === 'number') filter[k].value = Number(filter[k].value)
                            break
                        case this.columnFilter[k].startsWith('>='):
                            filter[k] = { type: 3, value: this.columnFilter[k].slice(2).trim().toUpperCase() }
                            if (this.fields[k].type === 'number') filter[k].value = Number(filter[k].value)
                            break
                        case this.columnFilter[k].startsWith('>'):
                            filter[k] = { type: 4, value: this.columnFilter[k].slice(1).trim().toUpperCase() }
                            if (this.fields[k].type === 'number') filter[k].value = Number(filter[k].value)
                            break
                        case this.columnFilter[k].startsWith('='):
                            filter[k] = { type: 0, value: this.columnFilter[k].slice(1).trim().toUpperCase() }
                            break
                        case this.columnFilter[k].startsWith('*') && this.columnFilter[k].endsWith('*'):
                            filter[k] = { type: 5, value: this.columnFilter[k].slice(1).slice(0, -1).trim().toUpperCase() }
                            break
                        case this.columnFilter[k].startsWith('*') && !this.columnFilter[k].slice(1).includes('*'):
                            filter[k] = { type: 6, value: this.columnFilter[k].slice(1).trim().toUpperCase() }
                            break
                        case this.columnFilter[k].startsWith('~'):
                            filter[k] = { type: 8, value: this.columnFilter[k].slice(1).trim() }
                            break
                        case this.columnFilter[k].endsWith('*') && !this.columnFilter[k].slice(0, -1).includes('*'):
                            filter[k] = { type: 7, value: this.columnFilter[k].slice(0, -1).trim().toUpperCase() }
                            break
                        case this.columnFilter[k].includes('*') || this.columnFilter[k].includes('?'):
                            filter[k] = { type: 8, value: '^' + this.columnFilter[k].replace(/\*/g, '.*').replace(/\?/g, '.').trim() + '$' }
                            break
                        default:
                            filter[k] = { type: 5, value: this.columnFilter[k].trim().toUpperCase() }
                            break
                    }
                })
                if (filterColumnList.length === 0)
                    this.table = this.value
                else {
                    this.table = this.value.filter((record) => {
                        const content = {}
                        filterColumnList.forEach((k) => {
                            const val = record[this.fields[k].name]
                            if (this.fields[k].type === 'number' && filter[k].type <= 4)
                                content[k] = val
                            else
                                content[k] = typeof val === 'undefined' || val === null ? '' : String(val).toUpperCase()
                        })
                        for (let i = 0; i < filterColumnList.length; i++) {
                            const k = filterColumnList[i]
                            if (this.fields[k].keyField && content[k].startsWith('§')) return true
                            switch (filter[k].type) {
                                case 0:
                                    if (`${content[k]}` !== `${filter[k].value}`) return false
                                    break
                                case 1:
                                    if (filter[k].value < content[k]) return false
                                    break
                                case 2:
                                    if (filter[k].value <= content[k]) return false
                                    break
                                case 3:
                                    if (filter[k].value > content[k]) return false
                                    break
                                case 4:
                                    if (filter[k].value >= content[k]) return false
                                    break
                                case 5:
                                    if (!content[k].includes(filter[k].value)) return false
                                    break
                                case 6:
                                    if (!content[k].endsWith(filter[k].value)) return false
                                    break
                                case 7:
                                    if (!content[k].startsWith(filter[k].value)) return false
                                    break
                                case 8:
                                    if (!new RegExp(filter[k].value, 'i').test(content[k])) return false
                                    break
                                case 9:
                                    if (`${content[k]}` === `${filter[k].value}`) return false
                                    break
                            }
                        }
                        return true
                    })
                }
            }

            this.reviseSelectedAfterTableChange()
            if (this.showSelectedOnly) {
                this.table = this.table.filter((rec, i) => this.selected[i])
                this.reviseSelectedAfterTableChange()
            }
            this.calSummary()
        },
        calStickyLeft() {
            let left = 0, n = 0
            this.leftMost = -1
            // this.tableContent.scrollTo(0, this.tableContent.scrollTop)
            Array.from(this.labelTr.children).forEach(th => {
                left += th.offsetWidth
                const field = this.fields[n++]
                if (field)
                    if (field.sticky) field.left = left + 'px'
                    else if (this.leftMost === -1) this.leftMost = left
            })
            this.$forceUpdate()
        },
        renderColumnCellStyle(field, record) {
            let result = field.initStyle
            if (record && field.readonly(record)) {
                result = Object.assign({}, result, this.readonlyStyle)
            }
            if (field.left) result = Object.assign({}, result, { left: field.left })
            return result
        },
        calSummary() {
            this.fields.forEach(field => {
                if (!field.summary) return ''
                const i = field.name
                let result = ''
                if (typeof field.summary == 'function') {
                    result = field.summary()
                } else {
                    switch (field.summary) {
                        case 'sum':
                            result = this.table.reduce((a, b) => (a + Number(b[i] ? b[i] : 0)), 0)
                            result = Number(Math.round(result + 'e+5') + 'e-5')  // solve the infinite .9 issue of javascript
                            break
                        case 'avg':
                            result = this.table.reduce((a, b) => (a + Number(b[i] ? b[i] : 0)), 0) / this.table.length
                            result = Number(Math.round(result + 'e+5') + 'e-5')  // solve the infinite .9 issue of javascript
                            break
                        case 'max':
                            result = this.table.reduce((a, b) => (a > b[i] ? a : b[i]), Number.MIN_VALUE)
                            break
                        case 'min':
                            result = this.table.reduce((a, b) => (a < b[i] ? a : b[i]), Number.MAX_VALUE)
                            break
                    }
                }
                if (isNaN(result)) result = ''
                this.$set(this.summary, i, typeof field.summary == 'function' ? result : field.toText(result, {}))
            })
        },
        getKeys(rec) {
            if (!rec) rec = this.currentRecord
            const key = this.fields.filter(field => field.keyField).map(field => rec[field.name])
            if (key.length && key.join() !== '') return key
            return [rec.$id]
        },

        /* *** Customization **************************************************************************************
         */
        setFilter(name, filterText) {
            const ref = this.$refs[`filter-${name}`][0]
            ref.$el.textContent = filterText
            ref.$emit('input', filterText)
        },

        clearFilter(name) {
            if (!name) this.columnFilter = {}
            else this.setFilter(name, '')
        },

        columnSuppress() {
            if (this.table.length === 0) return
            const cols = {}
            this.table.forEach((row) => {
                Object.keys(row).forEach((field) => {
                    if (row[field]) cols[field] = 1
                })
            })
            const showCols = Object.keys(cols)
            this.fields.forEach((field) => {
                if (!showCols.includes(field.name))
                    field.invisible = true
            })
            this.refresh()
        },

        /* Still evaluating */
        columnAutoWidth(name) {
            if (this.table.length === 0) return
            let doFields = this.fields
            if (name) doFields = [this.fields.find(f => f.name === name)]

            const cols = {}
            this.table.forEach((row) => {
                doFields.forEach((field) => {
                    if (row[field.name] && (!cols[field.name] || cols[field.name] < row[field.name].length))
                        cols[field.name] = row[field.name].length
                })
            })
            doFields.forEach((field) => {
                let width = cols[field.name] * 12
                if (width > 450) width = 450
                field.width = width + 'px'
            })
            this.refresh()
        },

        /* *** Date Picker *********************************************************************************
         */
        //todo:tab会切换单元格 导致赋值失败， 后续修复
        showDatePickerDiv() {
            const cellRect = this.currentCell.getBoundingClientRect()
            this.$refs.dpContainer.style.left = (cellRect.left) + 'px'
            this.$refs.dpContainer.style.top = (cellRect.bottom) + 'px'
            this.inputDateTime = this.currentCell.textContent
            this.showDatePicker = true
            this.$nextTick(() => {
                this.$refs.datepicker.show()
            })

            this.lazy(() => {
                const r = this.$refs.dpContainer.getBoundingClientRect()
                if (r.bottom > window.innerHeight)
                    this.$refs.dpContainer.style.top = (cellRect.top - r.height) + 'px'
                if (r.right > window.innerWidth)
                    this.$refs.dpContainer.style.top = (window.innerWidth - r.width) + 'px'
            })

        },
        datepickerClick(date) {
            if (window.event.key == "Enter") window.event.stopPropagation();
            this.inputBox.value = this.inputDateTime
            this.inputBoxShow = 0
            this.inputCellWrite(this.inputDateTime)
            this.showDatePicker = false
            this.focused = true
        },

        /* *** Vertical Scrollbar *********************************************************************************
         */
        calVScroll() {
            let d = this.labelTr.getBoundingClientRect().height
            if (this.filterRow) d += 29
            this.vScroller.top = d
            if (!this.noFooter) d += 25
            if (this.summaryRow) d += 27
            const fullHeight = this.$el.getBoundingClientRect().height
            this.vScroller.height = fullHeight - d
            const ratio = this.vScroller.height / (this.table.length * 24)
            this.vScroller.buttonHeight = Math.max(24, this.vScroller.height * ratio)
            const prop = (this.tableContent.scrollTop + this.pageTop * 24) / (this.table.length * 24 - this.vScroller.height)
            this.vScroller.buttonTop = (this.vScroller.height - this.vScroller.buttonHeight) * prop
            this.$forceUpdate()
        },
        vsMouseDown(e) {
            e.stopPropagation()
            const pos = e.offsetY - this.vScroller.buttonHeight / 2
            let ratio = Math.max(0, pos)
            ratio = Math.min(ratio, this.vScroller.height - this.vScroller.buttonHeight)
            ratio = ratio / (this.vScroller.height - this.vScroller.buttonHeight)
            if (this.noPaging)
                this.tableContent.scrollTo(this.tableContent.scrollLeft, this.table.length * 24 * ratio)
            else {
                this.vScroller.buttonTop = ratio * (this.vScroller.height - this.vScroller.buttonHeight)
                this.$refs.vScrollButton.style.marginTop = this.vScroller.buttonTop + 'px'
                this.pageTop = Math.round((this.table.length - this.pageSize) * ratio)
            }
        },
        vsbMouseDown(e) {
            e.stopPropagation()
            if (!this.vScroller.mouseY) {
                this.vScroller.saveButtonTop = this.vScroller.buttonTop
                this.vScroller.mouseY = e.clientY
                window.addEventListener('mousemove', this.vsbMouseMove)
                window.addEventListener('mouseup', this.vsbMouseUp)
                this.$refs.vScrollButton.classList.add('focus')
            }
        },
        vsbMouseUp() {
            window.removeEventListener('mousemove', this.vsbMouseMove)
            window.removeEventListener('mouseup', this.vsbMouseUp)
            this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'))
            this.vScroller.mouseY = 0
            if (!this.noPaging) {
                const ratio = this.vScroller.buttonTop / (this.vScroller.height - this.vScroller.buttonHeight)
                this.pageTop = Math.round((this.table.length - this.pageSize) * ratio)
            }
            this.vScroller.runner = ''
            this.$forceUpdate()
        },
        vsbMouseMove(e) {
            if (e.buttons === 0)
                this.vsbMouseUp()
            else {
                const diff = e.clientY - this.vScroller.mouseY
                if (this.noPaging) {
                    const ratio = (this.vScroller.saveButtonTop + diff) / (this.vScroller.height - this.vScroller.buttonHeight)
                    this.tableContent.scrollTo(this.tableContent.scrollLeft, this.table.length * 24 * ratio)
                }
                else {
                    this.vScroller.buttonTop = Math.max(0, Math.min(this.vScroller.height - this.vScroller.buttonHeight, this.vScroller.saveButtonTop + diff))
                    this.$refs.vScrollButton.style.marginTop = this.vScroller.buttonTop + 'px'

                    const ratio = this.vScroller.buttonTop / (this.vScroller.height - this.vScroller.buttonHeight)
                    const recPos = Math.round((this.table.length - this.pageSize) * ratio) + 1
                    const rec = this.table[recPos]
                    this.vScroller.runner = recPos + '<br>' + this.fields
                        .filter((field, i) => field.keyField || field.sticky || this.sortPos === i)
                        .map(field => rec[field.name])
                        .join('<br>')
                    this.$forceUpdate()
                }
            }
        },

        /* *** Horizontal Scrollbar *********************************************************************************
         */
        ftMouseDown(e) {
            const footerRect = this.footer.getBoundingClientRect()
            const ratio = (e.x - footerRect.left - 40) / (footerRect.width - 40)
            const fullWidth = this.systable.getBoundingClientRect().width
            const viewWidth = this.tableContent.getBoundingClientRect().width
            this.tableContent.scrollTo(fullWidth * ratio - viewWidth / 2, this.tableContent.scrollTop)
        },
        sbMouseDown(e) {
            e.stopPropagation()
            if (!this.hScroller.mouseX) {
                const sleft = this.$refs.hScroll.getBoundingClientRect().left
                const fleft = this.footer.getBoundingClientRect().left + 40
                this.hScroller.left = sleft - fleft
                this.hScroller.mouseX = e.clientX
                window.addEventListener('mousemove', this.sbMouseMove)
                window.addEventListener('mouseup', this.sbMouseUp)
                this.$refs.hScroll.classList.add('focus')
            }
        },
        sbMouseUp() {
            window.removeEventListener('mousemove', this.sbMouseMove)
            window.removeEventListener('mouseup', this.sbMouseUp)
            this.lazy(() => this.$refs.hScroll.classList.remove('focus'))
            this.hScroller.mouseX = 0
            this.$forceUpdate()
        },
        sbMouseMove(e) {
            if (e.buttons === 0)
                this.sbMouseUp()
            else {
                const diff = e.clientX - this.hScroller.mouseX
                const ratio = (this.hScroller.left + diff) / this.hScroller.scrollerUnseenWidth
                this.tableContent.scrollTo(this.hScroller.tableUnseenWidth * ratio, this.tableContent.scrollTop)
            }
        },

        /* *** Window Event *******************************************************************************************
         */
        tableScroll() {
            this.showDatePicker = false
            this.autocompleteInputs = []
            if (this.focused && this.currentField)
                this.inputSquare.style.marginLeft =
                    (this.currentField.sticky ? this.tableContent.scrollLeft - this.squareSavedLeft : 0) + 'px'

            if (this.tableContent.scrollTop !== this.vScroller.lastTop) {
                this.calVScroll()
                if (this.$refs.vScrollButton) {
                    this.$refs.vScrollButton.classList.add('focus')
                    this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                }
            }
            this.vScroller.lastTop = this.tableContent.scrollTop

            if (this.tableContent.scrollLeft !== this.hScroller.lastLeft) {
                if (this.$refs.hScroll && this.hScroller.tableUnseenWidth) {
                    this.$refs.hScroll.classList.add('focus')
                    this.lazy(() => this.$refs.hScroll.classList.remove('focus'), 1000)
                    const ratio = this.tableContent.scrollLeft / this.hScroller.tableUnseenWidth
                    this.$refs.hScroll.style.left = (this.hScroller.scrollerUnseenWidth * ratio) + 'px'
                }
            }
            this.hScroller.lastLeft = this.tableContent.scrollLeft
        },
        winScroll() {
            this.showDatePicker = false
            this.autocompleteInputs = []
        },
        winResize() {
            this.lazy(this.refreshPageSize, 200)
        },
        winPaste(e) {
            if (e.target.tagName !== 'TEXTAREA') return
            if (!this.mousein && !this.focused) return
            if (!this.currentField || this.currentField.readonly(this.currentRow)) return
            if (this.inputBoxShow) {
                this.inputBoxChanged = true
                return
            }
            const text = (e.originalEvent || e).clipboardData.getData('text/plain')
            e.preventDefault()
            if (this.textLooksLikeTable(text)) {
                this.doTablePaste(text)
            } else {
                this.inputCellWrite(text)
            }
        },
        doTablePaste(text) {
            //convert to row
            var rows = text.trim().split((/[\n\u0085\u2028\u2029]|\r\n?/g)).map(function (row) {
                return row.split("\t")
            })
            const keyStart = new Date().getTime()
            rows.forEach((line, i) => {
                let rowPos = this.currentRowPos + i  //行起始位
                let colPos = this.currentColPos  //列起始位 

                let rec = {
                    $id: typeof line.$id === 'undefined' ? keyStart + '-' + i : line.$id
                }
                line.forEach((val, j) => {
                    colPos = colPos + j
                    if (colPos > this.fields.length - 1) { return }
                    let field = this.fields[colPos]
                    if (field.type == 'select') { return }
                    if (field.readonly(this.table[rowPos] || rec)) { return }
                    if (field.validate) {
                        let err
                        if ((err = field.validate(val))) {
                            return
                            //   throw new Error(`VueExcelEditor: [row=${i + 1}] ` + this.localizedLabel.columnHasValidationError(field.name, err))
                        }
                    }
                    if (val !== null) rec[field.name] = val
                })

                if (this.table.length > rowPos) {  //update 
                } else {   //add 
                    if (this.autoAddRow) {
                        this.autoAddRow(this.value.length == 0 ? null : this.value[this.value.length - 1])
                        rowPos = this.table.length - 1
                    } else {
                        let lastRow = this.table[this.table.length - 1]
                        //最后一行的keyfield出现空值则移除最后一行
                        let keyFieldNoneValue = this.fields.filter(t => t.keyField).filter(t => !lastRow[t.name])
                        if (keyFieldNoneValue.length > 0) {
                            this.table.splice(this.table.length - 1)
                        }
                        rowPos = this.table.push(rec) - 1
                    }
                }
                Object.keys(rec).forEach(name => {
                    if (name.startsWith('$')) return
                    console.log(rowPos)
                    this.updateCellByName(rowPos, name, rec[name])
                })
            });
        },
        textLooksLikeTable(text) {
            var rows = text.split((/[\n\u0085\u2028\u2029]|\r\n?/g))
            //复制的单元格最后一行应该是空字符串
            if (rows.length > 1 && rows[rows.length - 1] === '') {
                return true
            }
            return false
        },
        winKeydown(e) {
            if (!this.mousein && !this.focused) return
            if (e.ctrlKey || e.metaKey)
                switch (e.keyCode) {
                    //INFO: undo在有逻辑的单元格中会有冲突的， 只能屏蔽
                    // case 90: // z
                    //     this.undoTransaction()
                    //     e.preventDefault()
                    //     break
                    case 65: // a
                        this.toggleSelectAllRecords()
                        e.preventDefault()
                        break
                    case 67: // c
                        this.inputBox.value = this.currentCell.innerText
                        this.inputBox.focus()
                        this.inputBox.select()
                        document.execCommand('copy')
                        e.preventDefault()
                        break
                    case 70: // f
                        if (!this.noFinding) {
                            this.$refs.panelFind.showPanel()
                            e.preventDefault()
                        }
                        break
                    case 71: // g
                        if (!this.noFindingNext && this.inputFind !== '') {
                            this.doFindNext()
                            e.preventDefault()
                        }
                        break
                    case 76: // l
                        e.preventDefault()
                        if (this.currentField.readonly(this.currentRow)) {
                            return;
                        }
                        if (this.currentField.type === 'date') {
                            this.showDatePickerDiv()
                        } else if (this.currentField.type == 'custom') {
                            this.currentField.emitEvent('customclick', {
                                row: this.currentRow, cellWriter: (setText) => {
                                    this.inputCellWrite(setText)
                                    this.inputBoxShow = 0
                                    this.inputBoxChanged = false
                                    this.moveTo(this.currentRowPos, this.currentColPos)
                                }
                            })
                        } else {
                            this.calAutocompleteList(true)
                        }
                        break;
                    case 8:
                    case 46:
                        e.preventDefault()
                        if (this.currentRow) {
                            this.removeRow();
                        }
                        break;
                }
            else if (e.altKey) {
                if (e.keyCode == 8 || e.keyCode == 46) {
                    e.preventDefault()
                    if (this.currentRow) {
                        this.removeRow();
                    }
                }
            }
            else {
                if (this.currentRowPos < 0) return
                switch (e.keyCode) {
                    case 37:  // Left Arrow
                        if (!this.focused) return
                        if (!this.inputBoxShow) {
                            this.moveWest(e)
                            e.preventDefault()
                        }
                        else {
                            if (this.inputBox.selectionStart === 0) {
                                this.moveWest(e)
                                e.preventDefault()
                            }
                        }
                        break
                    case 38:  // Up Arrow
                        if (!this.focused) return
                        e.preventDefault()
                        if (this.autocompleteInputs.length === 0)
                            this.moveNorth()
                        else
                            if (this.autocompleteSelect > 0)
                                this.autocompleteSelect--
                            else
                                if (this.autocompleteSelect === -1)
                                    this.autocompleteSelect = this.autocompleteInputs.length - 1
                        break
                    case 9:  // Tab
                    case 39: // Right Arrow
                        if (!this.focused) return
                        if (!this.inputBoxShow) {
                            this.moveEast(e)
                            e.preventDefault()
                        }
                        else {
                            if (this.inputBox.selectionEnd === this.inputBox.value.length) {
                                this.moveEast(e)
                                e.preventDefault()
                            }
                        }
                        break
                    case 40:  // Down Arrow
                        if (!this.focused) return
                        e.preventDefault()
                        if (this.autocompleteInputs.length === 0)
                            this.moveSouth(e)
                        else
                            if (this.autocompleteSelect < this.autocompleteInputs.length - 1) this.autocompleteSelect++
                        break
                    case 13:  // Enter
                        if (!this.focused) return
                        e.preventDefault()
                        if (this.autocompleteInputs.length === 0 || this.autocompleteSelect === -1) {
                            if (!this.moveSouth(e)) {
                                if (this.inputBoxChanged) {
                                    this.inputCellWrite(this.inputBox.value)
                                    this.inputBoxChanged = false
                                }
                                this.inputBoxShow = 0
                                this.showDatePicker = false
                                this.autocompleteInputs = []
                                this.autocompleteSelect = -1
                            }
                            return
                        }
                        else
                            if (this.autocompleteSelect !== -1)
                                this.inputAutocompleteText(this.autocompleteInputs[this.autocompleteSelect])
                        break
                    case 27:  // Esc
                        if (!this.focused) return
                        this.showDatePicker = false
                        this.autocompleteInputs = []
                        this.autocompleteSelect = -1
                        if (this.inputBoxShow) {
                            e.preventDefault()
                            this.inputBox.value = this.currentCell.innerText
                            this.inputBoxShow = 0
                            this.inputBoxChanged = false
                        }
                        break
                    case 33:  // Page Up
                        if (!this.focused) return
                        this.prevPage()
                        e.preventDefault()
                        break
                    case 34:  // Page Down
                        if (!this.focused) return
                        this.nextPage()
                        e.preventDefault()
                        break
                    case 35: //End
                        if (!this.focused) return
                        this.moveTo(this.currentRowPos, this.fields.length - 1)
                        e.preventDefault()
                        break
                    case 36: //Home
                        if (!this.focused) return
                        this.moveTo(this.currentRowPos, 0)
                        e.preventDefault()
                        break
                    case 8:   // Delete
                    case 46:  // BS
                        if (!this.focused) return
                        if (this.inputBoxShow) {
                            this.inputBoxChanged = true
                            setTimeout(this.calAutocompleteList)
                            return
                        }
                        if (this.currentField.readonly(this.currentRow)) return
                        if (this.autocompleteInputs.length) return
                        if (this.currentField.type === 'select') this.calAutocompleteList(true)
                        else {
                            this.inputBox.value = ''
                            this.inputCellWrite('')
                        }
                        break
                    default:
                        if (!this.focused) return
                        if (this.currentField.readonly(this.currentRow)) return
                        if (e.altKey) return
                        if (e.key !== 'Process' && e.key.length > 1) return
                        if (this.currentField.allowKeys && this.currentField.allowKeys.indexOf(e.key.toUpperCase()) === -1) return e.preventDefault()
                        if (this.currentField.lengthLimit && this.inputBox.value.length > this.currentField.lengthLimit) return e.preventDefault()
                        if (this.currentField.readonly(this.currentRow)) {
                            return;
                        }
                        if (!this.inputBoxShow) {
                            if (this.currentField.type === 'select') {
                                this.calAutocompleteList(true)
                                return
                            }
                            if (this.currentField.type === 'date') {
                                this.showDatePickerDiv()
                                return
                            }
                            this.inputBox.value = ''
                            this.inputBoxShow = 1
                            this.inputBox.focus()
                        }
                        this.inputBoxChanged = true
                        setTimeout(this.calAutocompleteList)
                        break
                }
            }
        },

        /* *** Column Separator *******************************************************************************************
         */
        colSepMouseDown(e) {
            e.preventDefault()
            e.stopPropagation()
            this.focused = false
            const getStyleVal = (elm, css) => {
                window.getComputedStyle(elm, null).getPropertyValue(css)
            }
            this.sep = {}
            this.sep.curCol = this.colgroupTr.children[Array.from(this.labelTr.children).indexOf(e.target.parentElement)]
            // this.sep.nxtCol = this.sep.curCol.nextElementSibling
            this.sep.pageX = e.pageX
            let padding = 0
            if (getStyleVal(this.sep.curCol, 'box-sizing') !== 'border-box') {
                const padLeft = getStyleVal(this.sep.curCol, 'padding-left')
                const padRight = getStyleVal(this.sep.curCol, 'padding-right')
                if (padLeft && padRight)
                    padding = parseInt(padLeft) + parseInt(padRight)
            }
            this.sep.curColWidth = e.target.parentElement.offsetWidth - padding
            // if (this.sep.nxtCol)
            //   this.sep.nxtColWidth = this.sep.nxtCol.offsetWidth - padding
            window.addEventListener('mousemove', this.colSepMouseMove)
            window.addEventListener('mouseup', this.colSepMouseUp)
        },
        colSepMouseOver(e) {
            e.target.style.borderRight = '5px solid #cccccc'
            e.target.style.height = this.systable.getBoundingClientRect().height + 'px'
        },
        colSepMouseOut(e) {
            e.target.style.borderRight = ''
            e.target.style.height = '100%'
        },
        colSepMouseMove(e) {
            if (!this.sep || !this.sep.curCol) return
            const diffX = e.pageX - this.sep.pageX
            this.sep.curCol.style.width = (this.sep.curColWidth + diffX) + 'px'
            this.lazy(this.calStickyLeft, 200)
        },
        colSepMouseUp(e) {
            e.preventDefault()
            e.stopPropagation()
            delete this.sep
            window.removeEventListener('mousemove', this.colSepMouseMove)
            window.removeEventListener('mouseup', this.colSepMouseUp)
            const setting = this.getSetting()
            if (this.remember) localStorage[window.location.pathname + '.' + this.token] = JSON.stringify(setting)
            this.$emit('setting', setting)
            this.$emit('columnResize', setting)
        },

        /* *** Finder *******************************************************************************************
         */
        doFindNext() {
            return this.doFind()
        },
        doFind(s) {
            if (typeof s === 'undefined') s = this.inputFind
            else this.inputFind = s
            s = s.toUpperCase()
            const row = Math.max(0, this.currentRowPos)
            for (let r = row + this.pageTop; r < this.table.length; r++) {
                const rec = this.table[r]
                for (let c = (r === row + this.pageTop ? this.currentColPos + 1 : 0); c < this.fields.length; c++) {
                    const field = this.fields[c].name
                    if (typeof rec[field] !== 'undefined' && String(rec[field]).toUpperCase().indexOf(s) >= 0) {
                        this.pageTop = this.findPageTop(r)
                        setTimeout(() => {
                            this.moveInputSquare(r - this.pageTop, c)
                            setTimeout(() => this.inputBox.focus())
                            this.focused = true
                        })
                        return true
                    }
                }
            }
            for (let r = 0; r <= row + this.pageTop; r++) {
                const rec = this.table[r]
                for (let c = 0; c < (r === row + this.pageTop ? this.currentColPos : this.fields.length); c++) {
                    const field = this.fields[c].name
                    if (typeof rec[field] !== 'undefined' && String(rec[field]).toUpperCase().indexOf(s) >= 0) {
                        this.pageTop = this.findPageTop(r)
                        this.moveInputSquare(r - this.pageTop, c)
                        setTimeout(() => {
                            this.focused = true
                        })
                        return true
                    }
                }
            }
            return false
        },
        findPageTop(rowPos) {
            for (let pt = this.pageTop; pt < this.table.length; pt += this.pageSize)
                if (rowPos >= pt && rowPos < pt + this.pageSize) return pt
            for (let pt = this.pageTop; pt > 0; pt -= this.pageSize)
                if (rowPos >= pt && rowPos < pt + this.pageSize) return pt
            return this.pageTop
        },

        /* *** Sort *******************************************************************************************
         */
        headerClick(e, colPos) {
            if (e.which === 1) {
                e.preventDefault()

                let field = this.fields[colPos];

                if (!field.sortable || !this.sortable) {
                    return;
                }

                if (this.sortPos === colPos && this.sortDir > 0)
                    this.sort(-1, colPos)
                else
                    this.sort(1, colPos)
            }
        },
        sort(n, pos) {
            this.processing = true
            const colPos = typeof pos === 'undefined' ? this.columnFilterRef.colPos : pos
            const fieldName = this.fields[colPos].name
            const type = this.fields[colPos].type
            setTimeout(() => {
                if (type === 'number')
                    this.value.sort((a, b) => {
                        if (Number(a[fieldName]) > Number(b[fieldName])) return n
                        if (Number(a[fieldName]) < Number(b[fieldName])) return -n
                        return 0
                    })
                else
                    this.value.sort((a, b) => {
                        if (a[fieldName] > b[fieldName]) return n
                        if (a[fieldName] < b[fieldName]) return -n
                        return 0
                    })
                this.sortPos = colPos
                this.sortDir = n
                this.$forceUpdate()
                this.processing = false
            }, 0)
        },

        /* *** Paging *******************************************************************************************
         */
        refreshPageSize() {
            if (this.$refs.hScroll) {
                const fullWidth = this.systable.getBoundingClientRect().width
                const viewWidth = this.tableContent.getBoundingClientRect().width
                this.hScroller.tableUnseenWidth = fullWidth - viewWidth
                this.$refs.hScroll.style.width = (100 * viewWidth / fullWidth) + '%'
                this.$refs.hScroll.style.marginLeft = (this.autoAddRow ? 40 : 80) + 'px'
                const scrollerWidth = this.$refs.hScroll.getBoundingClientRect().width
                this.hScroller.scrollerUnseenWidth = this.footer.getBoundingClientRect().width - 40 - scrollerWidth
            }
            if (!this.noPaging) {
                const offset = this.summaryRow ? 60 : 35
                this.pageSize = this.page || Math.floor((window.innerHeight - this.recordBody.getBoundingClientRect().top - offset) / 24)
            }
            else if (this.height === 'auto') {
                let h = Math.floor((window.innerHeight - this.tableContent.getBoundingClientRect().top - 25))
                let offset = 4
                if (this.filterRow) offset += 29
                if (this.summaryRow) offset += 25
                if (!this.footerRow) offset += 25
                h = Math.min(24 * (this.table.length - this.pageTop) + offset, h)
                this.systable.parentNode.style.height = h + 'px'
            }
            setTimeout(this.calVScroll)
        },
        firstPage(e) {
            if (e) e.stopPropagation()
            this.pageTop = 0
            this.calVScroll()
            if (this.$refs.vScrollButton) {
                setTimeout(() => {
                    this.$refs.vScrollButton.classList.add('focus')
                    this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                })
            }
        },
        lastPage(e) {
            if (e) e.stopPropagation()
            this.pageTop = this.table.length - this.pageSize < 0 ? 0 : this.table.length - this.pageSize
            this.calVScroll()
            if (this.$refs.vScrollButton) {
                setTimeout(() => {
                    this.$refs.vScrollButton.classList.add('focus')
                    this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                })
            }
        },
        prevPage(e) {
            if (e) e.stopPropagation()
            this.pageTop = this.pageTop < this.pageSize ? 0 : this.pageTop - this.pageSize
            this.calVScroll()
            if (this.$refs.vScrollButton) {
                setTimeout(() => {
                    this.$refs.vScrollButton.classList.add('focus')
                    this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                })
            }
        },
        nextPage(e) {
            if (e) e.stopPropagation()
            if (this.pageTop + this.pageSize < this.table.length)
                this.pageTop = Math.min(this.pageTop + this.pageSize, this.table.length - this.pageSize)
            this.calVScroll()
            if (this.$refs.vScrollButton) {
                setTimeout(() => {
                    this.$refs.vScrollButton.classList.add('focus')
                    this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                })
            }
        },

        /* *** Setting *******************************************************************************************
         */
        getSetting() {
            const colWidth = Array.from(this.colgroupTr.children).map(col => col.style.width)
            const fields = this.fields.map((field, i) => {
                return {
                    name: field.name,
                    invisible: field.invisible,
                    width: colWidth[i + 1]
                }
            })
            return {
                colHash: this.colHash,
                fields: fields
            }
        },
        settingClick() {
            // this.$refs.panelSetting.showPanel()
        },
        importTable(cb) {
            this.$refs.importFile.click()
            this.importCallback = cb
        },
        doImport(e) {
            this.processing = true
            this.refresh()
            setTimeout(() => {
                const files = e.target.files
                if (!files || files.length === 0) return
                const file = files[0]

                const fileReader = new FileReader()
                fileReader.onload = (e) => {
                    try {
                        const data = e.target.result
                        const wb = XLSX.read(data, { type: 'binary', cellDates: true, cellStyle: false })
                        const sheet = wb.SheetNames[0]
                        let importData = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheet])
                        importData = importData.map((rec) => {
                            Object.keys(rec).forEach(k => {
                                if (typeof rec[k] === 'string') rec[k] = rec[k].replace(/[ \r\n\t]+$/g, '')
                            })
                            return rec
                        })
                        const keyStart = new Date().getTime()
                        if (importData.length === 0) throw new Error('VueExcelEditor: ' + this.localizedLabel.noRecordIsRead)
                        if (this.fields
                            .filter(f => f.keyField)
                            .filter(f => typeof importData[0][f.name] === 'undefined' && typeof importData[0][f.label] === 'undefined').length > 0)
                            throw new Error(`VueExcelEditor: ${this.localizedLabel.missingKeyColumn}`)

                        let pass = 0
                        let inserted = 0
                        let updated = 0
                        while (pass < 2) {
                            importData.forEach((line, i) => {
                                let rowPos = this.table.findIndex(v => {
                                    return this.fields
                                        .filter(f => f.keyField)
                                        .filter(f => v[f.name] !== line[f.name] && v[f.name] !== line[f.label]).length === 0
                                })
                                let rec = {
                                    $id: typeof line.$id === 'undefined' ? keyStart + '-' + i : line.$id
                                }

                                this.fields.forEach((field) => {
                                    if (field.name.startsWith('$')) return
                                    if (field.type == 'select') return

                                    let val = line[field.name]

                                    if (typeof val === 'undefined') val = line[field.label]
                                    if (typeof val === 'undefined') val = null
                                    else {
                                        if (field.keyField && !val) { return }
                                        if (field.readonly(this.table[rowPos] || rec)) throw new Error(`VueExcelEditor: [row=${i + 1}] ` + this.localizedLabel.readonlyColumnDetected + ': ' + field.name)
                                        if (field.validate) {
                                            let err
                                            if ((err = field.validate(val)))
                                                throw new Error(`VueExcelEditor: [row=${i + 1}] ` + this.localizedLabel.columnHasValidationError(field.name, err))
                                        }
                                    }
                                    if (val !== null) rec[field.name] = val
                                })
                                if (pass === 1) {
                                    if (rowPos >= 0) {
                                        updated++
                                    }
                                    else {
                                        console.log('add')
                                        if (this.autoAddRow) {
                                            this.autoAddRow(this.value.length == 0 ? null : this.value[this.value.length - 1])
                                            rowPos = this.table.length - 1
                                        } else {
                                            let lastRow = this.table[this.table.length - 1]
                                            //最后一行的keyfield出现空值则移除最后一行
                                            let keyFieldNoneValue = this.fields.filter(t => t.keyField).filter(t => !lastRow[t.name])
                                            if (keyFieldNoneValue.length > 0) {
                                                this.table.splice(this.table.length - 1)
                                            }
                                            rowPos = this.table.push(rec) - 1
                                        }
                                        inserted++
                                    }
                                    Object.keys(rec).forEach(name => {
                                        if (name.startsWith('$')) return
                                        console.log(rowPos)
                                        this.updateCellByName(rowPos, name, rec[name])
                                    })
                                }
                            })
                            pass++
                        }
                        if (pass === 2 && this.importCallback) {
                            this.importCallback({
                                inserted: inserted,
                                updated: updated,
                                recordAffected: inserted + updated
                            })
                        }
                    }
                    catch (e) {
                        throw new Error('VueExcelEditor: ' + e.stack)
                    }
                    finally {
                        this.processing = false
                        this.$refs.importFile.value = ''
                    }
                }
                fileReader.onerror = (e) => {
                    this.processing = false
                    this.$refs.importFile.value = ''
                    throw new Error('VueExcelEditor: ' + e.stack)
                }
                fileReader.readAsBinaryString(file)
            }, 500)
        },
        exportTable(format, selectedOnly, filename) {
            this.processing = true
            setTimeout(() => {
                const wb = XLSX.utils.book_new()
                let ws1 = null
                let data = this.table
                if (selectedOnly)
                    data = this.table.filter((rec, i) => this.selected[i])
                const mapped = data.map(rec => {
                    const conv = {}
                    this.fields.forEach(field => conv[field.name] = rec[field.name])
                    return conv
                })
                ws1 = XLSX.utils.json_to_sheet(mapped, {
                    header: this.fields.map(field => field.name)
                })
                const labels = Array.from(this.labelTr.children).slice(1).map(t => t.children[0].innerText)
                XLSX.utils.sheet_add_aoa(ws1, [labels], { origin: 0 })
                ws1['!cols'] = Array.from(this.labelTr.children).slice(1).map((t) => {
                    return {
                        width: t.getBoundingClientRect().width / 6.5
                    }
                })
                XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1')
                filename = filename || 'export'
                switch (format) {
                    case 'csv':
                        if (!filename.endsWith('.csv')) filename = filename + '.csv'
                        break
                    case 'xls':
                        if (!filename.endsWith('.xls')) filename = filename + '.xls'
                        break
                    case 'xlsx':
                    case 'excel':
                    default:
                        if (!filename.endsWith('.xlsx')) filename = filename + '.xlsx'
                        break
                }
                XLSX.writeFile(wb, filename, {
                    compression: 'DEFLATE',
                    compressionOptions: {
                        level: 6
                    }
                })
                this.processing = false
            }, 500)
        },

        /* *** Select *******************************************************************************************
         */
        getSelectedRecords() {
            return this.table.filter((rec, i) => this.selected[i])
        },
        deleteSelectedRecords() {
            this.table = this.table.filter((rec, i) => typeof this.selected[i] === 'undefined')
            this.selected = {}
            this.selectedCount = 0
        },
        rowLabelClick(e) {
            let target = e.target
            while (target.tagName !== 'TD') target = target.parentNode
            const rowPos = Number(target.getAttribute('pos')) + this.pageTop
            if (e.shiftKey) {
                document.getSelection().removeAllRanges()
                if (this.prevSelect !== -1 && this.prevSelect !== rowPos) {
                    e.preventDefault()
                    if (rowPos > this.prevSelect)
                        for (let i = this.prevSelect; i <= rowPos; i++)
                            this.selectRecord(i)
                    else
                        for (let i = rowPos; i <= this.prevSelect; i++)
                            this.selectRecord(i)
                }
            }
            else {
                const selected = this.selected[rowPos]
                if (!this.freeSelect && !(e.ctrlKey || e.metaKey)) this.clearAllSelected()
                if (!selected) this.selectRecord(rowPos)
                else this.unSelectRecord(rowPos)
            }
            this.prevSelect = rowPos
        },
        selectAllClick() {
            this.toggleSelectAllRecords()
        },
        reviseSelectedAfterTableChange() {
            this.rowIndex = {}
            this.table.forEach((rec, i) => (this.rowIndex[rec.$id] = i))
            const temp = Object.assign(this.selected)
            this.selected = {}
            Object.keys(temp).forEach((p) => {
                const id = temp[p]
                if (typeof this.rowIndex[id] !== 'undefined')
                    this.selected[this.rowIndex[id]] = id
            })
            this.selectedCount = Object.keys(this.selected).length
        },
        toggleSelectRecord(rowPos) {
            if (typeof this.selected[rowPos] !== 'undefined') this.unSelectRecord(rowPos)
            else this.selectRecord(rowPos)
        },
        selectRecord(rowPos) {
            if (typeof this.selected[rowPos] === 'undefined') {
                this.selectedCount++
                this.selected[rowPos] = this.table[rowPos].$id
                if (this.recordBody.children[rowPos - this.pageTop])
                    this.recordBody.children[rowPos - this.pageTop].classList.add('select')
                this.lazy(rowPos, (buf) => {
                    this.$emit('select', buf, true)
                })
            }
        },
        unSelectRecord(rowPos) {
            if (typeof this.selected[rowPos] !== 'undefined') {
                delete this.selected[rowPos]
                this.selectedCount--
                if (this.recordBody.children[rowPos - this.pageTop])
                    this.recordBody.children[rowPos - this.pageTop].classList.remove('select')
                this.lazy(rowPos, (buf) => {
                    this.$emit('select', buf, false)
                })
            }
        },
        toggleSelectAllRecords(e) {
            if (e) e.preventDefault()
            if (this.selectedCount > 0)
                this.clearAllSelected()
            else {
                for (let i = 0; i < this.table.length; i++)
                    this.selectRecord(i)
                this.selectedCount = this.table.length
            }
        },
        clearAllSelected() {
            // for (let i = 0; i < this.$refs.systable.children[2].children.length; i++)
            //  this.unSelectRecord(this.pageTop + i)
            if (this.selectedCount > 0)
                this.$emit('select', Object.keys(this.selected).map(rowPos => Number(rowPos)), false)
            this.selected = {}
            this.selectedCount = 0
        },

        /* *** Cursor *******************************************************************************************
         */
        moveTo(rowPos, colPos) {
            colPos = colPos || 0
            this.moveInputSquare(rowPos, colPos)
        },
        moveWest() {
            if (this.focused) {
                if (this.currentColPos > 0) {
                    let goColPos = this.currentColPos - 1
                    while (this.fields[goColPos].invisible && goColPos >= 0) goColPos--
                    if (goColPos === -1) return
                    this.moveInputSquare(this.currentRowPos, goColPos)
                }
            }
        },
        moveEast() {
            if (this.focused) {
                if (this.currentColPos < this.fields.length - 1) {
                    let goColPos = this.currentColPos + 1
                    while (this.fields[goColPos].invisible && goColPos < this.fields.length - 1) goColPos++
                    if (goColPos === this.fields.length) return
                    this.moveInputSquare(this.currentRowPos, goColPos)
                } else {
                    if (this.table.length > this.currentRowPos + 1) {
                        this.moveInputSquare(this.currentRowPos + 1, 0)
                    }
                }
            }
        },
        moveNorth() {
            if (this.focused) {
                this.moveInputSquare(this.currentRowPos - 1, this.currentColPos)
                this.calVScroll()
                if (this.$refs.vScrollButton) {
                    setTimeout(() => {
                        this.$refs.vScrollButton.classList.add('focus')
                        this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                    })
                }
            }
        },
        moveSouth() {
            if (this.focused && this.currentRowPos < this.table.length) {
                const done = this.moveInputSquare(this.currentRowPos + 1, this.currentColPos)
                this.calVScroll()
                if (this.$refs.vScrollButton) {
                    setTimeout(() => {
                        this.$refs.vScrollButton.classList.add('focus')
                        this.lazy(() => this.$refs.vScrollButton.classList.remove('focus'), 1000)
                    })
                }
                return done
            }
            return false
        },
        mouseDown(e) {
            let target = e.target
            if (target.tagName === 'SPAN') {
                target = e.target.parentNode
            }
            if ((target.parentNode.parentNode.tagName === 'TBODY') && !target.classList.contains('first-col')) {
                e.preventDefault()
                setTimeout(() => this.inputBox.focus())
                this.focused = true
                const row = target.parentNode
                const colPos = Array.from(row.children).indexOf(target) - 1
                const rowPos = Array.from(row.parentNode.children).indexOf(row)
                this.moveInputSquare(rowPos, colPos)
                if (target.offsetWidth - e.offsetX > 15) return
                if (this.currentField.readonly(this.currentRow)) {
                    return;
                }
                if (target.classList.contains('select')) this.calAutocompleteList(true)
                if (target.classList.contains('datepick')) this.showDatePickerDiv()
            }
        },
        cellMouseMove(e) {
            let cursor = 'cell'
            if (this.inputBoxShow) cursor = 'default'
            if (!e.target.classList.contains('readonly')
                && (e.target.classList.contains('select') || e.target.classList.contains('datepick'))
                && e.target.offsetWidth - e.offsetX < 15)
                cursor = 'pointer'
            e.target.style.cursor = cursor
        },
        cellMouseOver(e) {
            const cell = e.target
            if (!cell.classList.contains('error')) return
            if (this.tipTimeout) clearTimeout(this.tipTimeout)

            let err = this.errmsg[cell.getAttribute('id')];
            if (err) {
                this.tip = err.err
            } else {
                this.tip = ''
            }

            if (this.tip === '') return
            const rect = cell.getBoundingClientRect()
            this.$refs.tooltip.style.top = (rect.top - 14) + 'px';
            this.$refs.tooltip.style.left = (rect.right + 8) + 'px'
            cell.addEventListener('mouseout', this.cellMouseOut)
        },
        cellMouseOut(e) {
            this.tipTimeout = setTimeout(() => {
                this.tip = ''
            }, 1000)
            e.target.removeEventListener(e.type, this.cellMouseOut)
        },
        mouseOver() {
            this.mousein = true
        },
        mouseOut() {
            this.mousein = false
        },

        /* *** InputBox *****************************************************************************************
         */
        moveInputSquare(rowPos, colPos) {
            if (colPos < 0) return false

            const row = this.recordBody.children[rowPos]
            if (!row) {
                if (rowPos > this.currentRowPos) {
                    // move the whole page down 1 record
                    if (this.pageTop + this.pageSize < this.table.length)
                        this.pageTop += 1
                    return false
                }
                else {
                    // move the whole page up 1 record
                    if (this.pageTop - 1 >= 0)
                        this.pageTop -= 1
                    return false
                }
            }

            // Clear the label markers
            this.labelTr.children[this.currentColPos + 1].classList.remove('focus')
            if (this.currentRowPos >= 0 && this.currentRowPos < this.pagingTable.length)
                this.recordBody.children[this.currentRowPos].children[0].classList.remove('focus')

            // Off the textarea when moving, write to value if changed
            if (this.inputBoxShow) this.inputBoxShow = 0
            if (this.inputBoxChanged) {
                this.inputCellWrite(this.currentField.toValue(this.inputBox.value))
                this.inputBoxChanged = false
            }


            // Relocate the inputSquare
            const cell = row.children[colPos + 1]
            if (!cell) return false
            this.currentField = this.fields[colPos]
            const cellRect = cell.getBoundingClientRect()
            const tableRect = this.systable.getBoundingClientRect()
            this.squareSavedLeft = this.tableContent.scrollLeft
            this.inputSquare.style.marginLeft = 0
            this.inputSquare.style.left = (cellRect.left - tableRect.left - 1) + 'px'
            this.inputSquare.style.top = (cellRect.top - tableRect.top - 1) + 'px'
            this.inputSquare.style.width = (cellRect.width + 1) + 'px'
            this.inputSquare.style.height = (cellRect.height + 1) + 'px'
            this.inputSquare.style.zIndex = this.currentField.sticky ? 3 : 1

            // Adjust the scrolling to display the whole focusing cell
            if (!this.currentField.sticky) {
                const boundRect = this.$el.getBoundingClientRect()
                if (cellRect.right >= boundRect.right - 12)
                    this.tableContent.scrollBy(cellRect.right - boundRect.right + 13, 0)
                if (cellRect.left <= boundRect.left + this.leftMost)
                    this.tableContent.scrollBy(cellRect.left - boundRect.left - this.leftMost - 1, 0)
            }

            this.currentRowPos = rowPos
            this.currentColPos = colPos
            this.currentCell = cell

            // Off all editors
            if (this.showDatePicker) this.showDatePicker = false
            if (this.autocompleteInputs.length) {
                this.autocompleteInputs = []
                this.autocompleteSelect = -1
            }
            if (this.recalAutoCompleteList) clearTimeout(this.recalAutoCompleteList)

            // set the label markers
            if (this.currentRowPos >= 0 && this.currentRowPos < this.pagingTable.length) {
                this.inputBox.focus()
                this.focused = true
                row.children[0].classList.add('focus')
                this.labelTr.children[colPos + 1].classList.add('focus')
            }
            return true
        },
        inputSquareClick() {
            if (!this.currentField.readonly(this.currentRow) && !this.inputBoxShow && this.currentField.type !== 'select') {
                this.inputBox.value = this.currentCell.innerText
                this.inputBoxShow = 1
                this.inputBox.focus()
                this.inputBoxChanged = false
                this.focused = true
            }
        },
        inputBoxMouseMove(e) {
            let cursor = 'text'
            if (!this.currentField.readonly(this.currentRow)
                && (this.currentField.options(this.currentRow).length || this.currentField.type === 'date')
                && e.target.offsetWidth - e.offsetX < 15)
                cursor = 'pointer'
            e.target.style.cursor = cursor
        },
        inputBoxMouseDown(e) {
            if (e.target.offsetWidth - e.offsetX > 15) return
            if (this.currentField.readonly(this.currentRow)) {
                return;
            }
            e.preventDefault()

            if (this.currentField.options(this.currentRow).length) {
                this.calAutocompleteList(true)
            }
            if (this.currentField.type === 'date') {
                this.showDatePickerDiv()
            } else if (this.currentField.type == 'custom') {
                this.currentField.emitEvent('customclick', {
                    row: this.currentRow, cellWriter: (setText) => {
                        this.inputCellWrite(setText)
                        this.inputBoxShow = 0
                        this.inputBoxChanged = false
                        this.moveTo(this.currentRowPos, this.currentColPos + 1)
                        this.moveEast();
                    }
                })
            }
        },
        inputCellWrite(setText, colPos, recPos) {
            let field = this.currentField
            if (typeof colPos !== 'undefined') field = this.fields[colPos]
            if (typeof recPos === 'undefined') recPos = this.pageTop + this.currentRowPos
            if (typeof this.selected[recPos] !== 'undefined')
                this.updateSelectedRows(field, setText)
            else
                this.updateCell(recPos, field, setText)
        },
        inputBoxBlur() {
            if ($('.el-picker-panel').length > 0 && $('.el-picker-panel')[0].querySelector(':hover')) return
            if (this.inputBoxChanged) {
                this.inputCellWrite(this.inputBox.value)
                this.inputBoxChanged = false
            }
            this.inputBoxShow = 0
            this.focused = false
            this.showDatePicker = false
            if (this.currentRowPos !== -1) {
                this.recordBody.children[this.currentRowPos].children[0].classList.remove('focus')
                this.labelTr.children[this.currentColPos + 1].classList.remove('focus')
            }
        },

        /* *** Update *******************************************************************************************
         */
        undoTransaction(e) {
            if (e) e.preventDefault()
            if (this.redo.length === 0) return
            const transaction = this.redo.pop()
            transaction.forEach((rec) => {
                this.updateCell(this.rowIndex[rec.$id], rec.field, rec.oldVal, true)
            })
        },
        updateCellByColPos(recPos, colPos, content) {
            return this.updateCell(recPos, this.fields[colPos], content)
        },
        updateCellByName(recPos, name, content) {
            return this.updateCell(recPos, this.fields.find(f => f.name === name), content)
        },

        updateCell(recPos, field, content, restore) {
            const tableRow = this.table[recPos]
            const oldVal = tableRow[field.name]
            const oldKeys = this.getKeys(tableRow)
            tableRow[field.name] = content

            if (field.change)
                field.change(recPos, field.name, content, tableRow, field, oldVal)

            setTimeout(() => {
                const transaction = {
                    $id: tableRow.$id,
                    keys: this.getKeys(tableRow),
                    row: tableRow,
                    rowIndex: recPos,
                    oldKeys: oldKeys,
                    name: field.name,
                    field: field,
                    oldVal: typeof oldVal !== 'undefined' ? oldVal : '',
                    newVal: content,
                    err: ''
                }

                const id = `id-${tableRow.$id}-${field.name}`
                if (field.validate !== null) transaction.err = field.validate(content)
                if (field.mandatory && content === '')
                    transaction.err += (transaction.err ? '\n' : '') + field.mandatory

                if (transaction.err !== '') {
                    this.errmsg[id] = { err: transaction.err, rowIndex: recPos }
                    this.systable.querySelector('td#' + id).classList.add('error')
                }
                else delete this.errmsg[id]

                this.lazy(transaction, (buf) => {
                    this.$emit('update', buf)
                    this.autoAdd(buf)
                    this.calSummary()
                    if (!restore) this.redo.push(buf)
                }, 50)
            })
        },
        //扩展autoAddRow
        autoAdd(buf) {
            if (!this.autoAddRow) {
                return;
            }
            console.log('autoadd')
            //是否是最后一行
            let lastBuff = buf.find(t => t.rowIndex == this.table.length - 1)

            if (lastBuff) {
                this.autoAddRow(lastBuff.row)
            }
        },
        updateSelectedRows(field, content) {
            this.processing = true
            setTimeout(() => {
                Object.keys(this.selected).forEach(recPos => this.updateCell(recPos, field, content))
                this.processing = false
            }, 0)
        },

        /* *** Autocomplete ****************************************************************************************
         */
        calAutocompleteList(force) {
            if (!force && !this.currentField.autocomplete) return
            if (force || (this.inputBoxChanged && this.inputBox.value.length > 0)) {
                if (typeof this.recalAutoCompleteList !== 'undefined') clearTimeout(this.recalAutoCompleteList)
                this.recalAutoCompleteList = setTimeout(() => {
                    if (!force) {
                        if (!this.focused || !this.inputBoxShow || !this.inputBoxChanged || !this.inputBox.value.length) {
                            this.autocompleteInputs = []
                            return
                        }
                    }
                    const field = this.currentField
                    const name = field.name
                    const value = force ? '' : this.inputBox.value
                    let list
                    let opts = field.options(this.currentRow)
                    if (opts.length > 0) {
                        list = opts
                    }
                    else {
                        list = []
                        for (let i = 0; i < this.table.length; i++) {
                            const rec = this.table[i]
                            if (typeof rec[name] !== 'undefined' && rec[name].startsWith(value) && list.indexOf(rec[name]) === -1)
                                list.push(rec[name])
                            if (list.length >= 10) break
                        }
                        list.sort()
                    }
                    this.autocompleteSelect = -1
                    this.autocompleteInputs = list
                    const rect = this.currentCell.getBoundingClientRect()
                    this.$refs.autocomplete.style.top = rect.bottom + 'px'
                    this.$refs.autocomplete.style.left = rect.left + 'px'
                    this.$refs.autocomplete.style.minWidth = rect.width + 'px'
                    this.lazy(() => {
                        const r = this.$refs.autocomplete.getBoundingClientRect()
                        if (r.bottom > window.innerHeight)
                            this.$refs.autocomplete.style.top = (rect.top - r.height) + 'px'
                        if (r.right > window.innerWidth)
                            this.$refs.autocomplete.style.top = (window.innerWidth - r.width) + 'px'
                    })
                }, force ? 0 : 700)
            }
        },
        inputAutocompleteText(item, e) {
            if (e) e.preventDefault()
            setTimeout(() => {
                let selOpts = this.currentField.selectOptions
                let text = selOpts ? item[selOpts.label] : item
                let value = selOpts ? item[selOpts.value] : item
                this.inputCellWrite(text)
                if (this.currentField.selectOptions.setValue) {
                    this.currentField.selectOptions.setValue(item, this.currentRow)
                }
                this.autocompleteInputs = []
                this.autocompleteSelect = -1
                this.inputBoxShow = 0
                this.inputBoxChanged = false
            })
        },

        /* *** Helper ****************************************************************************************
         */
        hashCode(s) {
            return s.split('').reduce((a, b) => {
                return a = ((a << 5) - a) + b.charCodeAt(0) | 0
            }, 0)
        },
        lazy(p, delay, p1) {
            if (typeof p !== 'function') return this.lazyBuf(p, delay, p1)
            if (!delay) delay = 20
            const hash = this.hashCode(p.name + p.toString())
            if (this.lazyTimeout[hash]) clearTimeout(this.lazyTimeout[hash])
            this.lazyTimeout[hash] = setTimeout(() => {
                p()
                delete this.lazyTimeout[hash]
            }, delay)
        },
        lazyBuf(item, p, delay) {
            if (!delay) delay = 20
            const hash = this.hashCode(p.name + p.toString())
            if (this.lazyBuffer[hash])
                this.lazyBuffer[hash].push(item)
            else
                this.lazyBuffer[hash] = [item]

            if (this.lazyTimeout[hash]) clearTimeout(this.lazyTimeout[hash])
            this.lazyTimeout[hash] = setTimeout(() => {
                p(this.lazyBuffer[hash])
                delete this.lazyTimeout[hash]
                delete this.lazyBuffer[hash]
            }, delay)
        }
    }
}