<template>
  <el-input class="ux-search-input" v-model="currentText" :readonly="!!currentValue" @blur="handleBlur"
    @keyup.enter.native="handleEnter" :class="[!!currentValue ? 'input-readonly' : '']" :disabled="disabled"
    v-loading="loading" ref="uxInput">
    <slot name="prepend" slot="prepend"></slot>
    <template v-if="!disabled">
      <i class="el-icon-circle-close el-input__icon" slot="suffix" v-if="currentText && clearable &&!currentValue"
        @click="handleClear"></i>
      <i class="el-icon-search el-input__icon" slot="suffix" @click="handleSearchClick"></i>
    </template>
  </el-input>
</template>

<script>
import UxDataDialog from "../UxDataDialog/index.vue";
import request from "@/utils/request";

export default {
  name: "ux-search-input",
  props: {
    textField: { default: "name" }, //显示字段，在mutiple中使用join连接
    valueField: { default: "id" }, //值字段 如果为空则绑定object ，在mutiple中返回数组
    value: {},
    text: {},
    disabled: { default: false },
    clearable: { default: true },
    tableConfig: {},
    title: {},
    searchApi: {},
    codeable: { default: false }, //新增编码
  },
  data() {
    return {
      loading: false,
      currentText: this.text,
      currentValue: this.value,
    };
  },
  watch: {
    text(n) {
      this.currentText = n;
    },
    value(n) {
      this.currentValue = n;
    },
  },
  methods: {
    //dialog类型点击搜索按钮
    handleSearchClick() {
      this.$dialog
        .open(UxDataDialog, { title: this.title, config: this.tableConfig })
        .then(this._handleRes);
    },
    //popover中选中事件
    handleSelect(res) {
      this.noData = false;
      this._handleRes(res);
    },
    _handleRes(res) {
      if (this.multiple) {
        this.currentText = res.map((x) => _.get(x, this.textField)).join();
        this.currentValue = this.valueField
          ? res.map((x) => _.get(x, this.valueField))
          : res;
      } else {
        this.currentText = _.get(res, this.textField);
        this.currentValue = this.valueField ? _.get(res, this.valueField) : res;
      }
      this._emitChange(res);
    },
    handleClear() {
      this.currentText = "";
      this.currentValue = null;
      this._emitChange(null);
    },
    handleBlur() {
      this._search();
    },
    handleEnter() {
      this._search();
    },
    _emitChange(data) {
      this.$emit("input", this.currentValue);
      this.$emit("update:text", this.currentText);
      this.$emit("change", data);
    },
    _search() {
      if (this.currentText && !this.currentValue) {
        this.loading = true;
        request({
          url: this.searchApi,
          method: "post",
          data: { code: this.currentText },
        })
          .then((res) => {
            if (res) {
              this.currentValue = res[this.valueField];
              this.currentText = res[this.textField];
            }
            this._emitChange(res);
          })
          .catch(() => {
            this.currentValue = null;
            if (!this.codeable) {
              // this.currentText = "";
            }
            this._emitChange({});
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  created() {},
};
</script>

<style lang="scss">
.pop-select-input {
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon-circle-close {
        float: left;
      }
    }
  }
}
</style>
