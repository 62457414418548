<template>
  <el-dialog :title="title" :visible.sync="visible" :center="center" :modal="true" :width="width" class="n-dialog-layout" :modal-append-to-body="true"
    :append-to-body="true" :lock-scroll="true" :show-close="showClose" :close-on-click-modal="false" :before-close="beforeClose" @opened="$emit('opened')"
    @close="handleClose" :fullscreen="fullscreen">
    <slot name="title" slot="title"></slot>
    <slot></slot>
    <slot name="footer" slot="footer"></slot>
  </el-dialog>
</template>
<script>
export default {
  name: "n-dialog-layout",
  props: {
    title: {},
    fullscreen: {
      default: false,
      type: Boolean,
    },
    width: {
      default: "50%",
      type: String,
    },
    showClose: {
      default: true,
      type: Boolean,
    },
    center: {
      default: false,
      type: Boolean,
    },
    beforeClose: {
      default: (done) => {
        done();
      },
      type: Function,
    },
  },
  data() {
    return {
      promise: null,
      resolve: null,
      reject: null,
      visible: false,
      confirmClose: false,
      result: {},
    };
  },
  methods: {
    open() {
      this.confirmClose = false;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
        this.visible = true;
      });
      return this.promise;
    },
    close(result) {
      this.confirmClose = true;
      this.result = result;
      this.visible = false;
    },
    cancel(arg) {
      this.confirmClose = false;
      this.result = arg;
      this.visible = false;
    },
    handleClose() {
      if (this.confirmClose) {
        this.resolve(this.result);
      } else {
        this.reject(this.result);
      }
    },
  },
};
</script>
<style lang="scss">
.n-dialog-layout {
  .el-dialog {
    .el-dialog__body {
      height: calc(100% - 56px);
    }
  }
}
</style>