 import VFrame from '@/framework/VFrame'
 export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        titleIcon: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        showBack: {
            type: Boolean,
            default: false
        },
        showBackConfirm: {
            type: Boolean,
            default: false
        },
        backUrl: {}
    },
    methods: {
        _handleBackClick() {
            if (this.backUrl) {
                this.$router.push(this.backUrl);
            } else {
                this.$router.go(-1);
            }
        },
        handleBackClick() {
            if (this.showBackConfirm) {
                this.$confirm(VFrame.l("IfYouConfirmYouMaybeLostYourUnSavedData"), VFrame.l("Tips")).then(() => {
                    this._handleBackClick();
                }).catch(() => { })
            } else {
                this._handleBackClick();
            }
        }
    }
}

