<template>
  <el-select :multiple="multiple" filterable
    v-model="currentValue" @change="handleChange"
    :clearable="true" :remote="isRemote"
    :placeholder="isRemote?l('PleaseEnterKeywordForSearch'):''"
    :collapse-tags="true"
    :remote-method="isRemote?fetch:null"
    :loading="loading">
    <el-option v-for="opt in options"
      :value="opt.value" :label="opt.text"
      :key="opt.value">{{opt.text}}</el-option>
  </el-select>
</template>

<script>
import { getSqlFilters } from "@/api/uxreport/query.js";
import ReportUtil from "@/components/Report/reportUtil.js";
export default {
  props: ["value", "dataSourceId", "multiple", "dataSourceSql", "isRemote"],
  data() {
    return {
      currentValue: this.value,
      options: [],
      loading: false,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  methods: {
    fetch(query, value) {
      this.loading = true;
      var sql = this.dataSourceSql;
      var rVarArray = ReportUtil.getVariables();
      for (var i = 0; i < rVarArray.length; i++) {
        sql = sql.replaceAll("#" + rVarArray[i].name + "#", rVarArray[i].value);
      }
      getSqlFilters({
        sql: sql,
        dataSourceId: this.dataSourceId,
        keyword: query,
        value: value,
      })
        .then((res) => {
          this.options = res.items;
          var defaultOne = this.options.find((t) => t.isDefault);
          if (defaultOne) {
            console.debug("default:" + JSON.stringify(defaultOne));
            this.$emit("input", defaultOne.value);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchByValue() {
      this.fetch("", this.currentValue);
    },
    handleChange() {
      console.log(this.currentValue);
      if (this.currentValue) {
        this.$emit("input", this.currentValue);
      } else {
        this.$emit("input", this.multiple ? [] : null);
      }
    },
  },
  created() {
    if (!this.isRemote) {
      this.fetch();
    } else if (this.currentValue) {
      this.fetchByValue();
    }
  },
};
</script>

<style>
</style>