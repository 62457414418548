<template>
  <span
    style="font-size:12px;margin-left:10px;height:100%;line-height:100%;">
    <span
      style="display:inline-block;width:12px;height:12px;"
      :style="{'background-color':notSkuBackgroundColor}"></span>
    <span>
      {{l('NotFoundSkuTips')}}</span>
  </span>
</template>

<script>
export default {
  name: "barcode-notfound",
  data() {
    return {
      notSkuBackgroundColor: this.CONSTANT.NOT_SKU_BACKGROUND_STYLE,
    };
  },
};
</script>

<style>
</style>