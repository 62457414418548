<template>
  <n-dialog-layout :title="l('Approval')"
    ref="dialog">
    <el-tabs v-model="activeTabsName"
      @tab-click="handleChange">
      <el-tab-pane :label="l('ApproveForm')"
        name="0">
        <template v-if="template">
          <el-form :size="formConf.size"
            :label-position="formConf.labelPosition"
            :model="formData" :rules="rules"
            :disabled="formConf.disabled||!allowApprove||!!adminMode"
            :label-width="formConf.labelWidth + 'px'"
            ref="form">
            <render-item
              v-for="(element, index) in drawingList"
              :key="element.renderKey"
              :drawing-list="drawingList"
              :element="element" :index="index"
              :form-conf="formConf"
              v-model="formData[element.vModel]" />
          </el-form>
        </template>
      </el-tab-pane>
      <el-tab-pane :label="l('Status')" name="1">
        <flow-card ref="flow" :data="flowConfig"
          :disabled="!allowApprove"
          :activeNodeId="instance.nodeId"
          v-if="instance">
        </flow-card>
      </el-tab-pane>
      <el-tab-pane :label="l('ApprovalHistory')"
        name="2" lazy>
        <el-collapse v-model="historyActiveName"
          accordion @change="handleHistoryChange">
          <el-collapse-item
            v-for="(item,index) in historyList"
            :key="index" :name="index">
            <template slot="title">
              <div style="width:100%">
                <span><i
                    :class="getNodeIcon(item)"></i>{{getNodeName(item) }}</span>
                <label
                  style="float: right; padding-right:10px; color:green;">{{$ux.format.dateTime(item.creationTime)}}
                </label>
              </div>
            </template>
            <div
              v-if="item.approvalFlowHistoryAssigneeList.length>0">
              <el-divider><i
                  class="el-icon-user"></i>
              </el-divider>
              <el-tag
                style="margin-right: 5px;cursor: pointer;"
                effect="plain"
                v-for="(user,index) in item.approvalFlowHistoryAssigneeList"
                :key="index"
                :type="user.approve ? 'success' : 'danger'">
                {{user.assigneeName}}
              </el-tag>

            </div>
            <div
              v-if="hiddenFormData(item.formData)">
              <el-divider><i
                  class="el-icon-s-order"></i>
              </el-divider>
              <template>
                <el-form :size="formConf.size"
                  :label-position="formConf.labelPosition"
                  :model="JSON.parse(item.formData)"
                  :rules="rules"
                  :disabled="formConf.disabled||!allowApprove||!!adminMode"
                  :label-width="formConf.labelWidth + 'px'"
                  ref="form">
                  <render-item
                    v-for="(element, index) in vhistoryDrawingList"
                    :key="element.renderKey"
                    :drawing-list="item.drawingList"
                    :element="element"
                    :index="index"
                    :form-conf="formConf"
                    v-model="historyFormData[element.vModel]" />
                </el-form>
              </template>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <template v-if="!adminMode">
        <el-button size="small" @click="cancel()">
          {{ l("Cancel") }}</el-button>
        <el-button size="small" type="danger"
          @click="ok(false)"
          v-if="instance&&instance.status=='PROCESSING'"
          :disabled="!allowApprove">
          {{ unProcess?l('RejectUnProcess'): l('Reject') }}
        </el-button>
        <el-button size="small" type="primary"
          @click="ok(true)"
          :disabled="!allowApprove">{{ text }}
        </el-button>
      </template>
      <template v-else>
        <el-button size="small" @click="cancel()">
          {{ l("Cancel") }}</el-button>
        <el-button size="small" type="danger"
          @click="adminOk(false)"
          v-if="instance&&instance.status=='PROCESSING'">
          {{ l('Delete') }}
        </el-button>
        <el-button size="small" type="primary"
          @click="adminOk(true)">
          {{ l('ApproveToEnd') }}</el-button>
      </template>

    </span>
  </n-dialog-layout>
</template>
<script>
// import { formConf } from "@/utils/generator/config";
let formConf = {
  formRef: "elForm",
  formModel: "formData",
  size: "medium",
  labelPosition: "top",
  labelWidth: 120,
  formRules: "rules",
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true,
};
import RenderItem from "@/components/FormBuilder/RenderItem.vue";
import FlowCard from "./Card/index.vue";
import { NodeUtils } from "./Card/util";
import { getHistory } from "@/api/uxretail/sys/approvalInstance.js";
export default {
  components: {
    RenderItem,
    FlowCard,
  },
  data() {
    return {
      activeTabsName: null,
      drawingList: null,
      formData: null,
      rules: {},
      template: null,
      flowConfig: null,
      formConf,
      text: "",
      allowApprove: false,
      unProcess: false,
      tips: "",
      instance: null,
      adminMode: false,
      historyList: [],
      historyDrawingList: [],
      historyFormData: null,
      isFetchHistory: false,
      historyActiveName: null,
    };
  },
  computed: {
    vhistoryDrawingList() {
      return this.historyDrawingList.filter(
        (t) => this.historyFormData[t.vModel]
      );
    },
  },
  methods: {
    handleChange(tab, event) {
      if (this.activeTabsName == 2) {
        if (!this.isFetchHistory) {
          this.isFetchHistory = true;
          this.fetchHistory(this.instance.id);
        }
      }
    },
    handleHistoryChange(nodeId) {
      let historyNode = NodeUtils.getPreviousNode(nodeId, this.flowConfig);
      this.historyFormData = JSON.parse(
        this.historyList.find((t) => t.nodeId === nodeId)?.formData
      );
      var drawingList = JSON.parse(this.template.formConfig);
      for (let i = 0; i < drawingList.length; i++) {
        var vModel = drawingList[i].vModel;
        if (!this.historyFormData.hasOwnProperty(vModel)) {
          this.$set(this.historyFormData, vModel, drawingList[i].defaultValue);
        }
        if (drawingList[i].required) {
          this.rules[vModel] = [
            { required: true, message: this.l("Required") },
          ];
        }
        drawingList[i].visible = true;
        if (
          historyNode &&
          historyNode.properties &&
          historyNode.properties.formOperates
        ) {
          var operate = historyNode.properties.formOperates.find(
            (t) => t.formId == drawingList[i].formId
          );
          if (operate) {
            drawingList[i].disabled = operate.formOperate == 1;
            if (operate.formOperate == 0) {
              drawingList[i].visible = false;
            }
          }
        }
      }
      this.historyDrawingList = drawingList;
    },
    getNodeIcon(item) {
      let historyNode = NodeUtils.getPreviousNode(item.nodeId, this.flowConfig);
      let icon = "";
      if (historyNode && historyNode.type) {
        switch (historyNode.type) {
          case "start":
            icon = "fa fa-check-square-o fa-fw";
            break;
          case "approver":
            icon = "fa fa-user fa-fw";
            break;
        }
      }
      return icon;
    },
    getNodeName(item) {
      let historyNode = NodeUtils.getPreviousNode(item.nodeId, this.flowConfig);
      if (historyNode && historyNode.properties) {
        return historyNode.properties.title;
      }
    },
    hiddenFormData(formData) {
      return !(Object.keys(JSON.parse(formData)).length === 0);
    },
    open({ instance, text, allowApprove, unProcess, adminMode }) {
      if (instance != null) {
        this.unProcess = unProcess;
        this.adminMode = adminMode;

        this.instance = instance;
        this.template = JSON.parse(instance.templateJson);

        this.flowConfig = JSON.parse(this.template.flowConfig);
        this.formData = JSON.parse(instance.formData);

        this.allowApprove = allowApprove;
        this.text = text;

        var currentNode = NodeUtils.getPreviousNode(
          this.instance.nodeId,
          this.flowConfig
        );
        var drawingList = JSON.parse(this.template.formConfig);
        for (var i = 0; i < drawingList.length; i++) {
          var vModel = drawingList[i].vModel;
          if (!this.formData.hasOwnProperty(vModel)) {
            this.$set(this.formData, vModel, drawingList[i].defaultValue);
          }
          if (drawingList[i].required) {
            this.rules[vModel] = [
              { required: true, message: this.l("Required") },
            ];
          }
          drawingList[i].visible = true;
          if (
            currentNode &&
            currentNode.properties &&
            currentNode.properties.formOperates
          ) {
            var operate = currentNode.properties.formOperates.find(
              (t) => t.formId == drawingList[i].formId
            );
            if (operate) {
              drawingList[i].disabled = operate.formOperate == 1;
              if (operate.formOperate == 0) {
                drawingList[i].visible = false;
              }
            }
          }
        }

        this.drawingList = drawingList.filter((t) => t.visible);
      }
      return this.$refs.dialog.open();
    },
    fetchHistory(id) {
      if (id) {
        getHistory(id)
          .then((res) => {
            this.historyList = res.items;
          })
          .catch(() => {
            this.historyList = [];
          });
      }
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
    ok(approve) {
      this.$refs.form.validate((val) => {
        if (val) {
          this.$refs.dialog.close({
            instance: this.instance,
            approve: approve,
            formData: JSON.stringify(this.formData),
            optionalApprovers: null,
          });
        }
      });
    },
    adminOk(approve) {
      this.$refs.dialog.close({
        id: this.instance.id,
        approve: approve,
      });
    },
  },
};
</script>


