<template>
  <el-dropdown trigger="click" @command="handleLanguageCommand($event)" :show-timeout="50" :hide-timeout="50">
    <span class="lang-dropdown-link">
      <i :class="currentLanguage.icon" style="display:inline-block;" 
      v-if="$ux.v.languages.length>1"></i>&nbsp;{{ l(currentLanguage.name)
      }}<i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown" style="margin-top:0;">
      <template v-for="language in languages">
        <el-dropdown-item v-if="!language.isDisabled" :command="language" :key="language.name">
          <i :class="language.icon" style="display:inline-block;"></i>
          {{ l(language.name) }}
        </el-dropdown-item>
      </template>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import Cookies from "js-cookie";
import { saveUserConfig } from "@/api/uxretail/sys/sysUserConfig.js";
/* global abp */
export default {
  name: "language-switch",
  data() {
    return {
      currentLanguage: this.$ux.v.applicationResource.languages.find(
        (t) => t.name == this.$store.state.user.language
      ),
      languages: this.$ux.v.applicationResource.languages.filter(
        (t) => !t.isDisabled
      ),
    };
  },
  methods: {
    handleLanguageCommand(language) {
      if (language) {
        saveUserConfig({
          configKey: this.CONSTANT.UX_USER_LANGUAGE_KEY,
          configValue: language.name,
        }).then((res) => {
          Cookies.set(
            this.CONSTANT.UX_LANGUAGE,
            language.name,
            new Date(new Date().getTime() + 5 * 365 * 86400000) //5year
          );
          window.location.reload();
        });
      }
    },
  },
  created() {},
};
</script>
<style lang="scss">
@import url("../../assets/styles/famfamfam-flags.css");
.lang-dropdown-link {
  font-size: 14px; 
}
</style>
