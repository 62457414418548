<template>
  <el-link class="ux-form-item__label link-edit" :underline="false" @click="linkClick" v-if="editable">{{label}} <i
      class="el-icon-edit-outline"></i>
  </el-link>
  <label class="ux-form-item__label" v-else>{{label}}</label>
</template>

<script>
import { checkPermi, checkRole } from "@/utils/permission"; // 权限判断函数

export default {
  name: "link-edit",
  props: {
    label: {},
    dictType: {},
  },
  data() {
    return {
      editable: checkPermi(["system:dict:edit"]),
    };
  },
  methods: {
    linkClick() {
      this.$router.push("/dict/type/data/" + this.dictType);
    },
  },
};
</script>

<style>
.link-edit {
  color: #f67a17 !important;
}
</style>