<template>
  <div class="navbar"
    :class="themeSetting.sideTheme"
    :style="{ backgroundColor: themeSetting.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg,
    color: (themeSetting.sideTheme === 'theme-dark' ? '#fff' : '#5a5e66')+' !important' }">
    <hamburger id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container"
      class="breadcrumb-container"
      v-if="!topNav" />
    <top-nav id="topmenu-container"
      class="topmenu-container" v-if="topNav" />

    <div v-if="shopDescription&&!topNav"
      class="shop-description"
      :style="{marginLeft:sidebar.opened?'-310px':'-227px'}">
      {{shopDescription}}
    </div>

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <search id="header-search"
          class="right-menu-item" />

        <message-box></message-box>

        <screenfull id="screenfull"
          class="right-menu-item hover-effect" />

        <!-- <el-tooltip :content="l('TheLayoutSize')" effect="dark" placement="bottom">
                <size-select id="size-select" class="right-menu-item hover-effect" />
            </el-tooltip> -->
            
        <!-- v-if="$ux.v.languages.length>1" -->
        <language-switch
          class="right-menu-item hover-effect">
        </language-switch>
        <span
          v-if="$ux.v.applicationResource.storeSetting&&$ux.v.applicationResource.storeSetting['Store.ShopOrgId']"
          class="right-menu-item">{{$ux.v.applicationResource.storeSetting["Store.UserName"]}}</span>
      </template>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar"
            class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>
              {{l('PersonalCenter')}}
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item
            @click.native="setting = true">
            <span>{{l('LayoutSettings')}}</span>
          </el-dropdown-item>
          <el-dropdown-item divided
            @click.native="logout">
            <span>{{l('LogOut')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import MessageBox from "./Messagebox/index.vue";
import LanguageSwitch from "../../components/LanguageSwitch/languageSwitch.vue";
import variables from "@/assets/styles/variables.scss";
export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    LanguageSwitch,
    MessageBox,
  },
  data() {
    return {
      shopDescription: this.$ux.v.applicationResource["storeSetting"]
        ? this.$ux.v.applicationResource["storeSetting"]["Store.Description"]
        : "",
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device"]),
    themeSetting() {
      return this.$store.state.settings;
    },
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
    variables() {
      return variables;
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm(
        this.l("AreYouSureToLogOutAndExitTheSystem?"),
        this.l("Reminder"),
        {
          confirmButtonText: this.l("Confirm"),
          cancelButtonText: this.l("Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          location.href = "/";
        });
      });
    },
  },
};
</script>

<style lang="scss" >
.navbar.theme-dark {
  .hamburger {
    fill: #fff !important;
  }
  .el-breadcrumb__inner {
    color: #fff !important;
  }

  .right-menu-item {
    color: #fff !important;
    .svg-icon {
      color: #fff !important;
    }
  }
}

.navbar.theme-light {
  .hamburger {
    fill: #5a5e66 !important;
  }
  .el-breadcrumb__inner {
    color: #5a5e66 !important;
  }

  .right-menu-item {
    color: #5a5e66 !important;
    .svg-icon {
      color: #5a5e66 !important;
    }
  }
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }
  .shop-description {
    position: absolute;
    height: 100%;
    line-height: 50px;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    text-align: center; 
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
