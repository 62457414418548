import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import VFrame from "./framework/VFrame";
import CONSTANT from '@/CONSTANT.js'
import format from '@/utils/format'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/posLogin', '/auth-redirect', '/bind', '/register','/resetPassword','/forget']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          //重新加载用户多语言以及DragLayout
          VFrame.start().then(async () => {
            await format.init()
            //set layout to store
            store.commit("dragLayout/initLayout")
            if (VFrame.applicationResource.userConfigList) {
              var layoutSetting = VFrame.applicationResource.userConfigList.find(x => x.configKey == CONSTANT.LAYOUT_SETTING)
              if (layoutSetting)
                store.commit("settings/INIT_USER_SETTING", layoutSetting)
            }
            store.dispatch('GenerateRoutes').then(accessRoutes => {
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes) // 动态添加可访问路由表
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
          }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
        })
      } else {
        if (store.getters.visitedViews.length > 8 && !store.getters.visitedViews.find((i) => i.fullPath === to.fullPath)) {
          Message.error(VFrame.l('CachedTabOver8PleaseCloseOther'))
          NProgress.done()
        } else {
          next()
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
