import request from '@/utils/request'




export function getData({ sql, dataSourceId }) {
    return request({
        url: '/uxretail/rpQuery/getData',
        method: 'post',
        data: { sql, dataSourceId }
    })
}

// 加载查询条件
export function getSqlFilters({ sql, dataSourceId, maxCount = 100, keyword, value }) {
    return request({
        url: '/uxretail/rpQuery/getSqlFilters',
        method: 'post',
        data: { sql, dataSourceId, maxCount, keyword, value }
    })
}
export function getColumns({ sql, dataSourceId }) {
    return request({
        url: '/uxretail/rpQuery/getColumns',
        method: 'post',
        data: { sql, dataSourceId, }
    })
}

// 查询Bilcustomerreturn列表
export function listRpQuery({ keyword }) {
    return request({
        url: '/uxretail/rpQuery/list',
        method: 'post',
        data: { keyword }
    })
}

// 导出Bilcustomerreturn
export function exportRpQuery({ keyword }) {
    return request({
        url: '/uxretail/rpQuery/export',
        method: 'post',
        data: { keyword }
    })
}

// 查询Bilcustomerreturn列表
export function pageRpQuery({ keyword }, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/uxretail/rpQuery/page',
        method: 'post',
        data: { keyword },
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
// 查询Bilcustomerreturn详细
export function infoRpQuery(id, code) {
    return request({
        url: '/uxretail/rpQuery/info',
        method: 'post',
        data: { id, code }
    })
}

// 新增Bilcustomerreturn
export function saveRpQuery(data) {
    return request({
        url: '/uxretail/rpQuery/save',
        method: 'post',
        data: data
    })
}

// 删除Bilcustomerreturn
export function deleteRpQuery(id) {
    return request({
        url: '/uxretail/rpQuery/delete',
        method: 'post',
        data: { id }
    })
}

// 新增Bilcustomerreturn
export function cancelRpQuery(id) {
    return request({
        url: '/uxretail/rpQuery/cancel',
        method: 'post',
        data: { id }
    })
}
export function postRpQuery(id) {
    return request({
        url: '/uxretail/rpQuery/post',
        method: 'post',
        data: { id }
    })
}
// 新增Bilcustomerreturn
export function confirmRpQuery(data) {
    return request({
        url: '/uxretail/rpQuery/confirm',
        method: 'post',
        data: data
    })
}


export function infoRpLayout(data) {
    return request({
        url: '/uxretail/rpQuery/infoLayout',
        method: 'post',
        data: data
    })
}

export function saveRpLayout(data) {
    return request({
        url: '/uxretail/rpQuery/saveLayout',
        method: 'post',
        data: data
    })
}

export function deleteRpLayout(data) {
    return request({
        url: '/uxretail/rpQuery/deleteLayout',
        method: 'post',
        data: data
    })
}