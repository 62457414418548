import request from '@/utils/request'

export function getHistory (id) {
    return request({
        url: '/uxretail/SysApprovalFlowInstance/getHistory',
        method: 'post',
        data: { id }
    })
}

export function getInstance ({ flowTypeCode, objectId, unProcess }) {
    return request({
        url: '/uxretail/SysApprovalFlowInstance/getInstance',
        method: 'post',
        data: { flowTypeCode, objectId, unProcess }
    })
}
export function commit ({ instance, approve, formData, optionalApprovers }) {
    return request({
        url: '/uxretail/SysApprovalFlowInstance/commit',
        method: 'post',
        data: { instance, approve, formData, optionalApprovers }
    })
}
export function adminCommit ({ instanceId }) {
    return request({
        url: '/uxretail/SysApprovalFlowInstance/adminCommit',
        method: 'post',
        data: { id: instanceId }
    })
}
export function deleteInstance (id) {
    return request({
        url: '/uxretail/SysApprovalFlowInstance/delete',
        method: 'post',
        data: { id: id }
    })
}