<template>
  <n-dialog-layout :title="title" ref="dialog" class="ux-data-dialog" @opened="handleOpened" :width="width+'px'">
    <slot name="filterHeader"></slot>
    <ux-query-view :multiple="config.multiple" ref="dt" size="small" :code="config.code" :sys_params="config.sys_params"
      :height="height" :value="value" :valueField="valueField" :echoField="echoField">
      <template slot="template-action-columns">
        <vxe-column prop="id" width="35" fixed="left">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="handleSelect(scope.row)"></el-button>
          </template>
        </vxe-column>
      </template>
    </ux-query-view>
    <div slot="footer" class="dialog-footer" v-if="config.multiple">
      <el-button @click="cancel()">{{ l("Cancel") }}</el-button>
      <el-button type="primary" @click="confirm()">{{
        l("Confirm")
      }}</el-button>
    </div>
  </n-dialog-layout>
</template>
<script>
import ConfigTable from "../Table/ConfigTable.vue";

export default {
  name: "ux-query-dialog",
  components: {
    ConfigTable,
  },
  data() {
    return {
      config: {},
      title: "",
      keyword: "",
      last_keyword: "",
      height: window.innerHeight * 0.6,
      width: window.innerWidth * 0.7,
      value:null,
      valueField:"id",
      echoField:"",
    };
  },
  methods: {
    open({ title, config }) {
      this.config = config;
      this.title = title;
      this.value = config.value
      this.valueField = config.valueField
      this.echoField = config.echoField
      return this.$refs.dialog.open();
    },
    handleOpened() {},
    cancel() {
      this.$refs.dialog.cancel();
    },
    confirm() {
      this.$refs.dialog.close(this.$refs.dt.getSelected());
    },
    handleSearch() {
      if (this.last_keyword != this.keyword) {
        this.config.filterDto.keyword = this.keyword;
        this.last_keyword = this.keyword;
        this.$refs.dt.refresh();
      }
    },
    handleSelect(row) {
      this.$refs.dialog.close(this.config.multiple ? [row] : row);
    },
  },
};
</script>
