<template>
    <div ref="reference"></div>
</template>

<script>
import { DatePicker } from "element-ui";
export default {
    mixins: [DatePicker],
    name: "inline-date-picker",
    methods: {
        show() {
            this.handleClickIcon();
        }
    },
    beforeDestroy() {
        console.log("hide picker");
        this.hidePicker();
    }
};
</script>

<style>
</style>