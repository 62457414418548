<template>
  <div ref="container"
    :class="[isMax?'max-table':'']"
    :style="{height:'calc(100% - '+diffHeight+'px)'}">
    <vxe-toolbar perfect size="mini"
      v-if="showToolbar">
      <template #buttons>
        <div class="flex-between   mr10">
          <!--left-->
          <div>
            <slot name="toolbar_prepend">
            </slot>
            <el-tooltip :content="l('CtrlEnter')"
              v-if="showAdd">
              <el-button type="text"
                icon="el-icon-plus" size="mini"
                @click="handleAdd">{{l('Add')}}
              </el-button>
            </el-tooltip>
            <el-button type="text"
              icon="el-icon-delete" size="mini"
              v-if="showDelete"
              :disabled="selectedItems.length==0"
              @click="deleteRows">
              {{l('Delete')}}</el-button>
            <el-button type="text"
              icon="el-icon-file" size="mini"
              v-if="!!importTemplate.code"
              :disabled="disabledImport"
              @click="importRows">
              {{l('Import')}}</el-button>
            <slot name="toolbar_append">
            </slot>
          </div>
          <!--right-->
          <div>
            <el-tooltip :content="l('Maximize')"
              v-if="showMax">
              <el-button type="text"
                icon="el-icon-full-screen"
                size="mini" @click="handleMax">
              </el-button>
            </el-tooltip>
          </div>
        </div>

      </template>
    </vxe-toolbar>
    <vxe-table border :height="height"
      ref="xTable" :data="pagedValue" size="mini"
      class="ux-edit-table" show-header-overflow
      show-overflow highlight-hover-row
      :scroll-y="{enabled:scrollYEnabled,oSize:100}"
      :scroll-x="{enabled:false}" auto-resize
      :highlight-current-row="true"
      :mouse-config="{selected: true}"
      @keydown="handleKeyDown" sync-resize
      :show-footer="!!footerMethod"
      :footer-method="footerMethod"
      :row-key="dragable"
      @current-change="e=>$emit('current-change',e)"
      @cell-click="e=>$emit('cell-click',e)"
      :keyboard-config="{isArrow: true,
        isDel: true,
        isEnter: true,
        isTab: true,
        enterToTab:true,
        isEdit: true,
        isChecked: true}"
      :edit-config="{trigger: 'click', mode: 'cell',showStatus:false,autoClear:false}"
      :edit-rules="rules" :row-config="rowConfig"
      @checkbox-all="handleCheckChange"
      @checkbox-change="handleCheckChange"
      @edit-closed="handleEditClose"
      :cell-style="cellStyle"
      :span-method="spanMethod">
      <vxe-column width="60" v-if="dragable"
        align="center">
        <template #default>
          <span class="drag-btn">
            <i class="el-icon-rank"></i>
          </span>
        </template>
      </vxe-column>
      <vxe-column type="checkbox" width="60"
        v-if="showCheckbox"></vxe-column>
      <vxe-column type="seq" width="45"
        v-if="showSeq" :fixed="seqFixed">
      </vxe-column>
      <slot name="columns">
      </slot>
    </vxe-table>
    <div
      style="display: flex;justify-content: flex-end;align-items: center;"
      v-if="pageable">
      <pagination v-show="value.length > 0"
        :total="value.length"
        :page.sync="page.pageNum"
        :limit.sync="page.pageSize"
        @pagination="$emit('pagination',pagedValue)">
      </pagination>
    </div>
  </div>
</template>

<script>
import "./edittable.render.js";
import Sortable from "sortablejs";
import ImportDialog from "@/components/ImportFunction/dialog.vue";
export default {
  name: "ux-edit-table",
  props: {
    rowConfig: {},
    showToolbar: {
      default: true,
    },
    showAdd: {
      default: true,
    },
    showDelete: {
      default: true,
    },
    showMax: {
      default: false,
    },
    disabledImport: {
      default: false,
    },
    showSeq: {
      default: true,
    },
    seqFixed: {
      default: "",
    },
    pageable: {
      default: false,
    },
    footerMethod: {
      default: null,
    },
    showCheckbox: {
      default: true,
    },
    height: {
      default: "auto",
    },
    value: {
      default: () => [],
    },
    rules: {},
    //支持object和function
    rowPredefine: {
      default: () => ({}),
    }, //如果有多级属性，需要预定义才能有属性监听
    dragable: {
      default: false,
    },
    dragableSortKey: {},
    importTemplate: {
      default: () => ({
        code: "",
        afterImport: null,
      }),
    },
    filterExpression: {}, //用于过滤显示值
    cellStyle: {
      default: null, // 用于展示没有 sku 单元格样式
    },
    spanMethod: {
      default: null,
    },
    scrollYEnabled: {
      default: false,
    },
  },
  data() {
    return {
      cHeight: "100%",
      vHeight: "auto",
      selectedItems: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      isMax: false,
    };
  },
  watch: {
    value(n, o) {
      if (n != o) {
        console.log("reload data");
        this.instance().loadData(this.pagedValue);
        this.$nextTick(() => {
          this.instance().refreshColumn();
        });
        if (this.pageable) {
          this.$emit("pagination", this.pagedValue);
        }
      }
    },
    height() {
      this.resize();
    },
  },
  methods: {
    handleMax() {
      this.isMax = !this.isMax;
    },
    importRows() {
      this.$dialog
        .open(ImportDialog, {
          code: this.importTemplate.code,
          parameterItems: this.importTemplate.parameterItems,
        })
        .then((res) => {
          if (this.importTemplate.afterImport) {
            res = this.importTemplate.afterImport(res.tableData);
          } else {
            res.tableData[0].forEach((t) => {
              this.value.push(t);
            });
          }
        });
    },
    resize() {
      // if (this.height == "auto" || !this.height) {
      //   this.cHeight = this.$refs.container.parentElement.clientHeight - 20;
      //   this.vHeight = this.cHeight - 46;
      // } else {
      //   this.vHeight = this.height;
      // }
    },
    handleCheckChange() {
      this.selectedItems = this.$refs.xTable.getCheckboxRecords(true);
      // 选中的 行
      this.$emit("select-change", this.selectedItems);
    },

    instance() {
      return this.$refs.xTable;
    },
    async validate() {
      if (this.value.length == 0) {
        return;
      }
      const errMap = await this.$refs.xTable
        .validate(true)
        .catch((errMap) => errMap);
      if (errMap) {
        const msgList = [];
        Object.values(errMap).forEach((errList) => {
          errList.forEach((params) => {
            const { rowIndex, column, rules } = params;
            rules.forEach((rule) => {
              msgList.push(
                `${this.l("Row")} ${rowIndex + 1} ${column.title} ：${
                  rule.message
                }`
              );
            });
          });
          this.$message.error(msgList.join("\r\n"));
        });
        throw new Error();
      }
    },
    handleKeyDown(event) {
      if (event.$event.ctrlKey && event.$event.key == "Enter") {
        this.handleAdd();
      }
    },
    handleAdd() {
      this.validate()
        .then(() => {
          if (typeof this.rowPredefine === "function") {
            this.value.push(this.rowPredefine());
          } else {
            this.value.push(this.copy(this.rowPredefine));
          }
          // this.$nextTick(() => {
          //     this.$refs.xTable.validate(true);
          // });
        })
        .catch(() => console.log("edittable valid fail"));
    },
    handleEditClose({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
    }) {
      console.log("close");
      this.$emit("cellEdited", {
        row,
        rowIndex,
        column,
        columnIndex,
      });
    },

    resize() {
      // if (this.height == "auto" || !this.height) {
      //   this.cHeight = this.$refs.container.parentElement.clientHeight - 20;
      //   this.vHeight = this.cHeight - 46;
      // } else {
      //   this.vHeight = this.height;
      // }
    },
    handleCheckChange() {
      this.selectedItems = this.$refs.xTable.getCheckboxRecords(true);
      // 选中的 行
      this.$emit("select-change", this.selectedItems);
    },

    instance() {
      return this.$refs.xTable;
    },

    handleKeyDown(event) {
      if (event.$event.ctrlKey && event.$event.key == "Enter") {
        this.handleAdd();
      }
    },
    handleAdd() {
      this.validate()
        .then(() => {
          if (typeof this.rowPredefine === "function") {
            this.value.push(this.rowPredefine());
          } else {
            this.value.push(this.copy(this.rowPredefine));
          }
          // this.$nextTick(() => {
          //     this.$refs.xTable.validate(true);
          // });
        })
        .catch(() => console.log("edittable valid fail"));
    },
    handleEditClose({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
    }) {
      this.$emit("cellEdited", {
        row,
        rowIndex,
        column,
        columnIndex,
      });
    },
    deleteRow(row) {
      this.value.remove(row);
    },
    deleteRows() {
      this.$confirm(this.l("SureDelete")).then(() => {
        this.$emit("deleteRows", this.selectedItems);
        this.selectedItems.forEach((t) => this.value.remove(t));
        this.selectedItems = [];
      });
    },
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable;
        this.sortable1 = Sortable.create(
          xTable.$el.querySelector(".body--wrapper>.vxe-table--body tbody"),
          {
            handle: ".drag-btn",
            onEnd: ({ newIndex, oldIndex }) => {
              const currRow = this.value.splice(oldIndex, 1)[0];
              this.value.splice(newIndex, 0, currRow);
              if (this.dragableSortKey) {
                this.value.forEach(
                  (t, index) => (t[this.dragableSortKey] = index)
                );
              }
            },
          }
        );
      });
    },
  },
  mounted() {},
  created() {
    if (this.dragable) {
      this.rowDrop();
    }
  },
  beforeDestroy() {
    if (this.sortable1) {
      this.sortable1.destroy();
    }
  },
  computed: {
    diffHeight() {
      let h = 0;
      if (this.showToolbar) h += 46;
      if (this.pageable) h += 52;
      return h;
    },
    pagedValue() {
      var v = this.filterExpression
        ? this.filterExpression(this.value)
        : this.value;
      if (v) {
        if (this.pageable) {
          var offset = (this.page.pageNum - 1) * this.page.pageSize;
          return _.slice(
            v,
            offset,
            offset + this.page.pageSize >= v.length
              ? v.length
              : offset + this.page.pageSize
          );
        } else {
          return v;
        }
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
.drag-btn {
  cursor: move;
}
.max-table {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.ux-edit-table {
  // .vxe-cell--title {
  //   white-space: pre-line !important;
  //   overflow: auto;
  // }
  // .vxe-header--row {
  //   .vxe-header--column {
  //     height: auto !important;
  //     .vxe-cell {
  //       min-height: 36px;
  //       max-height: unset !important;
  //     }
  //   }
  // }
}
</style>
