import axiso from 'axios'
import { getAll } from '@/api/uxretail/application.js'
import Cookies from 'js-cookie';
const format = require('string-format')


export default class VFrame {

    static host;
    static wsUrl;
    static loginPage;
    static posLoginPage;
    static VueInstance;
    static applicationResource;
    static languages;
    static sentry;
    static tracePropagationTargets;

    /**
     * 在vue实例化之前调用异步start方法
     */
    static start() {
        return new Promise((resolve, reject) => {
            axiso.request({
                url: "/static/appconfig.json",
                method: "get",
            }).then(result => {
                VFrame.host = (result.data.host || window.location.host);
                VFrame.wsUrl = "ws://" + VFrame.host + ":" + result.data.wsPort + "/ws/notify/";
                VFrame.loginPage = result.data.loginPage
                VFrame.posLoginPage = result.data.posLoginPage
                VFrame.languages = result.data.languages 
                VFrame.sentry = result.data.sentry
                VFrame.tracePropagationTargets = result.data.tracePropagationTargets 
                VFrame.getAll(resolve, reject);
            }).catch(error => {
                reject(error);
            });
        });
    }

    static getAll(resolve, reject) {
        getAll().then(res => {
            VFrame.applicationResource = res
            resolve()
        }).catch(() => {
            reject()
        });
    }


    static l(key, ...args) {
        if (localStorage.getItem("ux_debug_language")) {
            return `[${key}]`
        }

        if (!VFrame.applicationResource) {
            return key
        }
        if (VFrame.applicationResource.language_texts) {
            let res = VFrame.applicationResource.language_texts[key];
            if (res) {
                return format(res, args);
            } else {
                return `[${key}]`
            }
        }
        return key;
    }
}
