import { Checkbox } from "element-ui";

export default {
  name: "ux-checkbox",
  mixins: [Checkbox],
  props: {
    trueLabel: { default: 1 },
    falseLabel: { default: 0 }
  }
};
