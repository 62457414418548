import { formatNumber,parseNumber } from "devextreme/localization";
import { getConfigKey } from "@/api/system/config";

import moment from "moment";
import Format from "string-format";
import EMUMS from '@/ENUMS.js'
import VFrame from "../framework/VFrame";

class UxFormat {
  constructor() {
    this.priceFormat = "#,##0.00"
    this.moneyFormat = "#,##0"
    this.currencyFormat = "#,##0.000000"
    this.volumeFormat = "#,##0.0"
    this.dateFormat = "yyyy-MM-dd"

    //通用数值格式,用于非price，amount，qty等特殊地方
    this.numberFormat0 = "#,##0"
    this.numberFormat2 = "#,##0.00"

    this.amountFormat = "#,##0.00"
    this.costFormat = "#,##0.00"
    this.qtyFormat = "#,##0"
    this.weightFormat = "#,##0.0"
    this.dateTimeFormat = "yyyy-MM-dd HH:mm:ss"
    this.momentDateFormat = "YYYY-MM-DD"
    this.momentDateTimeFormat = "YYYY-MM-DD HH:mm:ss"
    this.percentFormat = "##0.0"

  }


  //store/user/GetInfo 后触发初始化
  async init() {
    this.priceFormat = await this._getFormat("ux.price.format", this.priceFormat)
    this.moneyFormat = await this._getFormat("ux.money.format", this.moneyFormat)
    this.currencyFormat = await this._getFormat("ux.currency.format", this.currencyFormat)
    this.volumeFormat = await this._getFormat("ux.volume.format", this.volumeFormat)
    this.dateFormat = await this._getFormat("ux.date.format", this.dateFormat)
    this.numberFormat0 = await this._getFormat("ux.number.format0", this.numberFormat0)
    this.numberFormat2 = await this._getFormat("ux.number.format2", this.numberFormat2)
    this.amountFormat = await this._getFormat("ux.amount.format", this.amountFormat)
    this.costFormat = await this._getFormat("ux.cost.format", this.costFormat)
    this.weightFormat = await this._getFormat("ux.weight.format", this.weightFormat)
    this.dateTimeFormat = await this._getFormat("ux.date.time.format", this.dateTimeFormat)

    if (VFrame.applicationResource["storeSetting"]) {
      this.priceFormat = VFrame.applicationResource["storeSetting"]["Store.PriceFormatValue"]
      this.amountFormat = VFrame.applicationResource["storeSetting"]["Store.MoneyFormatValue"]
      this.percentFormat = VFrame.applicationResource["storeSetting"]["Store.PercentFormatValue"]
    }


    this.momentDateFormat = this.dateFormat.replaceAll("y", "Y").replaceAll("d", "D")
    this.momentDateTimeFormat = this.dateTimeFormat.replaceAll("y", "Y").replaceAll("d", "D")
  }

  async _getFormat(key, defaultValue) {
    try {
      var res = await getConfigKey(key);
      var data = res.msg;
      if (data) {
        return data;
      } else {
        return defaultValue
      }
    } catch {
      return defaultValue
    }
  }
  dateTimebySales(dt, format) {
    if (!dt) return "";
    return moment(dt).format(format || this.momentDateTimeFormat);
  }
  date(dt, format) {
    if (!dt) return "";
    return moment(dt).format(format || this.momentDateFormat);
  }
  dateTime(dt, format) {
    if (!dt) return "";
    return moment(dt).format(format || this.momentDateTimeFormat);
  }
  //用于日期字符串查询db
  utcDateTime(dt, format) {
    if (!dt) return "";
    return moment(dt).utc().format(format || this.momentDateTimeFormat);
  }
  price(v) {
    return formatNumber(v, this.priceFormat);
  }
  cost(v) {
    return formatNumber(v, this.costFormat);
  }
  currency(v) {
    return formatNumber(v, this.currencyFormat);
  }
  amount(v, convert = false) {
    if (convert) {
      return parseNumber(formatNumber(v, this.amountFormat))
    }
    return formatNumber(v, this.amountFormat);
  }
  percent(v) {
    return formatNumber(v, this.percentFormat);
  }
  qty(v) {
    return formatNumber(v, this.qtyFormat);
  }

  columnFormatter(type) {
    let thiz = this;

    switch (type) {
      case "date":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.date(cellValue);
        };
      case "datetime":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.dateTime(cellValue);
        };
      case "price":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.price(cellValue);
        };
      case "amount":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.amount(cellValue);
        };
      case "qty":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.qty(cellValue);
        };
      case "cost":
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return thiz.cost(cellValue);
        };
      default:
        return function (row, column, cellValue, index) {
          if (!cellValue) {
            return ""
          }
          return cellValue
        };
    }
  }

  enumFormat(type, value) {
    var item = EMUMS[type].find(t => t.value == value)
    return item ? VFrame.l(item.key) : ""
  }

  // 特殊格式
  customDateFormat(v) {
    return moment(v).format("MMM D/YYYY");
  }
};


export default new UxFormat()