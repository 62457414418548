import vue from "vue";

const list = {
  UX_LANGUAGE: "UxSoft.Localization.CultureName",
  UX_DEFAULT_LANGUAGE_KEY: "ux.default.language",
  UX_USER_LANGUAGE_KEY: "ux.user.language",
  DRAG_LAYOUTS: "ux.drag.layouts",
  LAYOUT_SETTING: "layout-setting",
  // LANGUAGES: ["en-GB"], //for halo change to only en
  ORGANIZATION_TYPE: "ux.organization.type",
  INIT_BILL_STATUS: "Issued",
  NOT_SKU_BACKGROUND_STYLE:"#f60",
  NOT_SKU_COLOR_STYLE:"#ffffff"
};

vue.prototype.CONSTANT = list;

export default list;
