<template>
  <n-dialog-layout :title="title" ref="dialog" class="ux-data-dialog" @opened="handleOpened">
    <slot name="filterHeader"></slot>
    <config-table :columns="config.columns" :pageRequest="config.pageRequest" :pageApi="config.pageApi"
      :lazyFetch="true" :multiple="config.multiple" :filterDto="config.filterDto" ref="dt" size="small">
      <template slot="template-action-columns">
        <vxe-column prop="id">
          <template slot="header">
            <input type="text" @blur="handleSearch" @keyup.enter="handleSearch" v-model="keyword"
              :placeholder="l('PleaseEnterKeywords')" class="el-input__inner" style="height:30px;">
          </template>
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="handleSelect(scope.row)"></el-button>
          </template>
        </vxe-column>
      </template>
    </config-table>
    <div slot="footer" class="dialog-footer" v-if="config.multiple">
      <el-button @click="cancel()">{{ l("Cancel") }}</el-button>
      <el-button type="primary" @click="confirm()">{{
        l("Confirm")
      }}</el-button>
    </div>
  </n-dialog-layout>
</template>
<script>
import ConfigTable from "../Table/ConfigTable.vue";

export default {
  name: "ux-data-dialog",
  components: {
    ConfigTable,
  },
  data() {
    return {
      config: {},
      title: "",
      keyword: "",
      last_keyword: "",
    };
  },
  methods: {
    open({ title, config }) {
      if (!config.filterDto) {
        config.filterDto = { keyword: "" };
      }
      this.config = config;
      this.title = title;
      return this.$refs.dialog.open();
    },
    handleOpened() {
      this.$refs.dt.refresh();
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
    confirm() {
      this.$refs.dialog.close(this.$refs.dt.getSelected());
    },
    handleSearch() {
      if (this.last_keyword != this.keyword) {
        this.config.filterDto.keyword = this.keyword;
        this.last_keyword = this.keyword;
        this.$refs.dt.refresh();
      }
    },
    handleSelect(row) {
      this.$refs.dialog.close(this.config.multiple ? [row] : row);
    },
  },
};
</script>
