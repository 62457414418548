<template>
  <div v-loading="loading" style="overflow-y:auto;">
    <el-steps :active="step" simple>
      <el-step :title="l('DownloadTemplate')">
      </el-step>
      <el-step :title="l('Upload')"></el-step>
      <el-step :title="l('ImportResult')">
      </el-step>
    </el-steps>
    <div class="import-template-container">
      <div v-if="step==0">
        <div class="flex">
          <el-button type="success"
            @click="downloadTemplate">
            {{l('DownloadTemplate')}}</el-button>
          <div style="margin-left:20px;">
            <el-alert type="warning"
              :closable="false">
              {{l('AllowBrowserDownloadFile')}}
            </el-alert>
          </div>
        </div>
        <div class="text-right mt20">
          <el-button type="primary"
            icon="el-icon-arrow-right"
            @click="()=>step=1">{{l('Next')}}
          </el-button>
        </div>
      </div>
      <div v-else-if="step==1">
        <file-upload :isShowTip="false"
          actionUrl="/uxretail/SysImportTemplate/upload"
          :extendData="extendData"
          @upload="handleUploaded">
        </file-upload>
        <el-alert type="warning"
          :closable="false">
          {{l('AllowBrowserDownloadFile')}}
        </el-alert>
        <div class="text-right mt20">
          <el-button type="primary"
            icon="el-icon-arrow-left"
            @click="()=>step=0">{{l('Back')}}
          </el-button>
          <el-button type="danger">{{l('Cancel')}}
          </el-button>
        </div>
      </div>
      <div v-else>
        <div
          v-if="importResult.checkRowResultList&&importResult.checkRowResultList.length>0">
          <el-alert type="error"
            :title="l('RowError')"
            :closable="false">
            <table>
              <tr>
                <th width="40px"
                  style="text-align:left">
                  {{l('Row')}}</th>
                <th width="80px"
                  style="text-align:left">
                  {{l('Status')}}</th>
                <th>{{l('Message')}}</th>
              </tr>
              <tr
                v-for="(rc,index) in importResult.checkRowResultList"
                :key="index">
                <td>
                  {{rc.rowIndex+1}}
                </td>
                <td>
                  {{rc.status==1?l('InfoError'):l('BreakError')}}
                </td>
                <td
                  v-html="formatMessage(rc.message)">
                </td>
              </tr>
            </table>
          </el-alert>
        </div>
        <div
          v-if="importResult.checkResultList&&importResult.checkResultList.length>0"
          class="mt20">
          <el-alert type="error"
            :title="l('Error')" :closable="false">
            <table>
              <tr>
                <th width="80px"
                  style="text-align:left">
                  {{l('Status')}}
                </th>
                <th>{{l('Message')}}</th>
              </tr>
              <tr
                v-for="(rc,index) in importResult.checkResultList"
                :key="index">
                <td>
                  {{l('BreakError')}}
                </td>
                <td
                  v-html="formatMessage(rc.message)">
                </td>
              </tr>
            </table>
          </el-alert>
        </div>
        <template v-if="template.actionType==0">
          <div
            v-if="importResult.sqlResults&&importResult.sqlResults.length>0"
            class="mt20">
            <el-alert
              v-for="(rc,index) in importResult.sqlResults"
              type="success" :key="index"
              :title="l('ImportResult')"
              :closable="false">
              {{l('SQL')}}({{rc.index+1}})
              {{l('EffectRows')}}:
              {{rc.passCount}}
            </el-alert>
            <div v-if="test">
              <el-alert type="error"
                :title="l('PleaseRemoveTempTable')">
                {{importResult.tempSheetTable}}
              </el-alert>
            </div>
          </div>
        </template>
        <template v-else>
          <el-alert type="success">
            <div
              v-for="(s,index) in importResult.tableData"
              :key="index">
              {{l('ReturnRows')}}:{{s.length}}
            </div>
          </el-alert>
        </template>

        <div class="text-right mt20">
          <el-button type="primary"
            icon="el-icon-arrow-left"
            @click="()=>reset()">{{l('Back')}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { infoSysImportTemplate } from "@/api/uxretail/sys/sysImportTemplate.js";
import FileUpload from "@/components/FileUpload/index";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
export default {
  props: {
    templateId: {},
    code: {},
    test: { default: false },
    parameterItems: { default: {} },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      loading: false,
      template: null,
      step: 0,
      extendData: null,
      importResult: null,
    };
  },
  watch: {
    step() {
      this.$emit("step-change", this.step);
    },
    templateId() {
      this.fetch();
    },
  },
  methods: {
    formatMessage(msg) {
      if (msg) {
        var msgArr = msg.split(";").filter((x) => !!x);
        return msgArr.map((t) => this.l(t)).join("<br/>");
      }
      return "";
    },
    getClientData() {
      return this.importResult;
    },
    reset() {
      this.step = 0;
      this.importResult = null;
    },
    handleUploaded(data) {
      if (data.uxApi) {
        if (data.success) {
          this.importResult = data.result;
          this.step = 2;
        } else {
          this.$message.error(data.error);
        }
      } else {
        if (data.code == 500) {
          this.$message.error(data.msg);
        }
      }
    },
    downloadTemplate() {
      if (this.template.templateType == 0) {
        this.downloadStandardExcel();
      } else {
      }
    },
    downloadStandardExcel() {
      const workbook = new Workbook();
      var sheets = _.uniq(this.template.excelTemplate.map((t) => t.sheetName));

      for (var i = 0; i < sheets.length; i++) {
        var columns = this.template.excelTemplate.filter(
          (t) => t.sheetName == sheets[i]
        );
        var worksheet = workbook.addWorksheet(sheets[i]);
        worksheet.columns = columns.map((t) => {
          return {
            header: t.caption + (t.required ? "(*)" : ""),
            key: t.columnName,
            width: t.caption.length * 5,
          };
        });
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "cccccc",
            },
            bgColor: {
              argb: "#96C8FB",
            },
          };
        });
      }
      var code = this.template.code;
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], {
            type: "application/octet-stream",
          }),
          code + "." + "xlsx"
        );

        this.step = 1;
      });
    },
    fetch() {
      this.loading = true;
      infoSysImportTemplate(this.templateId, this.code)
        .then((res) => {
          this.template = res;
          this.$emit("change", res);
          this.extendData = {
            template: JSON.stringify(this.template),
            parameterItems: JSON.stringify(this.parameterItems),
            test: this.test,
          };
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style>
.import-template-container {
  margin: 40px;
}
</style>
