<template>
  <div class="config-table">
    <vxe-table :data="list" ref="table" :loading="loading" stripe :border="border" :size="size">
      <slot name="actions"></slot>
      <vxe-column type="checkbox" width="55" v-if="multiple">
      </vxe-column>

      <vxe-column :fit="true" v-for="col in _columns" :key="col.prop" :field="col.prop"
        :title="col.label || l(propToLabel(col.prop))" :width="col.width" :align="col.align">
      </vxe-column>
      <slot name="template-columns"> </slot>

      <slot name="template-action-columns" v-if="showActions">
        <vxe-column :width="200" fixed="right" align="right">
          <template slot="header">
            <input type="text" @blur="handleSearch" @keyup.enter="handleSearch" v-model="keyword"
              :placeholder="l('PleaseEnterKeywords')" class="el-input__inner" style="height:30px;">
          </template>
          <template slot-scope="scope">
            <div>
              <el-button type="text" icon="el-icon-edit" @click="$emit('editRow', scope.row)"></el-button>
              <el-button type="text" icon="el-icon-delete" @click="$emit('deleteRow', scope.row)"></el-button>
            </div>
          </template>
        </vxe-column>
      </slot>
    </vxe-table>
    <pagination v-show="total > 0" :total="total" :page.sync="page.pageNum" :limit.sync="page.pageSize"
      @pagination="refresh"></pagination>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "config-table",
  props: {
    columns: { default: () => [] }, //prop,label,width,align
    pageRequest: {},
    pageApi: {},
    showActions: { default: true },
    filterDto: {
      default: () => ({ keyword: "" }),
    },
    lazyFetch: { default: false },
    multiple: { default: false },
    border: { default: false },
    size: { default: "normal" },
  },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tt: "",
      keyword: this.filterDto.keyword || "",
    };
  },
  watch: {
    filterDto() {
      this.keyword = this.filterDto.keyword;
    },
  },
  computed: {
    _columns() {
      return this.columns.map((t) => {
        return t;
      });
    },
  },
  methods: {
    handleSearch() {
      this.filterDto.keyword = this.keyword;
      this.refresh();
    },
    _refresh() {
      if (this.pageRequest) {
        return this.pageRequest(this.filterDto, this.page);
      } else if (this.pageApi) {
        return request({
          url: this.pageApi,
          method: "post",
          data: this.filterDto,
          params: this.page,
        });
      } else {
        return Promise.reject();
      }
    },
    refresh() {
      this.loading = true;
      this._refresh()
        .then((response) => {
          this.list = response.items;
          this.total = response.totalCount;
        })
        .finally(() => (this.loading = false));
    },
    getSelected() {
      return this.$refs.table.getCheckboxRecords();
    },
  },
  created() {
    if (!this.lazyFetch) this.refresh();
  },
};
</script>

<style lang="scss">
.config-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  .el-table {
    flex: 1;
  }
}
</style>
