<template>
  <el-button :type="unProcess?'danger':'primary'"
    v-if="visible" @click="handleApprove"
    :loading="loading" size="medium">
    {{text}}
  </el-button>
</template>

<script>
import { getInstance, commit } from "@/api/uxretail/sys/approvalInstance.js";
import ApproveDialog from "./approveDialog.vue";
export default {
  name: "ApproveButton",
  props: {
    code: {}, //流程编号
    objectId: {}, //流程对象id
    unProcess: { default: false },
    preText: { default: "" },
  },
  data() {
    return {
      instance: null,
      allowApprove: false,
      loading: false,
    };
  },
  methods: {
    refresh() {
      this.fetchInstance();
    },
    fetchInstance() {
      getInstance({
        flowTypeCode: this.code,
        objectId: this.objectId,
        unProcess: this.unProcess,
      })
        .then((res) => {
          this.instance = res.instance;
          this.allowApprove = res.allowApprove;
        })
        .catch(() => {
          this.instance = null;
        });
    },
    handleApprove() {
      this.$dialog
        .open(ApproveDialog, {
          instance: this.instance,
          text: this.text,
          allowApprove: this.allowApprove,
          unProcess: this.unProcess,
        })
        .then((commitInput) => {
          this.loading = true;
          commit(commitInput)
            .then((res) => {
              this.$notify.success(
                this.text +
                  (commitInput.approve
                    ? this.l("Success")
                    : this.l("RejectSuccess"))
              );
              this.instance = res.instance;
              this.allowApprove = res.allowApprove;
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
    preTextL(text) {
      return this.l(this.preText + text);
    },
  },
  watch: {
    objectId() {
      if (this.objectId) {
        this.fetchInstance();
      } else {
        this.instance = null;
      }
    },
    instance() {
      this.$emit("change", this.instance);
    },
  },
  created() {
    if (this.objectId) {
      this.fetchInstance();
    }
  },
  computed: {
    visible() {
      if (this.instance) {
        if (!this.allowApprove && this.instance.status == "START") {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    text() {
      if (this.instance) {
        if (this.instance.status == "START") {
          return this.unProcess
            ? this.preTextL("UnPost")
            : this.preTextL("Commit");
        } else if (this.instance.status == "PROCESSING") {
          if (!this.allowApprove) {
            return this.unProcess
              ? this.preTextL("UnApproveView")
              : this.preTextL("ApproveView");
          }
          return this.unProcess
            ? this.preTextL("UnApprove")
            : this.preTextL("Approve");
        } else if (this.instance.status == "REJECT") {
          return this.unProcess
            ? this.preTextL("UnApproveReCommit")
            : this.preTextL("ApproveReCommit");
        } else if (this.instance.status == "CANCEL") {
          return this.unProcess
            ? this.preTextL("UnApproveCancelled")
            : this.preTextL("ApproveCancelled");
        } else if (this.instance.status == "END") {
          return this.unProcess
            ? this.preTextL("UnPosted")
            : this.preTextL("Posted");
        }
      }
      return "";
    },
  },
};
</script>

<style>
</style>