<template>
  <vue-draggable-resizable class="ux-drag-item" @dragstop="onDragStop" @resizestop="onResizeStop"
    :resizable="opts.resizable" drag-handle=".ux-drag-item-move" :grid="opts.grid" :x="currentLayoutItem.x"
    :y="currentLayoutItem.y" :w="currentLayoutItem.w" :h="currentLayoutItem.h" :active="active" v-if="editing"
    v-show="visible">
    <div class="ux-drag-item-move">
      <i class="iconfont icon-drag"></i>
    </div>
    <div class="ux-drag-item-content">
      <!-- <div class="ux-drag-item-disabled-block"></div> -->
      <slot></slot>
    </div>
    <div class="ux-drag-item-append">
      <div class="ux-drag-item-append-top">
        <div class="ux-drag-item-tabIndex">
          <input v-model="currentLayoutItem.tabIndex" @keyup="handleKeyup" style="width:30px;" />
        </div>
        <div class="ux-drag-item-hidden" v-if="!required">
          <a @click="visible = false">
            <i class="iconfont icon-jianhao4"></i>
          </a>
        </div>
      </div>
      <div>
        <span class="point">({{
            currentLayoutItem.x +
            currentLayoutItem.w +
            "," +
            (currentLayoutItem.y + currentLayoutItem.h)
          }})</span>
      </div>
    </div>
  </vue-draggable-resizable>
  <div v-else v-show="visible" :style="viewStyle">
    <div class="field-padding">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from "./vue-draggable-resizable/vue-draggable-resizable.vue";
import "./vue-draggable-resizable/vue-draggable-resizable.css";

export default {
  components: { "vue-draggable-resizable": VueDraggableResizable },
  name: "ux-drag-item",
  props: {
    required: { default: false },
    caption: { default: "" },
    prop: { default: "" },
    zIndex: { default: 0 },
  },
  data() {
    return {
      opts: {
        grid: [10, 10],
        resizable: true,
      },
      editing: false,
      visible: this.required ? true : false,
      currentLayoutItem: { x: 0, y: 0, w: 280, h: 70, tabIndex: 0 },
      label: this.caption,
      active: false,
      currentProp: this.prop,
    };
  },
  computed: {
    viewStyle() {
      return {
        position: "absolute",
        left: this.currentLayoutItem.x + "px",
        top: this.currentLayoutItem.y + "px",
        width: this.currentLayoutItem.w + "px",
        height: this.currentLayoutItem.h + "px",
        zIndex: this.visible ? (this.zIndex > 0 ? this.zIndex : 1) : 0,
      };
    },
  },
  methods: {
    handleKeyup(e) {
      this.currentLayoutItem.tabIndex = this.currentLayoutItem.tabIndex.replace(
        /[-][^\d]/g,
        ""
      );
    },
    onDragStop(x, y) {
      this.currentLayoutItem.x = x;
      this.currentLayoutItem.y = y;
    },
    onResizeStop(x, y, w, h) {
      this.currentLayoutItem.x = x;
      this.currentLayoutItem.y = y;
      this.currentLayoutItem.w = w;
      this.currentLayoutItem.h = h;
    },
    findInstance(slot) {
      if (!slot.componentInstance) {
        return null;
      }

      var hasValueProperty = false;
      for (var p in slot.componentInstance) {
        if (p == "value") {
          hasValueProperty = true;
          break;
        }
      }
      if (hasValueProperty) {
        return slot.componentInstance;
      }

      if (slot.componentInstance) {
        if (slot.componentInstance.$slots.default) {
          return this.findInstance(slot.componentInstance.$slots.default[0]);
        } else if (slot.componentInstance.$slots.$scopedSlots) {
          return this.findInstance(
            slot.componentInstance.$scopedSlots.default[0]
          );
        }
      }
      return null;
    },
    setTabIndex() {
      if (this.$slots.default) {
        let slot = this.$slots.default[0];
        var comInstance = this.findInstance(slot);
        if (comInstance) {
          // comInstance.$el.tabIndex = this.currentLayoutItem.tabIndex;
          // console.log(comInstance.$el)
        }
        // var editors = $(slot.elm).find("input,checkbox,radio");
        // if (editors && editors.length > 0) {
        //   $(editors[0]).attr("tabindex", this.currentLayoutItem.tabIndex);
        //   console.log(editors[0]);
        // for (var i = 0; i < editors.length; i++) {
        //   $(editors[0]).attr("tabindex", this.currentLayoutItem.tabIndex);
        //   console.log(editors[i])
        // }
        // }
        // console.log(slot)
        // let inputInstance = null;
        // if (slot.componentOptions) {
        //   if (slot.componentOptions.tag == "ux-form-item") {
        //     //找下级
        //     if (slot.componentInstance.$slots.default) {
        //       let slot2 = slot.componentInstance.$slots.default[0];
        //       if (slot2.componentInstance.useUxTabindex) {
        //           inputInstance = slot2.componentInstance;
        //       }
        //     }
        //   } else if (slot.componentOptions.tag != "el-tabs") {
        //     //直接设置
        //     inputInstance = this.$slots.default[0].componentInstance;
        //   }
        // }

        // if (inputInstance) {
        //   if (inputInstance.$el) {
        //     inputInstance.$el.tabIndex = this.currentLayoutItem.tabIndex;
        //     // console.log(inputInstance)
        //   }
        // }
      }
    },
  },
  // directives: {
  //     tabindex: {
  //         componentUpdated(el, binding, vnode) {
  //             console.log(this);
  //             let v = binding.value;
  //             $(el)
  //                 .find("input,checkbox,select,radio")
  //                 .attr("tabindex", v);
  //         }
  //     }
  // },
  // updated() {
  //     this.$nextTick(() => {
  //         console.log("update");
  //         if (!this.$slots.default) {
  //             console.error(this);
  //             return;
  //         }
  //         //这里需要多次$nextTick  暂定4次  可能自定义的层级属性过多
  //         let slotElement = this.$slots.default[0].elm;
  //         if (slotElement) {
  //             $(slotElement)
  //                 .find("input,checkbox,select,radio")
  //                 .change(function() {
  //                     this.attr("tabindex", this.currentLayoutItem.tabIndex);
  //                 });
  //         }
  //     });
  // },
  mounted() {
    this.$nextTick(() => {
      if (!this.$slots.default) {
        console.error(this);
        return;
      }
      if (!this.label) {
        this.label = this.$slots.default[0].componentInstance
          ? this.$slots.default[0].componentInstance.label
          : "";
        this.currentProp = this.$slots.default[0].componentInstance
          ? this.$slots.default[0].componentInstance.prop
          : "";
      }

      this.setTabIndex();

      this.eventBus.$emit(
        (this.editing ? "editing." : "") + "dragDialog.addField",
        this
      );
    });
  },
  watch: {
    currentLayoutItem() {
      this.setTabIndex();
    },
    "currentLayoutItem.tabIndex"() {
      this.setTabIndex();
    },
  },
  beforeDestroy() {
    // abp.event.trigger((this.editing ? 'editing.' : '') + 'dragDialog.removeField', this);
  },
  created() {
    if (this.$root.$options.name == "edit-layout-dialog") {
      this.editing = true;
    }
  },
};
</script>
 