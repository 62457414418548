import request from '@/utils/request'

// 查询Bilcustomerreturn列表
export function listReportDataSource({ keyword }) {
    return request({
        url: '/uxretail/reportDataSource/list',
        method: 'post',
        data: { keyword }
    })
}

// 导出Bilcustomerreturn
export function exportReportDataSource({ keyword }) {
    return request({
        url: '/uxretail/reportDataSource/export',
        method: 'post',
        data: { keyword }
    })
}

// 查询Bilcustomerreturn列表
export function pageReportDataSource({ keyword }, { pageNum = 1, pageSize = 20, orderByColumn, isAsc }) {
    return request({
        url: '/uxretail/reportDataSource/page',
        method: 'post',
        data: { keyword },
        params: { pageNum, pageSize, orderByColumn, isAsc }
    })
}
// 查询Bilcustomerreturn详细
export function infoReportDataSource(id, code) {
    return request({
        url: '/uxretail/reportDataSource/info',
        method: 'post',
        data: { id,code }
    })
}

// 新增Bilcustomerreturn
export function saveReportDataSource(data) {
    return request({
        url: '/uxretail/reportDataSource/save',
        method: 'post',
        data: data
    })
}

// 删除Bilcustomerreturn
export function deleteReportDataSource(id) {
    return request({
        url: '/uxretail/reportDataSource/delete',
        method: 'post',
        data: { id }
    })
}

// 新增Bilcustomerreturn
export function cancelReportDataSource(id) {
    return request({
        url: '/uxretail/reportDataSource/cancel',
        method: 'post',
        data: { id }
    })
}
export function postReportDataSource(id) {
    return request({
        url: '/uxretail/reportDataSource/post',
        method: 'post',
        data: { id }
    })
}
// 新增Bilcustomerreturn
export function confirmReportDataSource(data) {
    return request({
        url: '/uxretail/reportDataSource/confirm',
        method: 'post',
        data: data
    })
}

// 查询Bilcustomerreturn列表
export function listActive() {
    return request({
        url: '/uxretail/reportDataSource/listActive',
        method: 'post',
        data: {}
    })
}