<template>
  <el-input :loading="loading"
    :placeholder="placeholder"
    :readonly="readonly"
    @change.native="handleChange"
    @blur="$emit('blur')"
    :disabled="inputDisabled"
    v-model="currentValue" :maxlength="maxlength">
  </el-input>
</template>

<script>
import baseCodeGen from "./baseCodeGen";
export default {
  props: {
    id: {},
    value: {},
    placeholder: {},
    readonly: { default: false },
    disabled: { default: false },
    maxlength: { default: 256 },
  },
  mixins: [baseCodeGen],
  name: "ux-code-input",
  data() {
    return {
      currentValue: this.value,
    };
  },
  methods: {
    handleChange(e) {
      this.autoGen = false;
    },
  },
  watch: {
    currentValue() {
      // this.value = this.currentValue;
      this.$emit("input", this.currentValue);
    },
    value() {
      this.currentValue = this.value;
    },
  },
  computed: {
    inputDisabled() {
      return (
        this.disabled || (this.$parent.elForm || {}).disabled || this.id > 0
      );
    },
  },
};
</script>

<style>
</style>