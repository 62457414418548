<script>
import draggable from "vuedraggable";
import render from "@/utils/generator/render";

const layouts = {
  colFormItem(h, element, index, value, parent) {
    return (
      <el-col span={element.span}>
        <ux-form-item
          label-width={element.labelWidth ? `${element.labelWidth}px` : null}
          label={element.label}
          required={element.required}
          prop={element.vModel}
        >
          <render
            key={element.renderKey}
            conf={element}
            value={value}
            onInput={(event) => {
              // console.log(event)
              this.$set(element, "value", event);
              this.$emit("input", event);
              // console.log(element)
              // this.$emit('input',event)
            }}
          />
        </ux-form-item>
      </el-col>
    );
  },
  rowFormItem(h, element, index, value, parent) {
    let child = renderChildren.apply(this, arguments);
    if (element.type === "flex") {
      child = (
        <el-row
          type={element.type}
          justify={element.justify}
          align={element.align}
        >
          {child}
        </el-row>
      );
    }
    return (
      <el-col span={element.span}>
        <el-row
          gutter={element.gutter}
          nativeOnClick={(event) => {
            activeItem(element);
            event.stopPropagation();
          }}
        >
          <span class="component-name">{element.componentName}</span>
          {child}
        </el-row>
      </el-col>
    );
  },
};

function renderChildren(h, element, index, parent) {
  if (!Array.isArray(element.children)) return null;
  return element.children.map((el, i) => {
    const layout = layouts[el.layout];
    if (layout) {
      return layout.call(this, h, el, i, element.children);
    }
    return layoutIsNotFound();
  });
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.element.layout}匹配的layout`);
}

export default {
  components: {
    render,
  },
  props: ["element", "index", "drawingList", "activeId", "formConf", "value"],
  render(h) {
    const layout = layouts[this.element.layout];

    if (layout) {
      return layout.call(
        this,
        h,
        this.element,
        this.index,
        this.value,
        this.drawingList
      );
    }
    return layoutIsNotFound();
  },
};
</script>
