import { FormItem } from 'element-ui'
import AsyncValidator from 'async-validator';
import emitter from 'element-ui/src/mixins/emitter';
import objectAssign from 'element-ui/src/utils/merge';
import { noop, getPropByPath } from 'element-ui/src/utils/util'; 
export default {
    name: "ux-form-item",
    props: {
        validateImmediate: { default: false }
    },
    mixins: [FormItem],
    methods: {
        validate(trigger, callback = noop) {
            if (trigger && !this.validateImmediate) {
                return;
            }
            this.validateDisabled = false;
            const rules = this.getFilteredRule(trigger);
            if ((!rules || rules.length === 0) && this.required === undefined) {
                callback();
                return true;
            }

            this.validateState = 'validating';

            const descriptor = {};
            if (rules && rules.length > 0) {
                rules.forEach(rule => {
                    delete rule.trigger;
                });
            }
            descriptor[this.prop] = rules;

            const validator = new AsyncValidator(descriptor);
            const model = {};

            model[this.prop] = this.fieldValue;

            validator.validate(model, { firstFields: true }, (errors, invalidFields) => {
                this.validateState = !errors ? 'success' : 'error';
                this.validateMessage = errors ? errors[0].message : '';

                callback(this.validateMessage, invalidFields);
                this.elForm && this.elForm.$emit('validate', this.prop, !errors, this.validateMessage || null);
            });
        },
    }
}