<template>
  <n-dialog-layout :title="l('Filter')"
    ref="dialog">
    <filter-panel :reportName="reportName"
      :filters="filters" ref="filterPanel">
    </filter-panel>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel()">{{l('Cancel')}}
      </el-button>
      <el-button type="primary" @click="ok()">
        {{l('Confirm')}}</el-button>
    </div>
  </n-dialog-layout>
</template>
<script>
import FilterPanel from "./filterPanel.vue";
import store from "@/store";
import SqlString from "sqlstring";
import ReportUtil from "@/components/Report/reportUtil.js";
export default {
  components: {
    FilterPanel,
  },
  data() {
    return {
      filters: [],
      reportName: "",
      sql: "",
    };
  },
  methods: {
    open({ filters, reportName, sql }) {
      this.filters = filters;
      this.reportName = reportName;
      this.sql = sql;
      return this.$refs.dialog.open();
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
    ok() {
      this.$refs.filterPanel.getData().then((res) => {
        //sql
        var sql = this.sql || "";
        var rVarArray = ReportUtil.getVariables();

        for (var i = 0; i < rVarArray.length; i++) {
          sql = sql.replaceAll(
            "#" + rVarArray[i].name + "#",
            rVarArray[i].value
          );
        }
        var filterSql = "";
        res.sqlParameters.forEach((t) => {
          if (t.value || t.value === 0) {
            filterSql = t.valueSql.replaceAll("{value}", this.$ux.util.stripscript(t.value));
          } else {
            filterSql = t.noneValueSql.replaceAll("{value}", this.$ux.util.stripscript(t.value));
          }
          sql = sql.replaceAll("{" + t.name + "}", filterSql);
        });
        this.$refs.dialog.close({ filters: res.sqlParameters, sql: sql });
      });
    },
  },
};
</script>
