<template>
<div class="ux-drag-layout full">
    <!--function content-->
    <slot></slot>
    <!--contextmenu-->
    <div v-loading="deleting" class="ux-drag-layout-contextmenu" :style="{ left: x + 'px', top: y + 'px' }" v-show="visible" ref="contextmenu">
        <ul>
            <template v-if="publicLayouts.length > 0">
                <li>
                    <span class="group-title">{{ l("Public") }}</span>
                </li>
                <li v-for="layout in publicLayouts" :key="layout.id" class="group-layout-item" :class="[selectedLayout?(layout.id == selectedLayout.id ? 'selected' : ''):'']">
                    <a class="group-layout-title" @click="setCurrentLayout(layout)">
                        {{ layout.layoutName }}
                        <small v-if="layout.isDefault">[{{ l("Default") }}]</small>
                    </a>
                    <a v-if="layout.creatorUserId == $store.state.user.id && !layout.isSystem" class="group-layout-remove-button" @click="deleteLayout(layout)">{{ l("Delete") }}</a>
                </li>
            </template>
            <template v-if="myLayouts.length > 0">
                <li class="m-t">
                    <span class="group-title">{{ l("My") }}</span>
                </li>
                <li v-for="layout in myLayouts" :key="layout.id" class="group-layout-item" :class="[selectedLayout?(layout.id == selectedLayout.id ? 'selected' : ''):'']">
                    <a class="group-layout-title" @click="setCurrentLayout(layout)">
                        {{ layout.layoutName }}
                        <small v-if="layout.isDefault">[{{ l("Default") }}]</small>
                    </a>
                    <a v-if="layout.creatorUserId == $store.state.user.id" class="group-layout-remove-button" @click="deleteLayout(layout)">{{ l("Delete") }}</a>
                </li>
            </template>
        </ul>
        <div class="ux-drag-layout-contextmenu-footer text-right">
            <el-button type="text" @click="create">{{ l("New") }}</el-button>
            <el-button type="text" @click="edit" :disabled="!hasEditPermission">{{
          l("Edit")
        }}</el-button>
            <el-button type="text" @click="doCopy">{{ l("Copy") }}</el-button>
        </div>
    </div>
</div>
</template>

<script>
import {
    checkPermi,
    checkRole
} from "@/utils/permission"; // 权限判断函数

import editDragLayoutDialog from "./editDragLayoutDialog.vue";
import {
    deleteSysDragLayout
} from "@/api/uxretail/sys/sysDragLayout.js";
export default {
    name: "ux-drag-layout",
    components: {},
    data() {
        return {
            fields: [],
            selectedLayout: null,
            visible: false,
            x: 0,
            y: 0,
            deleting: false,
            dragLayouts: this.getDragLayouts(),
        };
    },
    methods: {
        getDragLayouts() {
            return this.$store ?
                this.$store.state.dragLayout.routerName2DragLayouts[
                    this.$route.name
                ] || [] : [];
        },
        setCurrentLayout(layout) {
            if (!layout) {
                this.editLayout("create");
                return;
            }
            this.visible = false;
            this.selectedLayout = layout;
            let layoutJson = JSON.parse(layout.layoutJson);
            this.fields.forEach((field, index) => {
                let existField = layoutJson.find((t) => t.prop == field.currentProp);
                if (existField) {
                    field.visible = existField.visible;
                    field.currentLayoutItem = existField.layoutItem;
                    if (!field.currentLayoutItem.tabIndex) {
                        field.currentLayoutItem.tabIndex = index + 1;
                    }
                }
            });
        },
        async deleteLayout(layout) {
            this.$confirm(this.l("SureDelete")).then(async () => {
                try {
                    this.deleting = true;
                    await deleteSysDragLayout(layout.id);
                    this.$store.commit("dragLayout/removeLayout", {
                        layout,
                        name: this.$route.name,
                    });
                    this.dragLayouts = this.getDragLayouts();
                } finally {
                    this.deleting = false;
                }
                if (this.selectedLayout == layout) {
                    this.selectedLayout = null;
                    this.setCurrentLayout(this.getDefaultLayout());
                }
            });
        },
        create() {
            this.editLayout("create");
        },
        edit() {
            this.editLayout("edit");
        },
        doCopy() {
            this.editLayout("copy");
        },
        editLayout(action) {
            this.visible = false;
            var layoutDto = Object.assign({}, this.selectedLayout);
            if (action == "create") {
                layoutDto = {};
            } else if (action == "copy") {
                layoutDto.id = null;
                layoutDto.isDefault = false;
                layoutDto.isPublic = false;
                layoutDto.isSystem = false;
                layoutDto.layoutName = "";
            }
            let input = {
                component: this.$route.matched.find((t) => t.name == this.$route.name)
                    .components.default,
                routeName: this.$route.name,
                layout: layoutDto,
            };
             
            this.$dialog
                .open(editDragLayoutDialog, input)
                .then((layout) => {
                    if (!layoutDto.id) {
                        this.$store.commit("dragLayout/addOrUpdateLayout", {
                            layout,
                            name: this.$route.name,
                        });
                        this.dragLayouts = this.getDragLayouts();
                    } else {
                        if (layout.isDefault) {
                            this.dragLayouts.forEach((t) => {
                                if (t.isPublic == layout.isPublic) {
                                    t.isDefault = false;
                                }
                            });
                        }
                        this.selectedLayout.layoutJson = layout.layoutJson;
                        this.selectedLayout.isDefault = layout.isDefault;
                        this.selectedLayout.isPublic = layout.isPublic;
                        this.selectedLayout.layoutName = layout.layoutName;
                    }
                    this.setCurrentLayout(layout);
                })
                .catch(() => {});
        },
        _getQueryLayout() {
            let layout;
            if (this.$route.query.layout) {
                layout = this.dragLayouts.find(
                    (t) => t.layoutName == this.$route.query.layout
                );
                if (!layout) {
                    this.$message.warn(this.l("LayoutNotExist"));
                }
            }
            return layout;
        },
        initLayout() {
            if (this.dragLayouts && this.dragLayouts.length > 0) {
                //先取选中  再取个人的（非公有） 再取公用的  再取第一个
                let layout = this._getQueryLayout();
                if (!layout) {
                    layout = this.getDefaultLayout();
                }
                this.selectedLayout = layout;
            } else {
                this.editLayout("create");
            }
            this.eventBus.$on("dragDialog.addField", this.addField);
        },
        getDefaultLayout() {
            let layout =
                this.selectedLayout ||
                this.dragLayouts.find((t) => t.isDefault && !t.isPublic) ||
                this.dragLayouts.find((t) => t.isDefault && t.isPublic) ||
                this.dragLayouts[0];
            return layout;
        },
        addField(field) {
            let layoutJson = this.selectedLayout ?
                JSON.parse(this.selectedLayout.layoutJson) : [];

            if (!field.currentLayoutItem.tabIndex) {
                field.currentLayoutItem.tabIndex = this.fields.length + 1;
            }

            this.fields.push(field);
            let existField = layoutJson.find((t) => t.prop == field.currentProp);
            if (existField) {
                field.visible = existField.visible;
                field.currentLayoutItem = existField.layoutItem;
            }
        },
        nextFocus() {
            let next = null;
            if (this.currentForcus) {
                let nexts = this.sortedFields.filter(
                    (t) =>
                    t.currentLayoutItem.tabIndex >
                    this.currentForcus.currentLayoutItem.tabIndex
                );
                if (nexts.length > 0) {
                    next = nexts[0];
                }
            }

            if (!next) {
                next = this.sortedFields[0];
            }

            next.$el.focus();
            this.currentForcus = next;
        },
    },
    computed: {
        sortedFields() {
            return this.fields.sort(
                (t1, t2) => t1.currentLayoutItem.tabIndex < t2.tabIndex
            );
        },
        tabIndexByPropertyName() {
            let prop2tabIndex = {};
            this.fields.forEach((element) => {
                prop2tabIndex[element.currentProp] = element.currentLayoutItem.tabIndex;
            });
            return prop2tabIndex;
        },
        hasEditPermission() {
            if (this.selectedLayout) {
                if (this.selectedLayout.isSystem) {
                    return false;
                } else if (this.selectedLayout.isPublic) {
                    return true; //checkPermi(["app.publicDragLayout"]);
                    //return abp.auth.isGranted("Pages.App.PublicDragLayout");
                } else {
                    return true;
                }
            }
            return true;
        },
        publicLayouts() {
            return this.dragLayouts.filter((t) => t.isPublic);
        },
        myLayouts() {
            return this.dragLayouts.filter((t) => !t.isPublic);
        },
    },
    created() {
        if (this.$root.$options.name == "edit-layout-dialog") {
            this.editing = true;
        } else {
            this.initLayout(); //初始化布局
        }
    },
    mounted() {
        if (!this.editing) {
            this.$el.addEventListener("contextmenu", (e) => {
                var e = e || window.event;
                e.preventDefault();
                this.x =
                    e.clientX + 230 > window.innerWidth ?
                    window.innerWidth - 230 :
                    e.clientX;
                this.y = e.clientY;
                this.visible = true;
            });

            this.$el.addEventListener("click", (e) => {
                var e = e || window.event;
                this.visible = false;
            });
            this.$nextTick(() => {
                this.$refs.contextmenu.addEventListener("click", (e) => {
                    var e = e || window.event;
                    e.stopPropagation();
                });
            });
        }
    },
    destroyed() {
        this.$off("dragDialog.addField", this.addField);
    },
    watch: {
        $route(to, from) {
            if (to.query.layout != from.query.layout) {
                if (this.$root.$options.name == "edit-layout-dialog") {
                    this.editing = true;
                } else {
                    console.log('router change')
                    this.selectedLayout = null;

                    let layout = this._getQueryLayout() || this.getDefaultLayout();

                    if (layout) {
                        this.setCurrentLayout(layout);
                    }
                }
            }
        },
    },
    // beforeRouteUpdate(to, from, next) {
    //     console.log("beforeRouteUpdate");
    //     if (this.$root.$options.name == "edit-layout-dialog") {
    //         this.editing = true;
    //     } else {
    //         this.initLayout(); //初始化布局
    //     }
    // }
};
</script>

<style lang="scss">
@import "~@/assets/styles/element-variables.scss";

.ux-drag-layout {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .field-padding {
        padding: 0 10px;
        height: 100%;
    }

    &-contextmenu {
        background-color: #fff;
        width: 200px;
        min-height: 150px;
        position: fixed;
        z-index: 999;
        transform-origin: center top;
        padding: 10px;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .group-title {
        padding: 7px 0;
        line-height: normal;
        font-size: 12px;
        color: #909399;
    }

    .group-layout-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .group-layout-item.selected {
        .group-layout-title {
            color: $--color-primary;
        }
    }

    .group-layout-title {
        padding: 7px 0;
        line-height: normal;
        font-size: 14px;
        color: #333;
        display: block;

        small {
            line-height: 14px;
            color: #999;
        }
    }

    .group-layout-remove-button {
        color: $--color-danger;
        font-size: 12px;
    }

    .group-layout-title:hover {
        color: $--color-primary;
    }

    .ux-current-layout {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 12px;
        color: #888;
    }

    .el-tabs {
        .el-tabs__content {
            position: relative;
            overflow: auto;
        }
    }
}

.ux-drag-item {
    border: 1px dashed #dd6e15;
    display: flex;
    position: relative;
    align-items: flex-start;

    &-disabled-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(245, 247, 250, 0.1);
        z-index: 99;
    }

    &-move {
        cursor: move;
        width: 35px;
        line-height: 32px;
        color: $--color-primary;
        z-index: 100;

        i {
            font-size: 22px !important;
        }
    }

    &-append {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        &-top {
            display: flex;
        }

        .point {
            font-size: 12px;
            line-height: 16px;
            color: $--color-primary;
            display: inline-block;
            overflow: visible;
            text-align: center;
            min-width: 60px;
            width: 100%;
        }
    }

    &-tabIndex {
        line-height: 32px;
        z-index: 100;
        margin-right: 2px;
        display: flex;
        flex-direction: column;

        input {
            width: 20px;
            background-color: transparent;
            border: 1px solid $--color-primary;
            text-align: right;
            font-size: 13px;
            line-height: 25px;
            font-weight: 600;
            padding: 0 5px;
        }
    }

    &-hidden {
        z-index: 100;
        width: 35px;
        line-height: 32px;
        color: $--color-danger;

        i {
            font-size: 22px !important;
        }
    }

    &-content {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .handle {
        z-index: 100;
    }
}
</style>
