/* global _
  *
  * vm.$util
  *
  * */
import Vue from 'vue'
import domtoimage from "dom-to-image";
import moment from "moment";

var util = {}

util.guid = () => {
  var guid = "";
  for (var i = 1; i <= 32; i++) {
    var n = Math.floor(Math.random() * 16.0).toString(16);
    guid += n;
    if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
  }
  return guid;
}

util.stripscript = (param) => {
  var _reg = /select |update |delete |truncate |join |union |exec |insert |drop |count | and | or |mid |from |=|add |&|-- |>|<|%/g;
  return (param + "").replaceAll('\'', '"').replaceAll(_reg, "");
}

util.domtoimage = async (container) => {
  var el = container.querySelector('.ql-editor')
  el.style.overflow = "visible"
  try {
    var dataUrl = await domtoimage.toPng(el, {
      quality: 0.95,
      bgcolor: "#fff",
      width: el.clientWidth,
    });
  } finally {
    el.style.overflow = "auto"
  }
  return dataUrl.replace("data:image/png;base64,", "");
}

util.today = () => {
  return moment().startOf('day')
}

export default util;
