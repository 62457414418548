<template>
    <el-dialog
        id="panelModal"
        :visible.sync="show"
        class="panel-modal"
        show-close
        @opened="handleOpened()"
    >
        <div class="panel-title" slot="title">
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sort-amount-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="svg-inline--fa fa-sort-amount-down fa-w-16 fa-1x"
            >
                <path
                    fill="currentColor"
                    d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-128-64h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm256-192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                    class
                />
            </svg>
            <span>{{l('SortingAndFiltering')}}</span>
        </div>
        <div ref="panelFilter" class="panel-body">
            <div class="panel-content">
                <div class="panel-action">
                    <div>
                        <el-input
                            class="input-with-select"
                            v-model="inputFilter"
                            ref="input"
                            @keydown.native.enter="doFilter"
                        >
                            <el-select
                                v-model="inputFilterCondition"
                                slot="prepend"
                                @change="handleFilterConditionChange"
                                style="width:250px !important;"
                            >
                                <el-option
                                    v-for="fc in filterConditions"
                                    :label="fc.text"
                                    :value="fc.value"
                                    :key="fc.value"
                                ></el-option>
                            </el-select>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-footer" slot="footer">
            <el-button class="panel-button" type="primary" @click="doFilter">{{l('Apply')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            columnFilterRef: null,
            inputFilter: "",
            inputFilterCondition: "",
            filterConditions: [
                { text: this.l("Contains"), value: "" },
                { text: this.l("Match"), value: "=" },
                { text: this.l("NotMatch"), value: "<>" },
                { text: this.l("GreaterThan"), value: ">" },
                { text: this.l("GreaterThanOrEqualTo"), value: ">=" },
                { text: this.l("lessThan"), value: "<" },
                { text: this.l("lessThanOrEqualTo"), value: "<=" },
                { text: this.l("regularExpression"), value: "~" }
            ]
        };
    },
    computed: {
        symbol() {
            switch (this.inputFilterCondition) {
                case "":
                    return "≒";
                case "<=":
                    return "≤";
                case ">=":
                    return "≥";
                case "<>":
                    return "≠";
                default:
                    return this.inputFilterCondition;
            }
        }
    },
    methods: {
        showPanel(ref) {
            this.show = true;
            this.columnFilterRef = ref;
            this.columnFilterRef.$el.textContent = this.inputFilter = this.inputFilterCondition =
                "";
            this.columnFilterRef.$emit("input", "");
        },
        handleFilterConditionChange() {
            this.$refs.input.focus();
        },
        handleOpened() {
            this.$refs.input.focus();
        },
        doFilter() {
            const opt = this.inputFilterCondition + this.inputFilter;
            this.columnFilterRef.$el.textContent = opt;
            this.columnFilterRef.$emit("input", opt);
            this.show = false;
        }
    }
};
</script>
<style scoped lang="scss">
.panel-modal {
    .panel-title {
        display: flex;
        font-size: 20px;
    }
    svg {
        width: 20px;
        margin-right: 5px;
    }
}
</style>
 