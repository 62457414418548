<template>
  <n-dialog-layout :title="l('Import')"
    ref="dialog">
    <import-function :templateId="id" :code="code"
      :parameterItems="parameterItems"
      ref="importFunction"
      @step-change="handleStep"></import-function>
    <div slot="footer" class="dialog-footer"
      v-if="step==2">
      <el-button @click="cancel()">{{l('Cancel')}}
      </el-button>
      <el-button type="primary" @click="ok()">
        {{l('Confirm')}}</el-button>
    </div>
  </n-dialog-layout>
</template>

<script>
import ImportFunction from "./index.vue";
export default {
  components: {
    ImportFunction,
  },
  data() {
    return {
      code: null,
      id: null,
      parameterItems: {},
      step: 0,
    };
  },
  methods: {
    handleStep(step) {
      this.step = step;
    },
    open({ id, code, parameterItems }) {
      this.id = id;
      this.code = code;
      this.parameterItems = parameterItems;
      return this.$refs.dialog.open();
    },
    ok() {
      this.$refs.dialog.close(this.$refs.importFunction.getClientData());
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
  },
};
</script>
