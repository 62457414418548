<script>
import VFrame from "@/framework/VFrame.js";
import { NodeUtils } from "./util.js";
const isCondition = (data) => data.type === "condition";
const notEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;
const hasBranch = (data) => notEmptyArray(data.conditionNodes);
const stopPro = (ev) => ev.stopPropagation();

function createNormalCard(ctx, conf, h) {
  const classList = ["flow-path-card"];
  const afterTrue = (isTrue, name) => (isTrue && classList.push(name), isTrue);
  const isStartNode = afterTrue(NodeUtils.isStartNode(conf), "start-node");
  const isApprNode = afterTrue(NodeUtils.isApproverNode(conf), "approver");
  const isCopyNode = afterTrue(NodeUtils.isCopyNode(conf), "copy");
  let content = "";

  if (NodeUtils.isStartNode(conf) || NodeUtils.isApproverNode(conf)) {
    switch (conf.properties.assigneeType) {
      case "role":
      case "user":
        content =
          conf.properties.approvers && conf.properties.approvers.length > 0
            ? conf.properties.approvers.map((t) => t.name).join(",")
            : NodeUtils.isStartNode(conf)
            ? VFrame.l("Everyone")
            : VFrame.l("EnterApprover");
        break;
      case "director":
        content = this.l("Director");
        break;
      case "optional":
        content = this.l("SponsorOptional");
        break;
      default:
        content = NodeUtils.isStartNode(conf)
          ? VFrame.l("Everyone")
          : VFrame.l("EnterApprover");
        break;
    }
  }

  if (NodeUtils.isCopyNode(conf))
    content =
      conf.properties.members && conf.properties.members.length > 0
        ? conf.properties.members.map((t) => t.name).join(",")
        : VFrame.l("EnterCopyTo");

  if (conf.nodeId == this.activeNodeId) {
    classList.push("active-node");
    this.activeNodeFound = true;
  } else {
    if (this.activeNodeFound) {
      //后面的都是未开始的流程
    } else {
      //已完成的流程
      classList.push("finish-node");
    }
  }

  return (
    <section class={classList.join(" ")}>
      <header class="header">
        <div class="title-box" style="height: 100%;width:190px;">
          {isApprNode && (
            <i
              class="iconfont iconshenpi"
              style="font-size:12px;color:white;margin-right:4px;"
            ></i>
          )}
          {isCopyNode && (
            <i
              class="el-icon-s-promotion"
              style="font-size:12px;color:white;margin-right:4px;"
            ></i>
          )}
          <span class="title-text">{conf.properties.title}</span>
        </div>
        <div class="actions" style="margin-right:4px;"></div>
      </header>
      <div class="body">
        <span class="text">{content}</span>
      </div>
    </section>
  );
}
// arg = ctx, data, h
const createFunc = (...arg) => createNormalCard.call(arg[0], ...arg);
let nodes = {
  start: createFunc,
  approver: createFunc,
  copy: createFunc,
  empty: (_) => "",
  condition: function (ctx, conf, h) {
    let content = VFrame.l("EnterConditions");
    if (conf.properties.conditions && conf.properties.conditions.length > 0) {
      content = conf.properties.conditions
        .map((t) => t.display + " " + t.operate + " " + t.value)
        .join("\r\n");
    }
    const classList = ["flow-path-card", "condition"];
    if (this.activeNodeFound) {
      //后面的都是未开始的流程
    } else {
      //已完成的流程
      classList.push("finish-node");
    }

    return (
      <section class={classList.join(" ")}>
        <header class="header">
          <div class="title-box" style="height: 20px;width:160px;">
            <span class="title-text">{conf.properties.title}</span>
          </div>
          <span class="priority">
            {this.l("Priority")}:{conf.properties.priority + 1}
          </span>
          <div class="actions"></div>
        </header>
        <div class="body">
          <pre class="text">{content}</pre>
        </div>
      </section>
    );
  },
};

function addNodeButton(ctx, data, h, isBranch = false) {
  // 只有非条件节点和条件分支树下面的那个按钮 才能添加新分支树
  let couldAddBranch = !hasBranch(data) || isBranch;
  let isEmpty = data.type === "empty";
  if (isEmpty && !isBranch) {
    return "";
  }
  return (
    <div class="add-node-btn-box flex  justify-center">
      <div class="add-node-btn">
        <el-popover placement="right" trigger="click" width="300">
          <div class="condition-box">
            <div>
              <div class="condition-icon">
                <i class="iconfont icon-shenpiren"></i>
              </div>
              {this.l("Approver")}
            </div>

            <div>
              <div class="condition-icon">
                <i
                  class="el-icon-s-promotion iconfont"
                  style="vertical-align: middle;"
                ></i>
              </div>
              {this.l("CC")}
            </div>

            <div>
              <div class="condition-icon">
                <i class="iconfont icon-fenzhijigou"></i>
              </div>
              {this.l("Condition")}
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  );
}

function NodeFactory(ctx, data, h) {
  if (!data) return;
  let res = [],
    branchNode = "",
    selfNode = (
      <div class="node-wrap">
        <div class={`node-wrap-box ${data.type}`}>
          <el-tooltip
            content={this.l("NoCondition")}
            placement="top"
            effect="dark"
          ></el-tooltip>
          {nodes[data.type].call(ctx, ctx, data, h)}
          {addNodeButton.call(ctx, ctx, data, h)}
        </div>
      </div>
    );

  if (hasBranch(data)) {
    // 如果节点是数组 一定为条件分支 添加分支样式包裹
    // {data.childNode && NodeFactory.call(ctx, ctx, data.childNode, h)}
    branchNode = (
      <div class="branch-wrap">
        <div class="branch-box-wrap">
          <div class="branch-box  flex justify-center relative">
            <button class="btn">{this.l("Conditions")}</button>
            {data.conditionNodes.map((d) => NodeFactory.call(ctx, ctx, d, h))}
          </div>
        </div>
        {addNodeButton.call(ctx, ctx, data, h, true)}
      </div>
    );
  }
  if (isCondition(data)) {
    return (
      <div class="col-box">
        <div class="center-line"></div>
        <div class="top-cover-line"></div>
        <div class="bottom-cover-line"></div>
        {selfNode}
        {branchNode}
        {NodeFactory.call(ctx, ctx, data.childNode, h)}
      </div>
    );
  }
  res.push(selfNode);
  branchNode && res.push(branchNode);
  data.childNode && res.push(NodeFactory.call(ctx, ctx, data.childNode, h));
  return res;
}

function addEndNode(h) {
  return <section class="end-node">End</section>;
}

export default {
  props: {
    data: { type: Object, required: true },
    // 点击发布时需要校验节点数据完整性 此时打开校验模式
    activeNodeId: {},
  },
  watch: {},
  methods: {
    /**
     *事件触发器 统筹本组件所有事件并转发到父组件中
     * @param { Object } 包含event（事件名）和args（事件参数）两个参数
     */
    eventLancher(event, ...args) {
      // args.slice(0,-1) vue 会注入MouseEvent到最后一个参数 去除事件对象
      let param = { event, args: args.slice(0, -1) };
      this.$emit("emits", param);
    },
  },
  created() {
    this.activeNodeFound = false;
  },
  render(h) {
    return (
      <div
        style="display: inline-flex; flex-direction: column; align-items: center;"
        class="flow-card-container"
      >
        {this.data && NodeFactory.call(this, this, this.data, h)}
        {addEndNode(h)}
      </div>
    );
  },
};
</script>

<style lang="stylus"  >
@import 'index.styl';

.flow-card-container {
  display: inline-block;
  background: $bg-color;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  overflow: auto;
}

@keyframes twinkling {
  /* 透明度由0到1 */
  0% {
    opacity: 0.5; /* 透明度为0 */
  }

  100% {
    opacity: 1; /* 透明度为1 */
  }
}

.active-node {
  -webkit-animation: twinkling 0.8s infinite ease-in-out;
  border: 3px solid #ff9431;
}

.finish-node {
  background-color: #dcdfe6 !important;
}
</style>