export default {
  //旗帜
  flag: [
    { key: "Company", value: 0 },
    { key: "Home", value: 1 }
  ],
  //性别
  age: [],
  autoCodeGenType: [
    { key: "Brand", value: "Brand" },
    { key: "Color", value: "Color" },
    { key: "SizeGroup", value: "SizeGroup" },
    { key: "Product", value: "Product" },
    { key: "Sku", value: "Sku" },
    { key: "Barcode", value: "Barcode" },
    { key: "ItemQualifier", value: "ItemQualifier" },
    { key: "Category", value: "Category" },
    { key: "Supplier", value: "Supplier" },
    { key: "Customer", value: "Customer" },
    { key: "CouponItemCode", value: "CouponItemCode" },
    { key: "CouponSerialNo", value: "CouponSerialNo" },
  ],
  //卡类型
  cardType: [
    { key: "None", value: 0 },
    { key: "AmericanExpress", value: 1 },
    { key: "ChinaUnionPay", value: 2 },
    { key: "ChinaUnionPayBankOfChina", value: 3 },
    { key: "DinersClub", value: 4 },
    { key: "DiscoverCard", value: 5 },
    { key: "EntrustBankcard", value: 6 },
    { key: "JapanCreditBureau", value: 7 },
    { key: "MasterCard", value: 8 },
    { key: "VisaCard", value: 9 },
    { key: "EPS", value: 10 },
    { key: "EPSNEReservedForFutureUse", value: 11 },
    { key: "10EPS", value: 12 },
  ],
  //付款类型
  paymentType: [
    { key: "Cash", value: 0 },
    { key: "Card", value: 1 },
    { key: "Other", value: 2 },
    { key: "applepay", value: 3 },
  ],
  //交易类型
  transactionType: [
    { key: "Sales", value: 0 },
    { key: "ReturnRefund", value: 1 },
    { key: "Deposit", value: 2 },
    { key: "UniformQuotaSponsorship", value: 3 },
    { key: "AlterationRepair", value: 4 },
    { key: "PreOrder", value: 5 },
    { key: "CouponReceipt", value: 6 },
  ],
  //其他类型
  otherType: [
    { key: "GEC", value: 1 },
    { key: "Octopus", value: 2 },
    { key: "Coupon", value: 3 },
    { key: "GiftCard", value: 8 },
    { key: "Cheque", value: 9 },
    { key: "MallCoupon", value: 11 },
    { key: "Alipay", value: 12 },
    { key: "WeChatPayment", value: 13 },
    { key: "CentBalance", value: 15 },
    { key: "CollectionVariance", value: 99 },
    { key: "AlipayOnlinePayment", value: 100 },
  ],
  //客户状态
  customerStatus: [
    { key: "Active", value: 0 },
    { key: "BlackList", value: 1 },
    { key: "CloseDown", value: 2 },
    { key: "Inactive", value: 3 },
    { key: "OneTime", value: 4 },
    { key: "Prospect", value: 5 },
  ],
  //ECR 类型
  ecrType: [
    { key: "EDC", value: 1 },
    { key: "EPS", value: 2 },
    { key: "CUP", value: 3 },
  ],
  //AutoGen
  autogen: [
    { key: "ItemPool", value: 44041003 },
    { key: "VipCard", value: 100303 },
    { key: "GoodsReturnSupplier", value: 4023 },
    { key: "GoodsReturnCustomer", value: 4024 },
    { key: "StockTransferOut", value: 4025 },
    { key: "StockTransferIn", value: 4026 },
    { key: "PromotionMaintenace", value: 5001 },
    { key: "PromPriceSetting", value: 5002 },
    { key: "Test-s002", value: 400231 },
    { key: "VipPointRedemptionDefines", value: 200002 },
    { key: "ItemQualifier", value: 44041002 },
    { key: "TimeQualifier", value: 44041001 },
    { key: "PaymentQualifier", value: 44041004 },
    { key: "AmtDiscountRule", value: 44041005 },
    { key: "QtyDiscountRule", value: 44041006 },
    { key: "PriceChange", value: 44041007 },
    { key: "PriceBookChange", value: 44041008 },
    { key: "PurchaseOrder", value: 300001 },
    { key: "VipPointRewardDefine", value: 200001 },
    { key: "PurchaseInvoice", value: 300002 },
    { key: "GoodsReceiveNote", value: 300003 },
    { key: "ShipmentMaster", value: 300004 },
    { key: "Transfer", value: 300005 },
    { key: "Product", value: 1003 },
    { key: "Sample", value: 10030 },
    { key: "SalesOrder", value: 40003 },
    { key: "ProductBarcode", value: 100301 },
    { key: "VipMaster", value: 100302 },
    { key: "SalesInvoice", value: 4001 },
    { key: "DeliveryNote", value: 4002 },
    { key: "ProformaInvoice", value: 4020 },
    { key: "StockAdjustment", value: 4021 },
    { key: "StockTransferPack", value: 4022 },
  ],
  //AutoGenPos
  autogenpos: [
    { key: "NormalInvoice", value: 10001 },
    { key: "DepositInvoice", value: 10002 },
    { key: "CreditNote", value: 10003 },
    { key: "SalesReturn", value: 10005 },
    { key: "CustomerReservation", value: 10004 },
    { key: "Alteration", value: 10006 },
    { key: "Quotation", value: 10007 },
  ],
  //消息类型
  messageType: [
    { key: "Normal", value: 1 },
    { key: "CRM", value: 2 },
    { key: "CouponLVLetterVoucherTC", value: 3 },
  ],
  //纸张类型
  paperType: [
    { key: "Thermalpaper80X297", value: 1 },
    { key: "Thermalpaper72X297", value: 2 },
    { key: "Impactpaper", value: 3 },
    { key: "A4", value: 4 },
  ],
  //会员组类型
  vipGroupType: [
    { key: "Normal", value: 1 },
    { key: "Staff", value: 2 },
    { key: "Special", value: 3 },
    { key: "Sponsorship", value: 4 },
  ],
  //会员积分计算类型
  pointValidCalcType: [
    { key: "ByCycle", value: 0 },
    { key: "PerMonth", value: 1 },
    { key: "PerMonthEnd", value: 2 },
  ],
  //有效期起始类型
  validityStartType: [
    { key: "ALL", value: 1 },
    { key: "TypeChangeDate", value: 2 },
    { key: "IssueDate", value: 3 },
  ],
  //regon
  crmRegion: [
    { key: "China", value: 1 },
    { key: "Macao", value: 2 },
  ],
  //广告类型
  crmAdvertiseType: [
    { key: "Normal", value: 1 },
    { key: "RegularReferenceCode", value: 2 },
    { key: "SpecialReferenceCode", value: 3 },
    { key: "PointRedemption", value: 4 },
    { key: "StampRedemption", value: 5 },
  ],
  //会员升降级类型
  vipConditionAction: [
    { key: "Upgrade", value: 1 },
    { key: "Downgrade", value: 2 },
    { key: "Renew", value: 3 },
    { key: "Terminal", value: 4 },
    { key: "NewJoin", value: 5 },
  ],
  //会员升降级检查
  vipConditionCheckat: [
    { key: "EverySalesEndofValidity", value: 1 },
    { key: "EverySalesEndOfCycle", value: 2 },
    { key: "EverySales", value: 3 },
    { key: "EndOfCycle", value: 4 },
    { key: "EndOfValidity", value: 5 },
  ],
  //会员升降级检查类型
  vipConditionWhen: [
    { key: "NetSalesAmount", value: 1 },
    { key: "Point", value: 2 },
  ],
  //会员升降级计算范围
  vipConditionWithin: [
    { key: "EveryCycle", value: 1 },
    { key: "FromJoinDate", value: 2 },
    { key: "EveryValidityPeriod", value: 3 },
    { key: "EveryValidityOfGrade", value: 4 },
  ],
  //会员升降级限定值
  vipConditionWithvalues: [
    { key: "GreaterThan", value: 1 },
    { key: "GreaterThanOrEqualTo", value: 2 },
    { key: "EqualTo", value: 3 },
    { key: "LowerThan", value: 4 },
    { key: "LowerThanOrEqualTo", value: 5 },
  ],
  //消息审核状态
  auditStatus: [
    { key: "new", value: 1 },
    { key: "confirmed", value: 2 },
    { key: "rejected", value: 3 },
  ],
  //阅读状态
  messageStatus: [
    { key: "read", value: 1 },
    { key: "unread", value: 2 },
  ],
  //CRM店铺范围类型
  vipShopRangeType: [
    { key: "All", value: 0 },
    { key: "SpecShop", value: 1 },
    { key: "SpecShopGroup", value: 2 },
  ],
  //CRM会员组限定类型
  vipRangeType: [
    { key: "All", value: 0 },
    { key: "SpecificMemberGrade", value: 1 },
  ],
  //会员婚姻状态
  vipMarried: [
    { key: "Single", value: 0 },
    { key: "Married", value: 1 },
  ],
  //会员默认联系方法
  vipDefaultContactEthod: [
    { key: "Email", value: 0 },
    { key: "Fax", value: 1 },
    { key: "Mail", value: 2 },
    { key: "Phone", value: 3 },
    { key: "Wechat", value: 4 },
  ],
  //会员出生日期类型
  vipBirthDateType: [
    { key: "YMD", value: 1 },
    { key: "MD", value: 2 },
    { key: "M", value: 3 },
  ],
  //会员标签来源类型
  crmTagType: [
    { key: "Auto", value: 1 },
    { key: "Manual", value: 2 },
  ],
  //会员标签规则连接方式
  crmTagRuleJoin: [
    { key: "And", value: 1 },
    { key: "Or", value: 2 },
  ],
  //售价格式
  priceFormat: [
    { key: "#,##0;-#,##0;0", value: 0 },
    { key: "#,##0.0;-#,##0.0;0.0", value: 1 },
    { key: "#,##0.00;-#,##0.00;0.00", value: 2 },
    { key: "#,##0.000;-#,##0.000;0.000", value: 3 },
    { key: "#,##0.0000;-#,##0.0000;0.0000", value: 4 },
    { key: "#,##0.00000;-#,##0.00000;0.00000", value: 5 },
    { key: "#,##0.000000;-#,##0.000000;0.000000", value: 6 },
  ],
  //金钱格式
  moneyFormat: [
    { key: "#,##0;-#,##0;0", value: 0 },
    { key: "#,##0.0;-#,##0.0;0.0", value: 1 },
    { key: "#,##0.00;-#,##0.00;0.00", value: 2 },
    { key: "#,##0.000;-#,##0.000;0.000", value: 3 },
    { key: "#,##0.0000;-#,##0.0000;0.0000", value: 4 },
    { key: "#,##0.00000;-#,##0.00000;0.00000", value: 5 },
    { key: "#,##0.000000;-#,##0.000000;0.000000", value: 6 },
  ],
  //店铺性别
  gender: [
    { key: "Staff", value: 0 },
    { key: "Male", value: 1 },
    { key: "Female", value: 2 },
  ],
  //混合匹配类型
  mixmatchtype: [
    { key: "UseMixmatchOnly", value: 0 },
    { key: "UseMixmatchwithothersalespromotion", value: 1 },
  ],
  //成本格式
  costFormat: [
    { key: "#,##0;-#,##0;0", value: 0 },
    { key: "#,##0.0;-#,##0.0;0.0", value: 1 },
    { key: "#,##0.00;-#,##0.00;0.00", value: 2 },
    { key: "#,##0.000;-#,##0.000;0.000", value: 3 },
    { key: "#,##0.0000;-#,##0.0000;0.0000", value: 4 },
    { key: "#,##0.00000;-#,##0.00000;0.00000", value: 5 },
    { key: "#,##0.00000;-#,##0.00000;0.00000", value: 6 },
  ],
  //圆的方法
  roundMethod: [
    { key: "RoundDown", value: 0 },
    { key: "RoundUp", value: 1 },
    { key: "Rounding", value: 2 },
  ],
  //店铺付款类型
  shopPaymentType: [
    { key: "DSReceipt", value: 0 },
    { key: "OwnReceipt", value: 1 },
  ],
  //价格书信息
  priceBookInformation: [
    { key: "WholeSaleRate", value: 0 },
    { key: "PriceType", value: 1 },
    { key: "PriceRule", value: 2 },
  ],
  //会员性别
  vipSex: [
    { key: "Female", value: 2 },
    { key: "Male", value: 3 },
  ],
  //积分规则操作类型
  vipPointOperationType: [
    { key: "upgrade", value: 1 },
    { key: "demotion", value: 2 },
    { key: "Newmembership", value: 3 },
  ],
  //积分规则触发条件
  vipTriggerCondition: [
    { key: "EffMmonth", value: "EffMmonth" },
    { key: "EffNetAmount", value: "EffNetAmount" },
    { key: "EffPoint", value: "EffPoint" },
  ],
  //会员适用范围
  vipApplicationScope: [
    { key: "All", value: 1 },
    { key: "Special", value: 2 },
  ],
  //职员状态
  staffStatus: [
    { key: "Normal", value: 0 },
    { key: "ProbationPeriod", value: 1 },
    { key: "Resigned", value: 2 },
  ],
  //报告类型
  reportType: [
    { key: "StandardReport", value: 0 },
    { key: "SalesInvoice", value: 1 },
    { key: "Pipeline", value: 2 },
    { key: "Deposit", value: 3 },
  ],
  //信贷期限类型
  creditDayType: [
    { key: "DueInOfDate", value: 0 },
    { key: "DueAtEndOfMonth", value: 1 },
  ],
  //货品池类型
  poolItemType: [
    { key: "ProductCode", value: 0 },
    { key: "Barcode", value: 1 },
  ],
  //限定符类型
  qualifierType: [
    { key: "Product", value: 0 },
    { key: "Category", value: 2 },
    { key: "Brand", value: 5 },
    { key: "BasicData2001", value: 6 },
    { key: "Year", value: 7 },
    { key: "Season", value: 8 },
    { key: "BaseColor", value: 9 },
    { key: "LineCode", value: 11 },
    { key: "ItemType", value: 12 },
    { key: "Barcode", value: 73 },
    { key: "GiftItem", value: 96 },
    // { key: "ItemPackage", value: 98 }, //禁用
    { key: "ItemPool", value: 99 },
    { key: "ProductDynamicAttribute", value: 100 },
  ],
  //限定符范围
  qualifierRange: [
    { key: "Equal", value: 0 },
    { key: "NotEqual", value: 1 },
    { key: "Including", value: 2 },
    { key: "Excluding", value: 3 },
  ],
  //調撥出庫壞貨原因
  stocktransferoutDamagereason: [
  ],
  //规则类型
  promotionRuleType: [
    { key: "SimpleQuantity", value: 0 },
    { key: "LimitedQuantity", value: 1 },
    // { key: "QuantityLevel", value: 2 }, //禁用
    { key: "Package", value: 3 },
    { key: "QuantityStep", value: 4 },
    { key: "QuantityRange", value: 5 },
  ],
  //计算方式
  ruleGroupType: [
    { key: "GroupbyItemQualifier", value: 0 },
    { key: "SingleItem", value: 1 },
  ],
  //计算顺序
  ruleSortType: [
    { key: "ScanAscending", value: 0 },
    { key: "PriceAscending", value: 1 },
    { key: "PriceDescending", value: 2 },
  ],
  //折扣单位
  ruleUnitType: [
    { key: "Percentage", value: 0 },
    { key: "AmountOff", value: 1 },
    { key: "FixPrice", value: 2 },
  ],
  //折扣类型
  discountType: [
    { key: "Percentageoff", value: 0 },
    { key: "Dollaroff", value: 1 },
  ],
  //特价类型
  promotionType: [
    { key: "MarkDown", value: 0 },
    { key: "PreSale", value: 1 },
    { key: "MarkDownPreSale", value: 2 },
  ],
  //礼品货品
  giftItem: [
    { key: "0", value: 0 },
    { key: "1", value: 1 },
  ],
  //优惠价格Vip范围类型
  promotionPriceVipRangeType: [
    { key: "AllCustomer", value: 0 },
    { key: "AllVip", value: 1 },
    { key: "ByVipType", value: 2 },
    { key: "NonVip", value: 3 },
    { key: "NonVipOrByVipType", value: 4 },
    { key: "SingleVip", value: 5 },
  ],
  //促销类型
  promotionSetType: [
    { key: "Company", value: 0 },
    { key: "MallPromotion", value: 1 },
  ],
  //特價設定的貨品層次
  extractDetailMode: [
    { key: "Product", value: 0 },
    { key: "ProductColor", value: 1 },
    { key: "Barcode", value: 2 },
  ],
  //特价商店范围类型
  promotionShopGroupsRangeType: [
    { key: "IncludingGroup", value: 0 },
    { key: "ExcludingGroup", value: 1 },
  ],
  //特价店铺范围类型
  promotionShopsRangeType: [
    { key: "OrIncludingShops", value: 0 },
    { key: "AndExcludingShops", value: 1 },
  ],
  //销售/送货条件类型
  promotionSetBuysType: [
    { key: "Brand", value: 3 },
    { key: "Category", value: 4 },
    { key: "ProductCode", value: 2 },
    { key: "ItemQualifier", value: 0 },
  ],
  //计算顺序
  promotionSortType: [
    { key: "Pricesfromhightolow", value: 0 },
    { key: "Pricesfromlowtohigh", value: 1 },
  ],
  //验证码使用模式
  promotionCode: [
    { key: "NotEnabled", value: 0 },
    { key: "EnabledVerificationCode", value: 1 },
    { key: "EnabledPresetVerificationCode", value: 2 },
  ],
  //促销店铺选择
  promotionShopSelect: [
    { key: "Include", value: 0 },
    { key: "NoInclude", value: 1 },
  ],
  //默认促销类型
  promotionDefRuleType: [
    { key: "LineDiscount", value: 0 },
    { key: "QuantityDiscount", value: 1 },
    { key: "AmountDiscount", value: 2 },
    { key: "BuyGetOffer", value: 3 },
    { key: "ApplySpecialortotheitemqtyinallBuyConditions", value: 4 },
    { key: "BuyoverAmtgetproduct", value: 5 },
  ],
  //会员升降级优先级
  vipGradePriority: [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
    { key: "7", value: 7 },
    { key: "8", value: 8 },
    { key: "9", value: 9 },
    { key: "10", value: 10 },
  ],
  //特价设定折扣类型
  promPriceUnitType: [
    { key: "Percentage", value: 0 },
    { key: "AmountOff", value: 1 },
  ],
  //礼券类型
  couponItemType: [
    { key: "Coupon", value: 0 },
    { key: "CreditMoney", value: 1 },
    { key: "GiftCard", value: 2 },
    { key: "CrmCoupon", value: 3 },
    { key: "ReceiptCoupon", value: 4 },
  ],
  //礼券使用时间
  couponUseTime: [
    { key: "UseOnce", value: 0 },
    { key: "UseTilBalanceIsZero", value: 1 },
  ],
  //礼券有效日期类型
  couponValidDateType: [
    { key: "FixValidDate", value: 0 },
    { key: "DaysAfterGeneration", value: 1 },
    { key: "DaysAfterSold", value: 2 },
    { key: "PerMonth", value: 3 },
    { key: "PerTheEndOfMonth", value: 4 },
    { key: "ValidDateRangeBySerial", value: 5 },
    // { key: "BaseOnPromotionValidDate", value: 6 },//禁用
  ],
  //礼券兑换方式
  couponRedeemWay: [
    { key: "LineDiscountCoupon", value: 1 },
    { key: "PaymentCoupon", value: 2 },
  ],
  //礼券可用于
  couponUseIn: [
    // { key: "None", value: 0 },//禁用
    { key: "OnlyApplicableToOriginalPrice", value: 1 },
    { key: "NotAllowToJoinPromotion", value: 2 },
    { key: "UseInNetAmount", value: 3 },
  ],
  //礼券积分
  couponPointGive: [
    { key: "NoSpendingAndBonusPoint", value: 0 },
    { key: "WithSpending", value: 1 },
    { key: "WithBonusPoint", value: 2 },
    { key: "WithSpendingAndBonusPoint", value: 3 },
  ],
  //RequestType
  couponRequestType: [
    { key: "GenerateImportnewCouponSerialNo", value: 1 },
    { key: "ChangeStatus", value: 2 },
    { key: "ChangeExpiryDate", value: 3 },
    { key: "ChangeLocation", value: 4 },
  ],
  //礼券默认状态
  couponDefaultStatus: [
    { key: "Stock", value: 0 },
    { key: "Activate", value: 1 },
  ],
  //礼券默认状态
  couponChangeStatus: [
    { key: "Stock", value: 0 },
    { key: "Activate", value: 1 },
    { key: "Redeemed", value: 2 },
    { key: "Cancelled", value: 3 },
  ],
  //礼券激活折扣类型
  activationDiscountType: [
    { key: "SelectDiscountType", value: 0 },
    { key: "DiscountbyPercentage", value: 1 },
    { key: "DiscountbyAmount", value: 2 },
  ],
  // 自动补货优先级
  replenishmentRulePriority: [
    { key: 1, value: 1 },
    { key: 2, value: 2 },
    { key: 3, value: 3 },
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
    { key: 9, value: 9 },
    { key: 10, value: 10 },

  ],
  //会员使用类型
  vipRedemptionType: [
    { key: "AmountDiscount", value: 0 },
    { key: "RedeemItem", value: 1 },
    { key: "RedeemCoupon", value: 2 },
  ],
  //会员积分抵扣类型
  vipRedeemItemType: [
    { key: "ItemCode", value: 0 },
    { key: "Brand", value: 1 },
    { key: "Category", value: 2 },
    { key: "ItemQualifier", value: 3 },
    { key: "CouponItemCode", value: 4 },
  ],
  //会员印花获取规则
  vipStampType: [
    { key: "ItemQualifier", value: 1 },
    { key: "InvoiceNetAmount", value: 2 },
    { key: "PaymentQualifier", value: 3 },
  ],
  //会员印花使用类型
  vipStampUseType: [
    { key: "RedeemItem", value: 1 },
    { key: "RedeemCoupon", value: 2 },
  ],
  //会员印花使用规则
  vipStampRedemptionType: [
    { key: "ItemQualifier", value: 1 },
    { key: "CouponItemCode", value: 2 },
  ],
  // 跨品牌促销规则
  crossBrandPromotionRuleType: [
    { key: 'RationOfInvAmt', value: 0 },
    { key: 'FixAmt', value: 1 },
    { key: 'FixPercentage', value: 2 },
    { key: 'DynamicPromotion', value: 3 },
  ],
  // 跨品牌促销类型
  crossBrandPromotionType: [
    { key: 'DaysAfterEffect', value: 0 },
    { key: 'FixValidDate', value: 1 },
    { key: 'PerMonth', value: 2 },
    { key: 'PerMonth(End of Month)', value: 3 },
  ],
  // 跨品牌促销 Min Discount Unit
  crossBrandMinDiscountUnit: [
    { key: 'Percent%', value: 0 },
    { key: 'AmountOff', value: 1 },
  ],
  // corssBrandCBMode
  corssBrandCBMode: [
    { key: 'AllLocation', value: 0 },
    { key: 'ExcludeOriginalLocation', value: 1 },
    { key: 'OriginalLocationOnly', value: 2 },
    { key: 'ExcludeSelfLocationGroup', value: 3 },
    { key: 'FixShop', value: 4 },
  ],
  //
  quotaType: [
    { key: "StaffPurchaseQuotaDefinition", value: 1 },
    { key: "StaffIncentiveQuotaDefinition", value: 2 },
    { key: "SFFQuotaDefinition", value: 3 },
    { key: "VIPSponsorshipQuotaDefinition", value: 4 },
  ],
  // password policy scope
  passwordPolicyScope: [
    { key: 'NotTakeEffect', value: 0 },
    { key: 'ACCSOnlyTakeEffect', value: 1 },
    { key: 'POSOnlyTakeEffect', value: 2 },
    { key: 'ACCSAndPOSAreEffective', value: 3 },

  ],
  replenishType: [
    { key: 'BySales', value: 0 },
    { key: 'ByShopRequest', value: 1 },
  ],
  specificType: [
    { key: 'SpecificMember', value: 0 },
    { key: 'SpecificMemberGrade', value: 1 },
    { key: 'SpecificCustomerTag', value: 2 },
  ],
  couponDeliveryCouponType: [
    { key: 'CouponItemCode', value: 1 },
  ],
  // area 
  addressFormat: [
    { key: 'HKAddressFormat', value: 0 },
    { key: 'UKAddressFormat', value: 1 },
    { key: 'USAddressFormat', value: 2 },
    { key: 'EUAddressFormat', value: 3 },
  ],
  haloBirthDateType: [
    { key: "HaloDM", value: 0 },
    { key: "M", value: 1 },
  ],
  // Halo appellation redmine #21453
  haloSaluation: [
    { key: "Mr.", value: 0 },
    { key: "Ms.", value: 1 },
    { key: "Mrs.", value: 2 },
    { key: "Miss", value: 3 },
    { key: "NotSpecified", value: 4 },
  ],
  haloAddressType: [
    { key: "BillingAddress", value: 0 },
    { key: "DeliveryAddress", value: 1 },
  ],
  shopPurchaseRequestType: [
    { key: "NewStockReplenishment", value: 1 },
    { key: "OldCargoReplenishment", value: 2 },
  ],
  PosInvoiceTransStatus: [
    { key: "Outstanding", value: 0 },
    { key: "Hold", value: 1 },
    { key: "Cancelled", value: 2 },
    { key: "Proceed", value: 3 },
    { key: "Settled", value: 4 },
    { key: "Refunded", value: 5 },
    { key: "Received", value: 6 },
    {key:"GracePeriod",value:7},
    { key: "Pending", value: 10 },
    { key: "Sleeping", value: 11 },
    { key: "Converted", value: 13 },
    { key: "Closed", value: 14 },
  ],
  PosInvoiceType: [
    { key: "Quotation", value: 0 },
    { key: "NormalInvoice", value: 1 },
    { key: "DepositInvoice", value: 2 },
    { key: "CreditNote", value: 3 },
    { key: "SalesReturn", value: 4 },
    { key: "CouponReceipt_5", value: 5 },
  ],
  QuotationReprintType: [
    { key: "Quotation", value: 0 },
    { key: "Proforma", value: 1 },
  ],
  //交易类型
  tradeType: [
    { key: "Sales", value: 0 },
    { key: "IssueCreditNote", value: 1 },
  ],
  TranscationTypeCode: [
    { key: "Q", value: 0 }, // Quotation
    { key: "S", value: 1 }, // Sales Order
    { key: "R", value: 2 }, // Sales Refund
    { key: "C", value: 3 }, // CreditNote
  ],
  approveTips: [
    { key: 'ConfirmPaymentWhenDepositIsLessThan50%', value: 1 },
    { key: 'DeliveryChargesServiceItemIn0GBP', value: 2 },
    // （留意GBP是显示本单货币币别，前端可能提示信息要显示该单的invoiceCurrencyId的币别
    { key: 'RestockingFeeServiceItemIn0GBP', value: 3 },
    { key: 'StorageFeeServiceItemIn0GBP', value: 4 },
    { key: 'ManualDiscount', value: 5 },
    //留意这个可能要提示哪些行有人工折扣，前端可查找明细中isUserManualDiscount=true的行
  ],
  // Delivery Charge Type Setting
  DeliveryChargeUnitType: [
    { key: "FixAmount", value: 0 },
    { key: "%OfSalesAmount", value: 1 },
    { key: "FixAmountQty", value: 2 },
  ],
  ExchangeRateModule:[
    { key: "Retail", value: 0 },
    { key: "WholeSales", value: 1 },
  ],
  // vip point reward defines
  RewardType: [
    { key: "Sales", value: 0 },
    { key: "TopUp", value: 1 },
  ],
  RewardRulesTableType: [
    { key: "CashRebate", value: 0 },
    { key: "PointReward", value: 1 },
    { key: "Product", value: 2 },
    { key: "Coupon", value: 3 },
  ]
}


