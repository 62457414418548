<template>
  <el-button-group style="float: left;">
    <approve-button :code="code"
      :preText="preText" :objectId="objectId"
      :unProcess="false" @change="handleChange"
      ref="approveButton"></approve-button>
    <approve-button :code="code"
      :preText="preText" :objectId="objectId"
      :unProcess="true" v-if="unPostVisible"
      @change="handleUnPostChange">
    </approve-button>
  </el-button-group>
</template>

<script>
import Button from "./button.vue";
export default {
  components: {
    "approve-button": Button,
  },
  name: "ApproveButton",
  props: {
    code: {}, //流程编号
    objectId: {}, //流程对象id
    preText: {},
  },
  data() {
    return {
      instance: null,
      unPostInstance: null,
      allowApprove: false,
      loading: false,
    };
  },
  methods: {
    handleChange(instance) {
      this.emitStatusChange(this.instance, instance);
      this.instance = instance;
    },
    handleUnPostChange(instance) {
      this.emitStatusChange(this.unPostInstance, instance);
      this.unPostInstance = instance;
      // if (this.unPostInstance.status == "END") {
      //     this.$refs.approveButton.refresh();
      // }
    },
    emitStatusChange(source, target) {
      if (source && target && source.syncRowVersion != target.syncRowVersion) {
        this.$emit("change");
      }
    },
  },
  computed: {
    unPostVisible() {
      if (this.instance) {
        if (this.instance.status == "END") {
          return true;
        }
      }
      if (this.unPostInstance) {
        if (this.unPostInstance.status == "END") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>
