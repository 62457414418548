<template>
<n-dialog-layout :title="l('Setting')" ref="dialog" v-loading="loading">
    <ux-query-view ref="dt" size="small" code="QUERY-LayoutList" :sys_params="{userId:userId,queryId:queryId}" :height="height">
        <template slot="template-action-columns">
            <vxe-column prop="id" width="35" fixed="left">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="handleSelect(scope.row)"></el-button>
                </template>
            </vxe-column>
            <vxe-column prop="id" width="35" fixed="left">
                <template slot-scope="scope">
                    <el-button :disabled="scope.row.userId!=userId" type="text" icon="el-icon-delete" @click="handleRemove(scope.row)"></el-button>
                </template>
            </vxe-column>
        </template>
    </ux-query-view>
</n-dialog-layout>
</template>

<script>
import store from "@/store";
import {
    deleteRpLayout
} from "@/api/uxreport/query.js";
export default {
    data() {
        return {
            queryId: null,
            height: window.innerHeight * 0.5,
            userId: store.getters.userId,
            loading: false
        };
    },
    methods: {
        open(options) {
            this.queryId = options.queryId
            return this.$refs.dialog.open();
        },
        handleSelect(row) {
            this.$refs.dialog.close(row)
        },
        handleRemove(row) {
            this.$confirm(this.l('SureDelete')).then(() => {
                this.loading = true;
                deleteRpLayout({
                    id: row.id
                }).then(() => {
                    this.$message.success(this.l('DeletedSuccessfully'));
                    this.$refs.dialog.close(null)
                }).finally(() => this.loading = false)
            })
        },
    },
};
</script>
