<template>
  <dx-number-box :min="min" :max="max" ref="input"
    class="ux-input" :show-spin-buttons="false"
    :disabled="disabled" :readOnly="readonly"
    :placeholder="placeholder"
    @change="handleChange"
    @contentReady="contentReady"
    v-model="numericValue" :format="format">
  </dx-number-box>
</template>
<script>
const timeInterval = 100;
import { DxNumberBox } from "devextreme-vue/number-box";
import "devextreme/dist/css/dx.carmine.compact.css";
import dxformat from "@/utils/format.js";

export default {
  name: "ux-number-input",
  components: { DxNumberBox },
  props: {
    name: String,
    value: {},
    placeholder: String,
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    align: {
      type: String,
      default: "left",
    },
    precision: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && Number.isInteger(val);
      },
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {},
    selectAll: {},
  },
  data() {
    return {
      numericValue: parseFloat(this.value),
    };
  },
  watch: {
    // Watch for value change
    value() {
      this.numericValue = parseFloat(this.value);
    },
  },
  methods: {
    handleChange() {
      this.$emit("input", this.numericValue);
      this.$emit("change", this.numericValue);
    },
    contentReady(content) {
      content.component.on("valueChanged", (arg) => {
        this.$emit("valueChanged", arg);
      });
      content.component.on("focusIn", (e) => {
        e.element.querySelector(".dx-texteditor-input").select();
      });
    },
  },
  mounted() {},
  computed: {
    format() {
      if (this.type == "percent") {
        // return "#.## ﹪";
        if (this.precision === 0 || this.precision > 0) {
          let extend = "";
          for (var i = 0; i < this.precision; i++) {
            extend += "#";
          }
          return "#0" + (extend ? "." + extend : "") + "'%'";
        }
        // return "#0.## ﹪";
      } else if (this.type == "decimal" && this.precision > 0) {
        let extend = "";
        for (var i = 0; i < this.precision; i++) {
          extend += "#";
        }
        return "#,##0" + (extend ? "." + extend : "");
      } else if (this.type == "percentFormat") {
        return dxformat.percentFormat;
      } else if (this.type == "priceFormat") {
        return dxformat.priceFormat;
      } else if (this.type == "amountFormat") {
        return dxformat.amountFormat;
      } else if (this.precision === 0 || this.precision > 0) {
        let extend = "";
        for (var i = 0; i < this.precision; i++) {
          extend += "0";
        }
        return "#,##0" + (extend ? "." + extend : "");
      } else {
        return "#,##0.####";
      }
    },
  },
};
</script>  

<style lang="scss">
@import "~@/assets/styles/element-variables.scss";
.ux-input.dx-numberbox {
  border-radius: 4px;

  .dx-texteditor-container {
    height: 31px;
  }
  .dx-texteditor-input-container {
    height: 30px;
  }
  .dx-texteditor-input {
    padding: 0 15px !important;
  }
}
.ux-input.dx-texteditor.dx-state-focused.dx-editor-outlined {
  border: 1px solid $--color-primary !important;
}

.ux-input.dx-texteditor.dx-state-hover.dx-editor-outlined {
  border-color: #c0c4cc;
}
</style>