<template>
  <div class="flex ux-search-input">
    <!-- !!currentValue ? 'input-readonly' : '',  -->
    <el-input style="flex:2" v-model="currentText"
      :readonly="(!!currentValue)"
      @blur="handleBlur"
      @change.native="handleChange"
      @keyup.enter.native="handleEnter"
      :class="[nameField?'has-name-left':'']"
      :disabled="disabled" v-loading="loading"
      ref="uxInput"
      @keyup.native.46="handleClear">
      <template v-if="!disabled">
        <i class="el-icon-circle-close el-input__icon"
          slot="suffix"
          v-if="clearable&&((codeable&&!value)||!codeable)"
          @click="handleClear"></i>
        <i class="el-icon-search el-input__icon"
          slot="suffix"
          @click="handleSearchClick"></i>
      </template>
    </el-input>
    <el-input :readonly="true" style="flex:3"
      :class="[nameField?'has-name-right':'']"
      class="input-readonly" v-if="nameField"
      :value="currentName">
    </el-input>
  </div>
</template>

<script>
import QueryDialog from "./QueryDialog.vue";
import {
  infoSysQueryBuilder,
  dynamicQueryOne,
} from "@/api/uxretail/sys/sysQueryBuilder.js";
import baseCodeGen from "../UxCodeInput/baseCodeGen";
export default {
  name: "ux-query-input",
  mixins: [baseCodeGen],
  props: {
    textField: { default: "code" }, //显示字段，在mutiple中使用join连接
    nameField: { default: "" }, //name,name1,name2
    findField: { default: "code" },
    valueField: { default: "id" }, //值字段 如果为空则绑定object ，在mutiple中返回数组
    value: {},
    name: {},
    text: {},
    disabled: { default: false },
    clearable: { default: true },
    queryCode: {},
    sys_params: { default: () => ({}) },
    title: {},
    codeable: { default: false }, //新增编码
    multiple: { default: false },
    setV: {},
    echoField: { type: String ,default: "" },
  },
  data() {
    var index = this.$store.state.user.languageIndex;
    return {
      loading: false,
      currentText: this.text,
      currentName: this.name,
      currentValue: this.value,
      queryModel: {},
      languageIndex: index <= 0 ? "" : index,
      autoGenCodeEvent: "update:text",
    };
  },
  watch: {
    text(n) {
      this.currentText = n;
    },
    name(n) {
      this.currentName = n;
    },
    value(n) {
      this.currentValue = n;
      if (!n) {
        this.currentText = "";
        this.currentName = "";
      }
    },
    currentText() {
      this.$emit("update:text", this.currentText);
    },
    currentName() {
      this.$emit("update:name", this.currentName);
    },
    queryCode() {
      this.handleClear();
      this.fetchSchema();
    },
  },
  methods: {
    handleChange(e) {
      this.autoGen = false;
    },
    //dialog类型点击搜索按钮
    handleSearchClick() {
      this.$dialog
        .open(QueryDialog, {
          title: this.title,
          config: {
            multiple: this.multiple,
            code: this.queryCode,
            sys_params: this.sys_params,
            value: this.currentValue,
            valueField: this.valueField,
            echoField: this.echoField,
          },
        })
        .then(this._handleRes);
    },
    //popover中选中事件
    handleSelect(res) {
      this.noData = false;
      this._handleRes(res);
    },
    _handleRes(res) {
      if (this.multiple) {
        this.currentText = res.map((x) => _.get(x, this.textField)).join();
        this.currentValue = this.valueField
          ? res.map((x) => _.get(x, this.valueField))
          : res;
        if (this.nameField) {
          this.currentName = res
            .map((x) =>
              _.get(
                res,
                this.nameField + this.languageIndex,
                _.get(res, this.nameField)
              )
            )
            .join();
        }
      } else {
        this.currentText = _.get(res, this.textField);
        this.currentValue = this.valueField ? _.get(res, this.valueField) : res;
        if (this.nameField) {
          this.currentName = _.get(
            res,
            this.nameField + this.languageIndex,
            _.get(res, this.nameField)
          );
        }
      }
      this._emitChange(res);
    },
    handleClear() {
      this.currentText = "";
      this.currentValue = null;
      this.currentName = "";
      this._emitChange(null);
    },
    handleBlur() {
      this._search();
    },
    handleEnter() {
      this._search();
    },
    _emitChange(data) {
      this.$emit("input", this.currentValue);
      this.$emit("update:text", this.currentText);
      this.$emit("change", data);
      //info：这里只适用于edittable,其他地方不适用
      if (this.setV) {
        this.setV.set(this.setV.row, data);
      }
    },
    _search() {
      if (this.currentText && !this.currentValue && this.sql) {
        this.loading = true;
        dynamicQueryOne({
          queryCode: this.queryCode,
          sql: this.sql,
          dataScopeActive: this.queryModel.dataScope
            ? this.queryModel.dataScope.active
            : false,
          orgAlias: this.queryModel.dataScope
            ? this.queryModel.dataScope.orgAlias
            : "",
          userAlias: this.queryModel.dataScope
            ? this.queryModel.dataScope.userAlias
            : "",
          params: this.findField
            ? [`${this.findField}='${this.currentText}'`]
            : [],
        })
          .then((res) => {
            if (res) {
              this.currentValue = res[this.valueField];
              this.currentText = res[this.textField];
              if (this.nameField) {
                this.currentName = _.get(
                  res,
                  this.nameField + this.languageIndex,
                  _.get(res, this.nameField)
                );
              }
            } else {
              if (!this.codeable) {
                this.currentText = "";
                this.currentName = "";
                this.$message.error(this.l("NotFound"));
              }
            }
            this._emitChange(this.multiple ? [res] : res);
          })
          .catch(() => {
            this.currentValue = null;
            this.currentName = "";
            if (!this.codeable) {
              this.currentText = "";
            }
            this._emitChange(this.multiple ? [] : {});
          })
          .finally(() => (this.loading = false));
      }
    },
    fetchSchema() {
      if (this.queryCode) {
        var encyptQueryModel = sessionStorage.getItem(this.queryCode);
        if (encyptQueryModel) {
          this.queryModel = JSON.parse(encyptQueryModel);
        } else {
          this.loading = true;
          infoSysQueryBuilder(null, this.queryCode.replace("QUERY-", ""))
            .then((res) => {
              this.queryModel = JSON.parse(res.configJson);
              sessionStorage.setItem(this.queryCode, res.configJson);
            })
            .finally((this.loading = false));
        }
      } else {
        console.log("query-view code required");
      }
    },
  },
  computed: {
    sql() {
      var sql = this.queryModel.querySQL;
      this.queryModel.sys_params.forEach((t) => {
        sql = sql.replaceAll(
          "{" + t.paramName + "}",
          this.sys_params[t.paramName] || t.defaultValue
        );
      });
      return sql;
    },
  },
  created() {
    this.fetchSchema();
  },
};
</script>

<style lang="scss">
.ux-search-input {
  .el-input__inner {
    padding-right: 45px !important;
  }
  .el-input__icon {
    width: 20px;
  }

  .has-name-left {
    .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .has-name-right {
    .el-input__inner {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
