import Cookies from 'js-cookie'
import { getDicts } from "@/api/system/dict/data";
import _ from 'lodash'
import Vue from 'vue'


const getDictsThrottles = {}

var createDictThrottle = () => {
  return _.throttle(function (state, dictType, resolve, reject) {
    getDicts(dictType).then(response => {
      let key = "DICT_" + dictType;
      // state.dictDatas[dictType] = response.data;
      Vue.set(state.dictDatas, dictType, response.data)
      localStorage.setItem(key, JSON.stringify(response.data));
      resolve();
    }).catch(reject)
  }, 500)
}

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : false,
    withoutAnimation: false
  },
  device: 'desktop',
  size: 'small',
  dictDatas: {},
  printQuery: {},
  queryBuilders: {}
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_PRINTQUERY: (state, { printCode, infoPrintQuery }) => {
    state.printQuery[printCode] = infoPrintQuery
  },
  SET_QUERY_BUILDER: (state, { code, q }) => {
    state.queryBuilders[code] = q;
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setPrintQuery({ commit }, printModule) {
    commit('SET_PRINTQUERY', printModule)
  },
  getDictData({ commit, state }, { dictType, reload }) {
    return new Promise((resolve, reject) => {
      let key = "DICT_" + dictType;
      if (reload) {
        localStorage.removeItem(key)
      } else {
        var cachedData = localStorage.getItem(key);
        if (cachedData) {
          state.dictDatas[dictType] = JSON.parse(cachedData);
          resolve();
          return;
        }
      }

      if (!getDictsThrottles[dictType]) {
        getDictsThrottles[dictType] = createDictThrottle()
      }

      getDictsThrottles[dictType](state, dictType, resolve, reject);
    });


    // return new Promise((resolve, reject) => {
    //   getInfo().then(res => {
    //     const user = res.user
    //     const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
    //     if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //       commit('SET_ROLES', res.roles)
    //       commit('SET_PERMISSIONS', res.permissions)
    //     } else {
    //       commit('SET_ROLES', ['ROLE_DEFAULT'])
    //     }
    //     commit('SET_ID', user.userId)
    //     commit('SET_NAME', user.userName)
    //     commit('SET_AVATAR', avatar)
    //     commit('SET_LANGUAGE', res.language)
    //     Cookies.set(CONSTANT.UX_LANGUAGE, res.language)
    //     resolve(res)
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
