<template>
  <div class="sidebar-logo-container"
    :class="{ collapse: collapse }" :style="{
      backgroundColor: sideTheme=='theme-dark'? variables.menuBg : variables.menuLightBg
    }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse"
        class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="miniLogo"
          class="sidebar-logo" />
        <!-- <h1
          v-else
          class="sidebar-title"
          :style="{
            color:
              sideTheme === 'theme-dark'
                ? variables.sidebarTitle
                : variables.sidebarLightTitle
          }"
        >
          {{ title }}
        </h1> -->
      </router-link>
      <router-link v-else key="expand"
        class="sidebar-logo-link" to="/">
        <!--  -->
        <div>
          <img v-if="logo" :src="standardLogo"
            style="height:40px;width:auto;margin:0 10px;"
            class="sidebar-logo" />
        </div>
        <!-- <h1
          class="sidebar-title"
          :style="{
            color:
              sideTheme === 'theme-dark'
                ? variables.sidebarTitle
                : variables.sidebarLightTitle
          }"
        >
          {{ title }}
        </h1> -->
      </router-link>
    </transition>
  </div>
</template>
 
<script>
import logo18 from "@/assets/logo/logo_mini.png";
import logoImg from "@/assets/logo/logo.png";
import variables from "@/assets/styles/variables.scss";
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.getConfigKey("ux.mini.logo").then((response) => {
      this.logo18 = response.msg;
    });
    this.getConfigKey("ux.standard.logo").then((response) => {
      this.logo = response.msg;
    });
  },
  computed: {
    standardLogo() {
      if (this.logo) {
        return process.env.VUE_APP_BASE_API + this.logo;
      } else {
        return "";
      }
    },
    miniLogo() {
      if (this.logo18) {
        return process.env.VUE_APP_BASE_API + this.logo18;
      } else {
        return "";
      }
    },
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme;
    },
  },
  data() {
    return {
      title: "RuoyiManagementSystem",
      logo: logoImg,
      logo18: logo18,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
