<template>
  <el-breadcrumb class="app-breadcrumb"
    separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item,index) in levelList"
        :key="item.path">
        <span
          v-if="item.redirect==='noRedirect'||index==levelList.length-1"
          class="no-redirect">{{ item.meta.title }}</span>
        <a v-else
          @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route, form) {
      document.title = this.l(route.name);
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    findRoute(routes, parent) {
      var router = null;
      for (var i = 0; i < routes.length; i++) {
        routes[i].parent = parent;
        if (routes[i].name == this.$route.name) {
          router = routes[i];
          break;
        } else if (routes[i].children && routes[i].children.length > 0) {
          var t = this.findRoute(routes[i].children, routes[i]);
          if (t) {
            router = t;
            break;
          }
        }
      }
      return router;
    },
    getBreadcrumb() {
      var routes = this.copy(this.$store.state.permission.sidebarRouters);
      var r = this.findRoute(routes, null);
      var levelList = [];
      var temp = r;
      do {
        levelList.push(temp);
        temp = temp.parent;
      } while (temp);

      this.levelList = levelList.filter((t) => t.name).reverse();
      console.debug(this.levelList);
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim() === "HomePage";
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    cursor: text;
  }
}
</style>
