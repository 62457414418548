<template>
  <el-select :multiple="multiple" filterable v-model="currentValue" @change="handleChange" :collapse-tags="true">
    <slot></slot>
    <el-option v-for="opt in selectOptions" :value="opt[valuePath]" :label="opt[labelPath]" :key="opt[labelPath]">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "multiple-select",
  props: {
    value: {},
    multiple: { default: false },
    labelPath: { default: "code" },
    valuePath: { default: "id" },
    selectOptions: { default: () => [] },
    type: { default: "array" }, //','
  },
  data() {
    return {
      currentValue: this.getV(),
    };
  },
  watch: {
    value() {
      this.currentValue = this.getV();
    },
  },
  methods: {
    getV() {
      if (this.value || this.value == 0) {
        if (this.multiple) {
          if (this.type == "array") {
            return this.value;
          } else {
            return this.value
              ? this.value.split(this.type).map((t) => this.tryNumber(t))
              : [];
          }
        } else {
          return this.value;
        }
      }
      return this.multiple ? [] : null;
    },
    tryNumber(value) {
      if (
        value &&
        typeof value == "string" &&
        value.indexOf("0") == 0 &&
        value.length > 1
      ) {
        return value; //特殊情况 例如value=001
      }
      var v = parseInt(value);
      if (v == value) {
        return v;
      } else {
        return value;
      }
    },
    getCurrentValue() {
      if (this.multiple) {
        if (this.type == "array") {
          return this.currentValue;
        } else {
          return this.currentValue.join(this.type);
        }
      } else {
        return this.currentValue;
      }
    },
    handleChange() {
      this.$emit("input", this.getCurrentValue());
    },
  },
};
</script>

<style>
</style>