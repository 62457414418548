<template>
  <ux-query-select v-model="currentValue"
    :text.sync="currentText" v-bind="options"
    v-on="localEvents" :disabled="disabled"></ux-query-select>
</template>

<script>
export default {
  name: "uxt-query-select",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    params: {}, //row,column
    options: {},
    events: {},
  },
  data() {
    return {
      currentValue: _.get(this.params.row, this.options.bindRowValueField),
      currentText: _.get(this.params.row, this.options.bindRowTextField),
    };
  },
  watch: {
    currentValue() {
      _.set(this.params.row, this.options.bindRowValueField, this.currentValue);
    },
    currentText() {
      _.set(this.params.row, this.options.bindRowTextField, this.currentText);
    },
  },
  computed: {
    localEvents() {
      let temp = {};
      for (var p in this.events) {
        let event = this.events[p];
        let row = this.params.row;
        temp[p] = function () {
          event(
            row,
            arguments[0],
            arguments[1],
            arguments[2],
            arguments[3],
            arguments[4],
            arguments[5]
          );
        };
      }
      return temp;
    },
  },
};
</script>

<style>
</style>