import Vue from "vue";

import ConfigTable from "@/components/Table/ConfigTable.vue";
import LanguageInput from "@/components/LanguageInput/languageInput";
import ImageUpload from "@/components/ImageUpload/ImageUpload";
import UxCodeInput from "@/components/UxCodeInput/index.vue";
import UxCheckbox from "@/components/UxCheckbox/index";
import UxSelect from "@/components/UxSelect/index";
import UxSelectDict from "@/components/UxSelect/dict";
import UxSelectEnum from "@/components/UxSelect/enum";
import MutipleSelect from "@/components/UxSelect/mutipleSelect.vue"
import VueExcelEditor from "@/components/UxExcelEditor/VueExcelEditor.vue";
import VueExcelColumn from "@/components/UxExcelEditor/VueExcelColumn.vue";
import NDialogLayout from "@/components/layout/nDialogLayout.vue";
import NLayout from "@/components/layout/nLayout.vue";
import UxDragLayout from "@/components/UxDragLayout/uxDragLayout.vue";
import UxDragItem from "@/components/UxDragLayout/uxDragItem.vue";
import UxDataDialog from "@/components/UxDataDialog/index.vue";
import UxSearchInput from "@/components/UxSearchInput/index.vue";
import QueryView from "@/components/Query/QueryView.vue";
import QueryDialog from "@/components/Query/QueryDialog.vue";
import QueryInput from "@/components/Query/QueryInput.vue";
import QueryTagInput from "@/components/Query/QueryTagInput.vue";
import QuerySelect from "@/components/Query/QuerySelect.vue";
import NumberInput from "@/components/NumberInput/index.vue";
import { Row } from "element-ui";
import AreaTree from "@/components/TreeSelect/Area/index.vue";
import CategoryTree from "@/components/TreeSelect/Category/index.vue";
import OrganizationTree from "@/components/TreeSelect/Organization/index.vue";
import EditTable from "@/components/EditTable/index.vue";
import ReportButton from "@/components/ReportQuery/ReportButton.vue";
import YearInput from "@/components/UxYearInput/index.vue";
import LinkEdit from "@/components/LinkEdit/index.vue";
import FormInput from "@/components/FormInput/index.js";
import DynamicAttributes from "@/components/DynamicAttributes/index.vue";
import ApproveButton from "@/components/ApproveButton/index.vue";
import ImportButton from "@/components/ImportFunction/button.vue";
import BarcodeNotFound from "@/components/BarcodeNotFound/index.vue";

import "xe-utils"; 
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

import { baseZIndex } from "devextreme/ui/overlay";

import BaiduMap from "vue-baidu-map";

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "Aj4aHsN3RiXI7ynwEvXCBQKdWEfxh4c8",
});

baseZIndex(9999);
Vue.use(VXETable);
 
Vue.component(ConfigTable.name, ConfigTable);
Vue.component(LanguageInput.name, LanguageInput);
Vue.component(ImageUpload.name, ImageUpload);
Vue.component(UxCodeInput.name, UxCodeInput);
Vue.component(UxCheckbox.name, UxCheckbox);
Vue.component(UxSelect.name, UxSelect);
Vue.component(UxSelectDict.name, UxSelectDict);
Vue.component(UxSelectEnum.name, UxSelectEnum);
Vue.component(MutipleSelect.name, MutipleSelect)
Vue.component(NLayout.name, NLayout);
Vue.component(NDialogLayout.name, NDialogLayout);
Vue.component(UxDragLayout.name, UxDragLayout);
Vue.component(UxDragItem.name, UxDragItem);
Vue.component(UxSearchInput.name, UxSearchInput);
Vue.component(UxDataDialog.name, UxDataDialog);
Vue.component(QueryView.name, QueryView);
Vue.component(QueryDialog.name, QueryDialog);
Vue.component(QueryInput.name, QueryInput);
Vue.component(NumberInput.name, NumberInput);
Vue.component(AreaTree.name, AreaTree);
Vue.component(CategoryTree.name, CategoryTree);
Vue.component(OrganizationTree.name, OrganizationTree);
Vue.component(EditTable.name, EditTable);
Vue.component(ReportButton.name, ReportButton)
Vue.component(LinkEdit.name, LinkEdit)
Vue.component("ux-year-input", YearInput)
Vue.component(FormInput.name, FormInput);
Vue.component(DynamicAttributes.name, DynamicAttributes)
Vue.component(ApproveButton.name, ApproveButton)
Vue.component(ImportButton.name, ImportButton);
Vue.component(QueryTagInput.name, QueryTagInput);
Vue.component(QuerySelect.name, QuerySelect);

Vue.component("ux-excel-editor", VueExcelEditor);
Vue.component("ux-excel-column", VueExcelColumn);

Vue.component(BarcodeNotFound.name, BarcodeNotFound)

Vue.component("n-row", {
  mixins: [Row],
  props: { gutter: { default: 5 } }
});

//math
import "@/utils/math.js";

Object.defineProperty(Array.prototype, 'remove', {
  value: function (node) {
    var index = this.indexOf(node);
    this.splice(index, 1);
  }
})
// 判断单据状态
Vue.prototype.BILL_STATUS_ENABLE = (status) => {
  switch (status) {
    case "Confirming":
      return true;
    case "Confirm":
      return true;
    case "Post":
      return true;
    case "Cancel":
      return true;
    default:
      return false;
  }
}
