<template>
  <ux-number-input :min="min" ref="input" class="ux-input" :show-spin-buttons="false" :disabled="disabled"
    :readOnly="readonly" :placeholder="placeholder" v-model="numericValue" @valueChanged="handleChange">
  </ux-number-input>
</template>

<script>
export default {
  name: "uxt-detail-extract",
  props: {
    name: String,
    value: {},
    placeholder: String,
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    maxQty: {
      type: Number,
      default: Infinity,
    },
    align: {
      type: String,
      default: "left",
    },
    precision: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && Number.isInteger(val);
      },
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {},
    selectAll: {},
    setV: {},
    extractOrderName: {
      type: String,
    }, // 抽取单据名
    byExtractOrderName: {
      type: String,
    }, // 被抽取单据名
  },
  data() {
    return {
      numericValue: this.value,
    };
  },
  watch: {
    // Watch for value change
    value() {
      this.numericValue = this.value;
    },
  },
  methods: {
    handleChange(arg) {
      let v = arg.value;
      if (v > this.maxQty) {
        this.$confirm(
          this.extractOrderName +
            this.l("ManyDetailExtract") +
            this.byExtractOrderName +
            this.l("OweDetailQty")
        )
          .then(() => {
            this.setV(v);
          })
          .catch(() => {
            this.setV(this.maxQty);
          });
      } else {
        this.setV(v);
      }
    },
  },
};
</script>

<style>
</style>