<template>
  <form class="full">
    <div id="uxReportViewer" class="full"></div>
  </form>
</template>
<script>
import reportUtil from "./reportUtil";
import request from "@/utils/request";
import { listActive } from "@/api/uxreport/dataSource.js";
// import "./stimulsoft.viewer.office2013.whiteblue.css";
/*
   @event:designerReady  脚本加载完毕事件
   @event:print 打印事件
   */
export default {
  name: "n-report-viewer",
  props: ["reportJson", "filters"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async loadReport(report, variables) {
      var stiReport = await reportUtil.multipleDataSourceReport(
        report,
        null,
        this.datasources,
        variables
      );
      this.viewer.report = stiReport;
    },
    reload() {
      this.loadReport(this.reportJson, this.filters);
    },
  },
  async mounted() {
    await reportUtil.initConfig();
    this.loading = true;
    try {
      var res = await listActive();
      this.datasources = res.items;
    } finally {
      this.loading = false;
    }
    var options = new Stimulsoft.Viewer.StiViewerOptions();
    options.width = "100%";
    options.height = "100%";
    var masterEnable = await this.getConfigKey("ux.master.enable");
    options.toolbar.showSendEmailButton = masterEnable == 1;
    options.toolbar.showAboutButton = false;
    options.toolbar.showFullScreenButton = false;
    options.appearance.scrollbarsMode = true;
    options.toolbar.printDestination =
      Stimulsoft.Viewer.StiPrintDestination.Direct;
    options.appearance.htmlRenderMode =
      Stimulsoft.Report.Export.StiHtmlExportMode.Table;
    options.toolbar.viewMode = Stimulsoft.Viewer.StiWebViewMode.Continuous;

    this.viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
    this.viewer.onPrintReport = () => {
      this.$emit("print");
    };
    let thiz = this;
    // this.viewer.onEmailReport = function (args) {
    //   request({
    //     url: "/uxmaster/DocOrganization/sendEmailByCompany",
    //     method: "post",
    //     data: {
    //       email: args.settings.email,
    //       subject: args.settings.subject,
    //       message: args.settings.message,
    //       format: args.format,
    //       formatName: args.formatName,
    //       fileName: args.fileName,
    //       data: args.data,
    //     },
    //   }).then(() => {
    //     thiz.msgSuccess(thiz.l("SendSuccess"));
    //   });
    //   // args.settings -  send email form
    //   // args.settings.email  -  email adress
    //   // args.settings.subject  -  email subject
    //   // args.settings.message  -  email message
    //   // args.format  -  export format - PDF, HTML, HTML 5, Excel2007, Word2007, CSV
    //   // args.fileName - report file name (name of attachement)
    //   // args.data  -  byte array with exported report file
    // };
    this.viewer.renderHtml("uxReportViewer");
    this.$emit("viewerReady");
    this.reload();
  },

  destroyed() {
    this.viewer = null;
  },
};
</script> 
<style>
</style>
