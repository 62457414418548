import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken, removeToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import Cookies from "js-cookie";
import VFrame from "../framework/VFrame.js";
import CONSTANT from '@/CONSTANT.js'

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 120000
});
let requestUrl = "";
let requestStatus = false;//为true接口响应成功
// request拦截器
service.interceptors.request.use(
  config => {
    if(!requestStatus){
      requestUrl = config.url
    }
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    var cultureName = Cookies.get(CONSTANT.UX_LANGUAGE);
    if (cultureName) {
      if (VFrame.languages.indexOf(cultureName) == -1) {
        cultureName = VFrame.languages[0];
      }
    } else {
      cultureName = VFrame.languages[0]
    }
    config.headers[CONSTANT.UX_LANGUAGE] = cultureName; // 让每个请求携带自定义token 请根据实际情况自行修改

    Cookies.set(CONSTANT.UX_LANGUAGE, cultureName, {
      expires: 30,
    })
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  res => {
    requestStatus = false
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      if (!VFrame.VueInstance) {
        removeToken()
        location.href = "/";
        return;
      }
      MessageBox.alert(
        VFrame.l("SessionExpired"),
        VFrame.l("SystemInfo"),
        {
          confirmButtonText: VFrame.l("Relogin"),
          type: "warning"
        }
      ).then(() => {
        store.dispatch("LogOut").then(() => {
          location.href = "/";
        });
      });
    } else if (code === 500) {
      Message({
        message: res.data.data || res.data.msg || errorCode["default"], // redmine:22918
        type: "error"
      });
      if (res.data.data) {
        console.error(res.data.data)
      }
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Notification.error({
        title: msg
      });
      return Promise.reject("error");
    } else {
      if (res.data.uxApi) {
        if (res.data.success) {
          return res.data.result;
        } else {
          Notification.error({ title: res.data.error });
          console.error(res);
          return Promise.reject(res.data.error);
        }
      } else {
        return res.data;
      }
    }
  },
  error => {
    console.log("err" + error);
    requestStatus = true
    let { message } = error;
    if (message == "Network Error") {
      message = VFrame.l("TheBackEndPortConnectionIsAbnormal");
    } else if (message.includes("timeout")) {
      message = VFrame.l("TheSystemInterfaceRequestTimedOut");
      // 6-5日 Halo群,改动以下三个接口的超时提示
      if(requestUrl=="/uxpublic/SapInterface/getInventoryOnHand"){
        message = "TimeOutSAP";
      }else if(requestUrl=="/uxpublic/SapInterface/getLeadTime"){
        message = "TimeOutLeadTime";
      }else if(requestUrl=="/uxpublic/SapInterface/getOrderStatusInformation"){
        message = "TimeOutTMSTracker";
      }
    } else if (message.includes("Request failed with status code")) {
      message =
        VFrame.l("SystemInterface") +
        message.substr(message.length - 3) +
        VFrame.l("Abnormal");
    }
    Message({
      message: VFrame.l(message),
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
