<template>
  <ux-select-dict v-bind="options" v-on="localEvents" @change="handleChange" v-model="currentValue"
    :disabled="disabled">
  </ux-select-dict>
</template>

<script>
export default {
  props: {
    params: {}, //row,column
    options: {},
    events: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: _.get(this.params.row, this.options.bindRowValueField),
      currentText: _.get(this.params.row, this.options.bindRowTextField),
    };
  },
  methods: {
    handleChange({ row, value, label }) {
      console.log({ row, value, label });
      if (row) {
        _.set(this.params.row, this.options.bindRowValueField, value);
        _.set(this.params.row, this.options.bindRowTextField, label);
      } else {
        _.set(this.params.row, this.options.bindRowValueField, null);
        _.set(this.params.row, this.options.bindRowTextField, "");
      }
      console.log(this.params.row);
    },
  },
  computed: {
    localEvents() {
      let temp = {};
      for (var p in this.events) {
        let event = this.events[p];
        let row = this.params.row;
        temp[p] = function () {
          event(
            row,
            arguments[0],
            arguments[1],
            arguments[2],
            arguments[3],
            arguments[4],
            arguments[5]
          );
        };
      }
      return temp;
    },
  },
};
</script>

<style>
</style>